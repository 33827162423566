import React, { useCallback, useEffect, useState } from 'react';
import HomeHeader from '../../Molecules/HomeHeader';
import HomeTemplate from '../../Templates/homeTemplate';
import { Link, useNavigate } from 'react-router-dom';
import { TResponseItems } from '../../../api/types';
import { initialItems, parseDateToDMY, parseTimeToHM } from '../../../helpers/functions';
import { initialImage } from '../../../helpers/initialValues';
import { IHairType } from '../../../types/app/hair';
import { initialHairTypeItems } from '../HairType';
import { api } from '../../../api';
import { Empty, message, Space } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import BaseTable from '../../Organisms/BaseTable';
import { TNumberOfGirls } from '../../../types/app/numberOfGirls';
import { initialNumberOfGirlsItems } from '../NumberOfGirls';

export const initialHairItems: IHairType = {
  id: 0,
  layout: initialImage,
  preview: initialImage,
  hairType: initialHairTypeItems,
  numberOfObject: initialNumberOfGirlsItems(),
  itemPositionIndex: 0,
  updatedAt: `${new Date()}`,
};

const HairsPage = () => {
  const navigate = useNavigate();

  const [itemsLoader, setItemsLoader] = useState<boolean>(true);
  const [hairs, setHairs] = useState<TResponseItems<IHairType>>(initialItems(initialHairItems));

  const buildData = useCallback(() => {
    return hairs.items.map((item: IHairType) => {
      return {
        key: item.id,
        id: item.id,
        numberOfObject: item.numberOfObject || 0,
        itemPositionIndex: item.itemPositionIndex,
        layout: { src: item.layout?.small, name: item.layout?.filename },
        preview: { src: item.preview?.small, name: item.preview?.filename },
        hairType: item.hairType.name,
        updated: `${parseDateToDMY(item.updatedAt)} ${parseTimeToHM(item.updatedAt)}`,
      };
    });
  }, [hairs.items]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async (page?: number) => {
    try {
      setItemsLoader(true);
      const response = await api.getAll<IHairType>('/hairs', page);
      setHairs(response.data);
      setItemsLoader(false);
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Превью',
      dataIndex: 'preview',
      key: 'preview',
      render: (item: any) => {
        return (
          item?.src && (
            <div className="small-table-image">
              <img src={item.src} alt={item.name} />
            </div>
          )
        );
      },
    },
    {
      title: 'Макет',
      dataIndex: 'layout',
      key: 'layout',
      render: (item: any) => {
        return (
          item?.src && (
            <div className="small-table-image">
              <img src={item.src} alt={item.name} />
            </div>
          )
        );
      },
    },
    {
      title: 'Тип волос',
      dataIndex: 'hairType',
      key: 'hairType',
    },
    {
      title: 'Тематика кружки',
      dataIndex: 'numberOfObject',
      key: 'numberOfObject',
      render: (value: TNumberOfGirls) => {
        return value ? <Link to={`/number-of-girls/${value.id}/edit`}>{value.name}</Link> : null;
      },
    },
    {
      title: 'Позиция',
      dataIndex: 'itemPositionIndex',
      key: 'itemPositionIndex',
    },
    {
      title: 'Дата изменения',
      dataIndex: 'updated',
      key: 'updated',
    },
    {
      title: 'Действия',
      key: 'action',
      render: (text: IHairType) => (
        <Space size="middle" className="actions">
          <EditOutlined onClick={() => editHandler(text)} />
          <DeleteOutlined onClick={() => deleteHandler(text)} />
        </Space>
      ),
    },
  ];

  const editHandler = (item: IHairType) => {
    navigate(`${item.id}/edit`);
  };

  const deleteHandler = async (item: IHairType) => {
    try {
      setItemsLoader(true);
      const pageNumber =
        (hairs.totalCount - 1) % 10 === 0 && hairs.currentPageNumber > 1
          ? hairs.currentPageNumber - 1
          : hairs.currentPageNumber;
      await api.delete('/hair', item.id);
      await getData(pageNumber);
      message.success('Прическа успешно удалена!');
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const createHandler = () => {
    navigate('create');
  };

  const onPageChange = (page: number) => {
    getData(page);
  };

  return (
    <HomeTemplate withoutScroll={true}>
      <HomeHeader onClick={createHandler} title="Макеты причесок" />
      {hairs.totalCount ? (
        <BaseTable
          columns={columns}
          data={buildData()}
          loader={itemsLoader}
          currentPageNumber={hairs.currentPageNumber}
          numItemsPerPage={hairs.numItemsPerPage}
          totalCount={hairs.totalCount}
          onPageChange={onPageChange}
        />
      ) : (
        <Empty description="Нет данных" />
      )}
    </HomeTemplate>
  );
};

export default HairsPage;
