import React, { useEffect, useState } from 'react';
import HomeTemplate from '../../../Templates/homeTemplate';
import { ISlider, TSliderData } from '../../../../types/app/slider';
import { useAppDispatch } from '../../../../hooks/redux';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { appSlice } from '../../../../redux/reducers/slices/app';
import BaseInput from '../../../Atoms/BaseInput';
import ImgUploader from '../../../Molecules/ImgUploader';
import { Button, message } from 'antd';
import { TResponse, TResponseImages, TResponseItem } from '../../../../api/types';
import { TImage } from '../../../../api/types/images';
import { deleteEmptyProperties, errorHandler, uploadImage } from '../../../../helpers/functions';
import { TRequestSliderData } from '../../../../api/types/slider';
import { api } from '../../../../api';
import { ICategoryType } from '../../../../types/app/categories';
import { TRequestCategoryData } from '../../../../api/types/categories';

const EditSlider = () => {
  const [slide, setSlide] = useState<ISlider>();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    try {
      const getSlideAsync = async () => {
        dispatch(appSlice.actions.setContentLoader(true));
        const {
          data: { item },
        } = await api.get<ISlider>('/slider-item', id);
        setSlide(item);
        setValue('title', item.title);
        setValue('description', item.description);
        setValue('href', item.href);
        setValue('images', [item.image]);
        dispatch(appSlice.actions.setContentLoader(false));
      };
      getSlideAsync();
    } catch (e: any) {}
  }, []);

  const editSlide = async (isUpdateImage: boolean, data: TSliderData) => {
    try {
      dispatch(appSlice.actions.setContentLoader(true));
      let imageResponse: TResponse<TResponseImages<TImage>>;
      const slideData: TRequestSliderData = {
        title: data.title,
        description: data.description,
        href: data.href,
        imageId: slide?.image.id ?? 0,
      };
      if (isUpdateImage) {
        imageResponse = await uploadImage(data.images);
        slideData.imageId = imageResponse.data.items[0].id;
      }

      const result: TResponse<TResponseItem<ISlider>> = await api.edit<TRequestSliderData>(
        '/slider-item',
        id,
        slideData,
      );
      setSlide(result.data.item);
      message.success('Слайд успешно сохранен!');
      dispatch(appSlice.actions.setContentLoader(false));
    } catch (e: any) {
      dispatch(appSlice.actions.setContentLoader(false));
      errorHandler(e, setError);
    }
  };

  const onsubmit = async (slideData: any) => {
    try {
      const isEditedImage: boolean = slideData.images[0].id !== slide?.image.id;
      editSlide(isEditedImage, slideData);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  return (
    <HomeTemplate classes="slider-edit" withoutScroll={false}>
      <div className="simple-headline">Редактировать слайд</div>
      <div className="base-wrapper mw-1200">
        <form onSubmit={handleSubmit(onsubmit)} noValidate>
          <BaseInput name="title" title="Заголовок:" error={errors.title} required={true}>
            <input
              id="title"
              {...register('title', {
                required: 'Введите заголовок',
                maxLength: { value: 100, message: 'Максимальное количество символов 100' },
              })}
            />
          </BaseInput>
          <BaseInput name="description" title="Описание:" error={errors.description}>
            <input
              id="description"
              {...register('description', {
                maxLength: { value: 255, message: 'Максимальное количество символов 255' },
              })}
            />
          </BaseInput>
          <BaseInput name="href" title="Ссылка:" error={errors.href}>
            <input
              id="href"
              {...register('href', {
                maxLength: { value: 255, message: 'Максимальное количество символов 255' },
              })}
            />
          </BaseInput>
          <ImgUploader
            maxFileList={1}
            control={control}
            error={errors.images}
            label="Изображение:"
            defaultList={slide?.image}
          />
          <Button type="primary" htmlType="submit">
            Сохранить
          </Button>
        </form>
      </div>
    </HomeTemplate>
  );
};

export default EditSlider;
