import React, { useCallback, useEffect, useState } from 'react';
import HomeHeader from '../../Molecules/HomeHeader';
import BaseTable from '../../Organisms/BaseTable';
import { Empty, message, Space } from 'antd';
import { IMugType } from '../../../types/app/mugTypes';
import { useNavigate } from 'react-router-dom';
import { TResponseItems } from '../../../api/types';
import { initialItems, parseDateToDMY, parseTimeToHM } from '../../../helpers/functions';
import { api } from '../../../api';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import HomeTemplate from '../../Templates/homeTemplate';
import { initialImage } from '../../../helpers/initialValues';

const initialMugTypeItems: IMugType = {
  id: 0,
  name: '',
  weight: 0,
  price: 0,
  image: initialImage,
  updatedAt: `${new Date()}`,
};

const MugTypes = () => {
  const navigate = useNavigate();

  const [itemsLoader, setItemsLoader] = useState<boolean>(true);
  const [mugTypes, setMugTypes] = useState<Pick<TResponseItems<IMugType>, 'items'>>(initialItems(initialMugTypeItems));

  const buildSlides = useCallback(() => {
    return mugTypes.items.map((item: IMugType) => {
      return {
        key: item.id,
        id: item.id,
        name: item.name,
        price: item.price,
        weight: item.weight,
        updated: `${parseDateToDMY(item.updatedAt)} ${parseTimeToHM(item.updatedAt)}`,
        image: { src: item.image?.small, name: item.image?.filename },
      };
    });
  }, [mugTypes.items]);

  useEffect(() => {
    getMugTypes();
  }, []);

  const getMugTypes = async (page?: number) => {
    try {
      setItemsLoader(true);
      const slidesResponse = await api.getAll<IMugType>('/mug-types', page);
      setMugTypes(slidesResponse.data);
      setItemsLoader(false);
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Цена',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Вес, грамы',
      dataIndex: 'weight',
      key: 'weight',
    },
    {
      title: 'Изображение',
      dataIndex: 'image',
      key: 'image',
      render: (item: any) => (
        <div className="small-table-image">
          <img src={item.src} alt={item.name} />
        </div>
      ),
    },
    {
      title: 'Дата изменения',
      dataIndex: 'updated',
      key: 'updated',
    },
    {
      title: 'Действия',
      key: 'action',
      render: (text: IMugType) => (
        <Space size="middle" className="actions">
          <EditOutlined onClick={() => editHandler(text)} />
          <DeleteOutlined onClick={() => deleteHandler(text)} />
        </Space>
      ),
    },
  ];

  const editHandler = (slideItem: IMugType) => {
    navigate(`${slideItem.id}/edit`);
  };

  const deleteHandler = async (item: IMugType) => {
    try {
      setItemsLoader(true);
      await api.delete('/mug-type', item.id);
      await getMugTypes();
      message.success('Слайд успешно удален!');
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const createHandler = () => {
    navigate('create');
  };

  return (
    <HomeTemplate classes="slider-root" withoutScroll={true}>
      <HomeHeader onClick={createHandler} title="Типы кружек" />
      {mugTypes.items?.length ? (
        <BaseTable columns={columns} data={buildSlides()} loader={itemsLoader} />
      ) : (
        <Empty description="Нет данных" />
      )}
    </HomeTemplate>
  );
};

export default MugTypes;
