import axios, { AxiosRequestConfig } from 'axios';
import { token } from '../helpers';
import { store } from '../redux/store';
import { authActions } from '../redux/reducers/actionCreators/auth';

/*
https://s1temaker.ru/test/mugnolia/4-devochki.html
http://ohmymug.ru/police.html
https://gossby.com
http://api.test.ohmymug.ru/api/doc
admin@example.com
qweqweqwe
 * */

const baseHeaders = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
};
export const API_URL = process.env.REACT_APP_API_URL;

export const publicAxios = axios.create({
  baseURL: API_URL,
  headers: {
    ...baseHeaders,
  },
});

export const privateAxios = axios.create({
  baseURL: API_URL,
  headers: {
    ...baseHeaders,
  },
});

let requestConfig: AxiosRequestConfig;

privateAxios.interceptors.request.use(
  async function (request) {
    requestConfig = request;
    request.headers = { ...request.headers, Authorization: `Bearer ${token.get().token}` };
    return request;
  },
  function (error) {
    return Promise.reject(error);
  },
);

privateAxios.interceptors.response.use(
  async function (response) {
    return response;
  },
  async function (error) {
    if (error.response.status === 401) {
      try {
        const { dispatch } = store;
        const refreshResponse: any = await dispatch(authActions.refreshToken(token.get()));
        if (refreshResponse?.status === 200 && requestConfig) {
          const prevRequest = await privateAxios.request(requestConfig);
          if (prevRequest.status >= 200 && prevRequest.status < 300) {
            return Promise.resolve(prevRequest);
          }
        }
      } catch (error) {
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  },
);
