import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../hooks/redux';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { appSlice } from '../../../../redux/reducers/slices/app';
import { api } from '../../../../api';
import { TResponse, TResponseImages, TResponseItem } from '../../../../api/types';
import { TImage } from '../../../../api/types/images';
import { deleteEmptyProperties, errorHandler, uploadImage } from '../../../../helpers/functions';
import { Button, message } from 'antd';
import HomeTemplate from '../../../Templates/homeTemplate';
import BaseInput from '../../../Atoms/BaseInput';
import ImgUploader from '../../../Molecules/ImgUploader';
import { IPostcardType, TPostcardTypeData } from '../../../../types/app/postcardTypes';
import { TRequestPostcardTypeData } from '../../../../api/types/postcardTypes';
import { ICategoryType } from '../../../../types/app/categories';
import { TRequestCategoryData } from '../../../../api/types/categories';

const EditPostcardType = () => {
  const [postcardType, setPostcardType] = useState<IPostcardType>();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    try {
      const getDataAsync = async () => {
        dispatch(appSlice.actions.setContentLoader(true));
        const {
          data: { item },
        } = await api.get<IPostcardType>('/postcard-type', id);
        setPostcardType(item);
        setValue('name', item.name);
        setValue('price', item.price);
        setValue('images', [item.image]);
        dispatch(appSlice.actions.setContentLoader(false));
      };
      getDataAsync();
    } catch (e: any) {}
  }, []);

  const edit = async (isUpdateImage: boolean, data: TPostcardTypeData) => {
    try {
      dispatch(appSlice.actions.setContentLoader(true));
      let imageResponse: TResponse<TResponseImages<TImage>>;
      const postcardTypeData: TRequestPostcardTypeData = {
        name: data.name,
        price: +data.price,
        imageId: postcardType?.image.id ?? 0,
      };
      if (isUpdateImage) {
        imageResponse = await uploadImage(data.images);
        postcardTypeData.imageId = imageResponse.data.items[0].id;
      }

      const result: TResponse<TResponseItem<IPostcardType>> = await api.edit<TRequestPostcardTypeData>(
        '/postcard-type',
        id,
        postcardTypeData,
      );
      setPostcardType(result.data.item);
      message.success('Тип открытки успешно сохранен!');
      dispatch(appSlice.actions.setContentLoader(false));
    } catch (e: any) {
      dispatch(appSlice.actions.setContentLoader(false));
      errorHandler(e, setError);
    }
  };

  const onsubmit = async (data: any) => {
    try {
      const isEditedImage: boolean = data.images[0].id !== postcardType?.image.id;
      edit(isEditedImage, data);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  return (
    <HomeTemplate classes="slider-edit" withoutScroll={false}>
      <div className="simple-headline">Редактировать тип открытки</div>
      <div className="base-wrapper mw-1200">
        <form onSubmit={handleSubmit(onsubmit)} noValidate>
          <BaseInput name="name" title="Имя:" error={errors.name} required={true}>
            <input
              id="name"
              {...register('name', {
                required: 'Введите имя',
                maxLength: { value: 100, message: 'Максимальное количество символов 100' },
              })}
            />
          </BaseInput>
          <BaseInput name="price" title="Цена:" error={errors.price} required={true}>
            <input
              id="price"
              type="number"
              {...register('price', {
                required: 'Укажите цену',
                min: { value: 1, message: 'Укажите положительную цену' },
              })}
            />
          </BaseInput>
          <ImgUploader
            maxFileList={1}
            control={control}
            error={errors.images}
            label="Изображение:"
            defaultList={postcardType?.image}
          />
          <Button type="primary" htmlType="submit">
            Сохранить
          </Button>
        </form>
      </div>
    </HomeTemplate>
  );
};

export default EditPostcardType;
