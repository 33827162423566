import React, { useEffect } from 'react';
import HomeTemplate from '../../../../Templates/homeTemplate';
import BaseInput from '../../../../Atoms/BaseInput';
import { useAppDispatch } from '../../../../../hooks/redux';
import { useForm } from 'react-hook-form';
import { appSlice } from '../../../../../redux/reducers/slices/app';
import { settingsApi } from '../../../../../api/settings';
import { Button, message } from 'antd';
import { errorHandler } from '../../../../../helpers/functions';
import { api } from '../../../../../api';
import { TCdekDelivery, TCdekDeliveryData } from '../../../../../types/app/delivery';
import { TRequestCdekDeliveryData } from '../../../../../api/types/delivery';

const CdekEdit = () => {
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    setValue,
    setError,
    control,
    register,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    window.scrollTo(0, 0);
    try {
      const getDataAsync = async () => {
        dispatch(appSlice.actions.setContentLoader(true));
        const {
          data: { item },
        } = await settingsApi.get<TCdekDelivery>('admin/delivery-service/cdek');
        setValue('clientSecret', item.clientSecret);
        setValue('clientId', item.clientId);
        setValue('initCity', item.initCity);
        setValue('width', item.width);
        setValue('height', item.height);
        setValue('length', item.length);
        dispatch(appSlice.actions.setContentLoader(false));
      };
      getDataAsync();
    } catch (e: any) {
      console.log(e);
    }
  }, []);

  const edit = async (data: TCdekDeliveryData) => {
    try {
      dispatch(appSlice.actions.setContentLoader(true));
      const deliveryData: TRequestCdekDeliveryData = {
        ...data,
      };

      await api.edit<TRequestCdekDeliveryData>('admin/delivery-service/cdek', '', deliveryData);
      message.success('Доставка успешно изменена!');
      dispatch(appSlice.actions.setContentLoader(false));
    } catch (e: any) {
      dispatch(appSlice.actions.setContentLoader(false));
      errorHandler(e, setError);
    }
  };

  const onsubmit = async (data: any) => {
    try {
      edit(data);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  return (
    <HomeTemplate withoutScroll={false}>
      <div className="simple-headline">Редактировать СДЭК доставку</div>
      <div className="base-wrapper mw-1200">
        <form onSubmit={handleSubmit(onsubmit)} noValidate>
          <BaseInput name="clientSecret" title="Client secret:" error={errors.clientSecret} required={true}>
            <input
              id="clientSecret"
              {...register('clientSecret', {
                required: 'Заполните поле',
              })}
            />
          </BaseInput>
          <BaseInput name="clientId" title="Client id:" error={errors.clientId} required={true}>
            <input
              id="clientId"
              {...register('clientId', {
                required: 'Заполните поле',
              })}
            />
          </BaseInput>
          <BaseInput name="initCity" title="Город по умолчанию:" error={errors.initCity} required={true}>
            <input
              id="initCity"
              {...register('initCity', {
                required: 'Заполните поле',
              })}
            />
          </BaseInput>
          <BaseInput name="height" title="Высота:" error={errors.height} required={true}>
            <input
              id="height"
              type="number"
              {...register('height', {
                required: 'Укажите высоту',
                min: { value: 1, message: 'Укажите валидное значение' },
              })}
            />
          </BaseInput>
          <BaseInput name="width" title="Ширина:" error={errors.width} required={true}>
            <input
              id="width"
              type="number"
              {...register('width', {
                required: 'Укажите ширину',
                min: { value: 1, message: 'Укажите валидное значение' },
              })}
            />
          </BaseInput>
          <BaseInput name="length" title="Длина:" error={errors.length} required={true}>
            <input
              id="length"
              type="number"
              {...register('length', {
                required: 'Укажите длину',
                min: { value: 1, message: 'Укажите валидное значение' },
              })}
            />
          </BaseInput>
          <Button type="primary" htmlType="submit">
            Изменить
          </Button>
        </form>
      </div>
    </HomeTemplate>
  );
};

export default CdekEdit;
