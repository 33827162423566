import React, { useEffect, useState } from 'react';
import HomeTemplate from '../../../Templates/homeTemplate';
import { TProductData } from '../../../../types/app/products';
import { useNavigate } from 'react-router-dom';
import { TResponse, TResponseImages, TResponseItems } from '../../../../api/types';
import { ICategoryType } from '../../../../types/app/categories';
import { deleteEmptyProperties, errorHandler, initialItems } from '../../../../helpers/functions';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../hooks/redux';
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import { appSlice } from '../../../../redux/reducers/slices/app';
import _ from 'lodash';
import { TImage } from '../../../../api/types/images';
import { api } from '../../../../api';
import { Button, message, Select } from 'antd';
import { TRequestProductData } from '../../../../api/types/products';
import BaseInput from '../../../Atoms/BaseInput';
import BaseSelect from '../../../Atoms/BaseAsyncSelect';
import BaseDateTimePicker from '../../../Atoms/BaseDateTimePicker';
import ImgUploader from '../../../Molecules/ImgUploader';
import BaseCheckBox from '../../../Atoms/BaseCheckBox';
import { initialCategoryItems } from '../../Categories';
import { TNumberOfGirls } from '../../../../types/app/numberOfGirls';

const { Option } = Select;

const CreateProduct = () => {
  const navigate = useNavigate();
  const [imageIds, setImageIds] = useState<number[]>([0]);
  const [categories, setCategories] = useState<TResponseItems<ICategoryType>>(initialItems([initialCategoryItems]));
  const [numberOfGirls, setNumberOfGirls] = useState<TNumberOfGirls[]>();

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    control,
    formState: { errors, isSubmitted },
  } = useForm();

  const dispatch = useAppDispatch();

  useEffect(() => {
    setValue('new', false);
    setValue('bestseller', false);
    getCategories();
    getNumberOfGirls();
  }, []);

  const getNumberOfGirls = async () => {
    const response = await api.getAllWithoutPagination<TNumberOfGirls>('/number-of-object');
    setNumberOfGirls(response.data.items);
  };

  const getCategories = async () => {
    try {
      const categoriesResponse = await api.getAll<ICategoryType>('/categories', 1, 500);
      setCategories(categoriesResponse.data);
    } catch (e: any) {
      console.log(e);
    }
  };

  const nameHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setValue('slug', new CyrillicToTranslit().transform(e?.currentTarget.value, '-'), { shouldValidate: isSubmitted });
  };

  const create = async (data: TProductData) => {
    const cloneData: any = { ...data };
    cloneData.newPrice = +cloneData.newPrice;
    cloneData.oldPrice = +cloneData.oldPrice;
    delete cloneData.images;
    delete cloneData.title;
    delete cloneData.metaDescription;
    delete cloneData.keywords;

    try {
      dispatch(appSlice.actions.setContentLoader(true));
      const formData = new FormData();
      const productData: TRequestProductData = {
        ...cloneData,
        imageIds: imageIds,
        numberOfObjectId: data.numberOfObject,
        meta: {
          title: data.title || null,
          description: data.metaDescription || null,
          keywords: data.keywords || null,
        },
      };
      if (!imageIds[0] && !_.isEmpty(data.images)) {
        data.images?.forEach((item: any) => {
          formData.append('files[]', item.originFileObj);
        });
        const imageResponse: TResponse<TResponseImages<TImage>> = await api.uploadImages(formData);
        const mapImagesId = imageResponse.data.items.map((item) => +item.id);
        productData.imageIds = mapImagesId;
        setImageIds(mapImagesId);
      }

      if (_.isEmpty(data.images)) {
        delete productData.imageIds;
      }

      await api.create<TRequestProductData>('/product', deleteEmptyProperties(productData));

      message.success('Товар успешно создан!');
      dispatch(appSlice.actions.setContentLoader(false));
      navigate('/products');
    } catch (e: any) {
      dispatch(appSlice.actions.setContentLoader(false));
      errorHandler(e, setError);
    }
  };

  const onsubmit = async (data: any) => {
    try {
      create(data);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  return (
    <HomeTemplate classes="product-create" withoutScroll={false}>
      <div className="simple-headline">Создать товар</div>
      <div className="base-wrapper mw-1200">
        <form onSubmit={handleSubmit(onsubmit)} noValidate>
          <BaseInput name="name" title="Имя:" error={errors.name} required={true}>
            <input
              id="name"
              onInput={nameHandler}
              {...register('name', {
                required: 'Введите имя',
                maxLength: { value: 100, message: 'Максимальное количество символов 100' },
              })}
            />
          </BaseInput>
          <BaseInput name="slug" title="Slug:" error={errors.slug} required={true}>
            <input
              id="slug"
              {...register('slug', {
                required: 'Введите slug',
                maxLength: { value: 255, message: 'Максимальное количество символов 255' },
              })}
            />
          </BaseInput>
          <BaseSelect
            title="Категории:"
            name="categoryIds"
            control={control}
            error={errors.categoryIds}
            multiple={true}
          >
            <Option key={0} value={0}>
              Без категории
            </Option>
            {categories.items?.map((item: any) => {
              return (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              );
            })}
          </BaseSelect>
          <BaseCheckBox control={control} name="new" label="Новинка" />
          <BaseCheckBox control={control} name="bestseller" label="Бестселлер" />
          <BaseSelect
            require={true}
            title="Тематика кружки:"
            name="numberOfObject"
            control={control}
            error={errors.numberOfObject}
          >
            {!!numberOfGirls?.length &&
              numberOfGirls.map((item: TNumberOfGirls) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                );
              })}
          </BaseSelect>
          <BaseInput name="oldPrice" title="Старая цена:" error={errors.oldPrice} required={false}>
            <input
              id="oldPrice"
              type="number"
              {...register('oldPrice', {
                min: { value: 1, message: 'Укажите положительную цену' },
              })}
            />
          </BaseInput>
          <BaseInput name="newPrice" title="Новая цена:" error={errors.newPrice} required={false}>
            <input
              id="newPrice"
              type="number"
              {...register('newPrice', {
                min: { value: 1, message: 'Укажите положительную цену' },
              })}
            />
          </BaseInput>
          <BaseDateTimePicker label="Конец скидки:" required={false} control={control} name="endOfSaleAt" />
          <BaseInput name="description" title="Описание:" error={errors.description} required={true}>
            <input
              id="description"
              {...register('description', {
                required: 'Введите описание',
                maxLength: { value: 100, message: 'Максимальное количество символов 100' },
              })}
            />
          </BaseInput>
          <BaseInput name="warningText" title="Предупреждение:" error={errors.warningText} required={false}>
            <input
              id="warningText"
              {...register('warningText', {
                maxLength: { value: 255, message: 'Максимальное количество символов 255' },
              })}
            />
          </BaseInput>
          <BaseInput
            name="additionalConditionsText"
            title="Дополнительная информация:"
            error={errors.additionalConditionsText}
            required={false}
          >
            <input
              id="additionalConditionsText"
              {...register('additionalConditionsText', {
                maxLength: { value: 255, message: 'Максимальное количество символов 255' },
              })}
            />
          </BaseInput>
          <BaseInput name="title" title="Meta title:" error={errors.title}>
            <input id="title" {...register('title')} />
          </BaseInput>
          <BaseInput name="metaDescription" title="Meta description:" error={errors.metaDescription}>
            <textarea {...register('metaDescription', {})} id="metaDescription" />
          </BaseInput>
          <BaseInput name="keywords" title="Meta Keywords:" error={errors.keywords}>
            <textarea {...register('keywords', {})} id="keywords" />
          </BaseInput>
          <ImgUploader
            setImageId={setImageIds}
            maxFileList={4}
            control={control}
            error={errors.images}
            required={true}
            multiple={true}
            label="Изображение:"
          />
          <Button type="primary" htmlType="submit">
            Создать
          </Button>
        </form>
      </div>
    </HomeTemplate>
  );
};

export default CreateProduct;
