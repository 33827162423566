import { IApp } from '../../../types/app';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: IApp = {
  isLoggedIn: true,
  appLoader: true,
  contentLoader: false,
  subMenuActive: [],
};

export const appSlice = createSlice({
  name: 'App',
  initialState,
  reducers: {
    setLoggedIn: (state: IApp, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    setLoaderStatus: (state: IApp, action: PayloadAction<boolean>) => {
      state.appLoader = action.payload;
    },
    setContentLoader: (state: IApp, action: PayloadAction<boolean>) => {
      state.contentLoader = action.payload;
    },
    setSubMenu: (state: IApp, action: PayloadAction<string[]>) => {
      state.subMenuActive = action.payload;
    },
  },
});

export default appSlice.reducer;
