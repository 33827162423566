import React from 'react';
import HomeTemplate from '../../../Templates/homeTemplate';
import { Button, message, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { errorHandler } from '../../../../helpers/functions';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../hooks/redux';
import { appSlice } from '../../../../redux/reducers/slices/app';
import { api } from '../../../../api';
import { TPromoCodeData } from '../../../../types/app/promoCodes';
import { TRequestPromoCodeData } from '../../../../api/types/promoCodeRequest';
import BaseSelect from '../../../Atoms/BaseAsyncSelect';
import BaseInput from '../../../Atoms/BaseInput';

const { Option } = Select;

const CreatePromoCode = () => {
  const navigate = useNavigate();

  const {
    handleSubmit,
    setError,
    control,
    register,
    formState: { errors },
  } = useForm();

  const dispatch = useAppDispatch();

  const create = async (data: TPromoCodeData) => {
    try {
      const promoCodeData = { ...data, value: +data.value };
      dispatch(appSlice.actions.setContentLoader(true));

      await api.create<TRequestPromoCodeData>('/promo-code', promoCodeData);
      message.success('Промокод успешно добавлен!');
      dispatch(appSlice.actions.setContentLoader(false));
      navigate('/promo-codes');
    } catch (e: any) {
      dispatch(appSlice.actions.setContentLoader(false));
      errorHandler(e, setError);
    }
  };

  const onsubmit = async (data: TPromoCodeData) => {
    try {
      create(data);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  return (
    <HomeTemplate classes="faq-root" withoutScroll={true}>
      <div className="simple-headline">Создать промокод</div>
      <div className="base-wrapper mw-1200">
        <form onSubmit={handleSubmit(onsubmit)} noValidate>
          <BaseInput name="name" title="Название:" error={errors.name} required={true}>
            <input
              id="name"
              {...register('name', {
                required: 'Введите название',
                maxLength: { value: 100, message: 'Максимальное количество символов 100' },
              })}
            />
          </BaseInput>
          <BaseSelect title="Тип:" name="type" control={control} error={errors.type} require={true}>
            <Option key={1} value={1}>
              Процент
            </Option>
            <Option key={2} value={2}>
              Скидка
            </Option>
          </BaseSelect>
          <BaseInput name="value" title="Значение:" error={errors.value} required={true}>
            <input
              id="value"
              type="number"
              {...register('value', {
                min: { value: 0, message: 'Укажите положительное значение' },
              })}
            />
          </BaseInput>
          <BaseInput name="description" title="Описание:" error={errors.description}>
            <input
              id="description"
              {...register('description', {
                maxLength: { value: 255, message: 'Максимальное количество символов 255' },
              })}
            />
          </BaseInput>
          <Button type="primary" htmlType="submit">
            Создать
          </Button>
        </form>
      </div>
    </HomeTemplate>
  );
};

export default CreatePromoCode;
