import React, { useCallback, useEffect, useState } from 'react';
import HomeHeader from '../../Molecules/HomeHeader';
import HomeTemplate from '../../Templates/homeTemplate';
import { initialImage } from '../../../helpers/initialValues';
import { IAboutType } from '../../../types/app/about';
import { useNavigate } from 'react-router-dom';
import { TResponseItems } from '../../../api/types';
import { initialItems, parseDateToDMY, parseTimeToHM } from '../../../helpers/functions';
import { api } from '../../../api';
import { Empty, message, Space } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import BaseTable from '../../Organisms/BaseTable';

export const initialAboutItems: IAboutType = {
  id: 0,
  image: initialImage,
  title: '',
  content: '',
  updatedAt: `${new Date()}`,
};

const AboutPage = () => {
  const navigate = useNavigate();

  const [itemsLoader, setItemsLoader] = useState<boolean>(true);
  const [about, setAbout] = useState<Pick<TResponseItems<IAboutType>, 'items'>>(initialItems(initialAboutItems));

  const buildData = useCallback(() => {
    return about.items.map((item: IAboutType) => {
      return {
        key: item.id,
        id: item.id,
        title: item.title,
        content: item.content,
        image: { src: item.image?.small, name: item.image?.filename },
        updated: `${parseDateToDMY(item.updatedAt)} ${parseTimeToHM(item.updatedAt)}`,
      };
    });
  }, [about.items]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async (page?: number) => {
    try {
      setItemsLoader(true);
      const response = await api.getAll<IAboutType>('/about', page);
      setAbout(response.data);
      setItemsLoader(false);
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Изображение',
      dataIndex: 'image',
      key: 'image',
      render: (item: any) => {
        return (
          item?.src && (
            <div className="small-table-image">
              <img src={item.src} alt={item.name} />
            </div>
          )
        );
      },
    },
    {
      title: 'Заголовок',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Дата изменения',
      dataIndex: 'updated',
      key: 'updated',
    },
    {
      title: 'Действия',
      key: 'action',
      render: (text: IAboutType) => (
        <Space size="middle" className="actions">
          <EditOutlined onClick={() => editHandler(text)} />
          <DeleteOutlined onClick={() => deleteHandler(text)} />
        </Space>
      ),
    },
  ];

  const editHandler = (item: IAboutType) => {
    navigate(`${item.id}/edit`);
  };

  const deleteHandler = async (item: IAboutType) => {
    try {
      setItemsLoader(true);
      await api.delete('/about', item.id);
      await getData();
      message.success('Блок о нас успешно удален!');
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const createHandler = () => {
    navigate('create');
  };

  return (
    <HomeTemplate withoutScroll={true}>
      <HomeHeader onClick={createHandler} title="О нас" />
      {about.items?.length ? (
        <BaseTable columns={columns} data={buildData()} loader={itemsLoader} />
      ) : (
        <Empty description="Нет данных" />
      )}
    </HomeTemplate>
  );
};

export default AboutPage;
