import React, { useEffect, useState } from 'react';
import HomeTemplate from '../../../Templates/homeTemplate';
import { TResponse, TResponseImages, TResponseItem, TResponseItems } from '../../../../api/types';
import { ISkinColorType } from '../../../../types/app/skinColor';
import { errorHandler, initialItems, uploadImage } from '../../../../helpers/functions';
import { initialSkinColorItems } from '../../SkinColor';
import { useAppDispatch } from '../../../../hooks/redux';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { initialBodyItems } from '../index';
import { IBodyType, TBodyData } from '../../../../types/app/body';
import { appSlice } from '../../../../redux/reducers/slices/app';
import { api } from '../../../../api';
import { itemsApi } from '../../../../api/items';
import { TImage } from '../../../../api/types/images';
import { Button, message, Select } from 'antd';
import { TRequestBodyData } from '../../../../api/types/body';
import BaseSelect from '../../../Atoms/BaseAsyncSelect';
import ImgUploader from '../../../Molecules/ImgUploader';
import { TNumberOfGirls } from '../../../../types/app/numberOfGirls';

const { Option } = Select;

const EditLowerBody = () => {
  const [lowerBody, setLowerBody] = useState<IBodyType>(initialBodyItems);
  const [skinColors, setSkinColors] = useState<Pick<TResponseItems<ISkinColorType>, 'items'>>(
    initialItems(initialSkinColorItems()),
  );
  const [numberOfGirls, setNumberOfGirls] = useState<TNumberOfGirls[]>();
  const [positions, setPositions] = useState<number[]>([0]);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const {
    handleSubmit,
    setValue,
    setError,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    try {
      const getDataAsync = async () => {
        dispatch(appSlice.actions.setContentLoader(true));
        const {
          data: { item },
        } = await api.get<IBodyType>('/lower-body', id);
        setLowerBody(item);
        setValue('skinColorId', item.skinColor.id);
        setValue('numberOfObject', item.numberOfObject?.id || null);
        setValue('layoutImageId', [item.layout]);
        setValue('previewImageId', [item.preview]);
        setValue('itemPositionIndex', item.itemPositionIndex);
        dispatch(appSlice.actions.setContentLoader(false));

        getSkinColors();
        getNumberOfGirls();

        if (item.numberOfObject?.id) {
          getPositionIndex(item.numberOfObject.id);
        }
      };
      getDataAsync();
    } catch (e: any) {}
  }, []);

  const getSkinColors = async () => {
    const response = await api.getAll<ISkinColorType>('/skin-colors');
    setSkinColors(response.data);
  };

  const getNumberOfGirls = async () => {
    const response = await api.getAllWithoutPagination<TNumberOfGirls>('/number-of-object');
    setNumberOfGirls(response.data.items);
  };

  const getPositionIndex = async (countItem: number) => {
    const response = await itemsApi.getPositionIndex<number[]>('lower-body', countItem);
    setPositions(response.data.items);
  };

  const handleChangeGirlsCount = async (item: any) => {
    setValue('itemPositionIndex', '');
    setPositions([0]);
    getPositionIndex(item);
  };

  const edit = async (isUpdateLayout: boolean, isUpdatePreview: boolean, data: TBodyData) => {
    try {
      dispatch(appSlice.actions.setContentLoader(true));
      let imageResponse: TResponse<TResponseImages<TImage>>;
      const lowerBodyData: TRequestBodyData = {
        skinColorId: data.skinColorId,
        layoutImageId: lowerBody?.layout.id ?? 0,
        previewImageId: lowerBody?.preview.id ?? 0,
        numberOfObjectId: data.numberOfObject,
        itemPositionIndex: data.itemPositionIndex,
      };
      if (isUpdateLayout) {
        try {
          imageResponse = await uploadImage(data.layoutImageId);
          lowerBodyData.layoutImageId = imageResponse.data.items[0].id;
        } catch (e) {
          dispatch(appSlice.actions.setContentLoader(false));
          return errorHandler(e, setError, 'layoutImageId');
        }
      }
      if (isUpdatePreview) {
        try {
          imageResponse = await uploadImage(data.previewImageId);
          lowerBodyData.previewImageId = imageResponse.data.items[0].id;
        } catch (e) {
          dispatch(appSlice.actions.setContentLoader(false));
          return errorHandler(e, setError, 'previewImageId');
        }
      }

      const result: TResponse<TResponseItem<IBodyType>> = await api.edit<TRequestBodyData>(
        '/lower-body',
        id,
        lowerBodyData,
      );
      setLowerBody(result.data.item);
      message.success('Макет ног успешно изменен!');
      dispatch(appSlice.actions.setContentLoader(false));
    } catch (e: any) {
      dispatch(appSlice.actions.setContentLoader(false));
      errorHandler(e, setError);
    }
  };

  const onsubmit = async (data: any) => {
    try {
      const isEditedLayout: boolean = data.layoutImageId[0].id !== lowerBody?.layout.id;
      const isEditedPreview: boolean = data.previewImageId[0].id !== lowerBody?.preview.id;
      edit(isEditedLayout, isEditedPreview, data);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  return (
    <HomeTemplate classes="lower-body-edit" withoutScroll={false}>
      <div className="simple-headline">Редактировать ноги</div>
      <div className="base-wrapper mw-1200">
        <form onSubmit={handleSubmit(onsubmit)} noValidate>
          <BaseSelect title="Цвет кожи:" name="skinColorId" control={control} error={errors.skinColorId} require={true}>
            {skinColors.items[0].id &&
              skinColors.items?.map((item: ISkinColorType) => {
                return (
                  <Option key={item.id} value={item.id}>
                    <div className="skin-color-options">
                      <img src={item.image.small} alt="цвет кожи" />
                      {item.name}
                    </div>
                  </Option>
                );
              })}
          </BaseSelect>
          <BaseSelect
            require={true}
            title="Тематика кружки:"
            name="numberOfObject"
            control={control}
            error={errors.numberOfObject}
            handlerChange={handleChangeGirlsCount}
          >
            {!!numberOfGirls?.length &&
              numberOfGirls.map((item: TNumberOfGirls) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                );
              })}
          </BaseSelect>
          <BaseSelect
            title="Позиция:"
            name="itemPositionIndex"
            control={control}
            error={errors.itemPositionIndex}
            require={true}
            disabled={!positions[0]}
          >
            {!!positions[0] &&
              positions.map((item: number) => {
                return (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                );
              })}
          </BaseSelect>
          <ImgUploader
            maxFileList={1}
            required={true}
            control={control}
            error={errors.previewImageId}
            label="Превью 100x100 пикселей:"
            name="previewImageId"
            defaultList={lowerBody?.preview}
          />
          <ImgUploader
            maxFileList={1}
            required={true}
            control={control}
            error={errors.layoutImageId}
            label="Макет 2000x2000 пикселей формата png:"
            name="layoutImageId"
            defaultList={lowerBody?.layout}
          />
          <Button type="primary" htmlType="submit">
            Сохранить
          </Button>
        </form>
      </div>
    </HomeTemplate>
  );
};

export default EditLowerBody;
