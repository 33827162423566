import React, { useEffect, useState } from 'react';
import HomeTemplate from '../../../Templates/homeTemplate';
import { useAppDispatch } from '../../../../hooks/redux';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { IHeadType, THeadData } from '../../../../types/app/heads';
import { appSlice } from '../../../../redux/reducers/slices/app';
import { api } from '../../../../api';
import { TResponse, TResponseImages, TResponseItem, TResponseItems } from '../../../../api/types';
import { ISkinColorType } from '../../../../types/app/skinColor';
import { errorHandler, initialItems, uploadImage } from '../../../../helpers/functions';
import { initialSkinColorItems } from '../../SkinColor';
import { itemsApi } from '../../../../api/items';
import ImgUploader from '../../../Molecules/ImgUploader';
import { Button, message, Select } from 'antd';
import { TImage } from '../../../../api/types/images';
import { TRequestHeadData } from '../../../../api/types/heads';
import BaseSelect from '../../../Atoms/BaseAsyncSelect';
import { initialHeadItems } from '../index';
import { TNumberOfGirls } from '../../../../types/app/numberOfGirls';

const { Option } = Select;

const EditHead = () => {
  const [head, setHead] = useState<IHeadType>(initialHeadItems);
  const [skinColors, setSkinColors] = useState<Pick<TResponseItems<ISkinColorType>, 'items'>>(
    initialItems(initialSkinColorItems()),
  );
  const [numberOfGirls, setNumberOfGirls] = useState<TNumberOfGirls[]>();
  const [positions, setPositions] = useState<number[]>([0]);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const {
    handleSubmit,
    setValue,
    setError,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    try {
      const getDataAsync = async () => {
        dispatch(appSlice.actions.setContentLoader(true));
        const {
          data: { item },
        } = await api.get<IHeadType>('/head', id);
        setHead(item);
        setValue('skinColorId', item.skinColor.id);
        setValue('numberOfObject', item.numberOfObject?.id || null);
        setValue('layoutImageId', [item.layout]);
        setValue('itemPositionIndex', item.itemPositionIndex);
        dispatch(appSlice.actions.setContentLoader(false));

        getSkinColors();
        getNumberOfGirls();

        if (item.numberOfObject?.id) {
          getPositionIndex(item.numberOfObject.id);
        }
      };
      getDataAsync();
    } catch (e: any) {}
  }, []);

  const getSkinColors = async () => {
    const response = await api.getAll<ISkinColorType>('/skin-colors');
    setSkinColors(response.data);
  };

  const getNumberOfGirls = async () => {
    const response = await api.getAllWithoutPagination<TNumberOfGirls>('/number-of-object');
    setNumberOfGirls(response.data.items);
  };

  const getPositionIndex = async (countItem: number) => {
    const response = await itemsApi.getPositionIndex<number[]>('head', countItem);
    setPositions(response.data.items);
  };

  const handleChangeGirlsCount = async (item: any) => {
    setValue('itemPositionIndex', '');
    setPositions([0]);
    getPositionIndex(item);
  };

  const edit = async (isUpdateImage: boolean, data: THeadData) => {
    try {
      dispatch(appSlice.actions.setContentLoader(true));
      let imageResponse: TResponse<TResponseImages<TImage>>;
      const headData: TRequestHeadData = {
        skinColorId: data.skinColorId,
        layoutImageId: head?.layout.id ?? 0,
        numberOfObjectId: data.numberOfObject,
        itemPositionIndex: data.itemPositionIndex,
      };
      if (isUpdateImage) {
        try {
          imageResponse = await uploadImage(data.layoutImageId);
          headData.layoutImageId = imageResponse.data.items[0].id;
        } catch (e) {
          dispatch(appSlice.actions.setContentLoader(false));
          return errorHandler(e, setError, 'layoutImageId');
        }
      }

      const result: TResponse<TResponseItem<IHeadType>> = await api.edit<TRequestHeadData>('/head', id, headData);
      setHead(result.data.item);
      message.success('Макет головы успешно изменен!');
      dispatch(appSlice.actions.setContentLoader(false));
    } catch (e: any) {
      dispatch(appSlice.actions.setContentLoader(false));
      errorHandler(e, setError);
    }
  };

  const onsubmit = async (data: any) => {
    try {
      const isEditedImage: boolean = data.layoutImageId[0].id !== head?.layout.id;
      edit(isEditedImage, data);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  return (
    <HomeTemplate classes="product-edit" withoutScroll={false}>
      <div className="simple-headline">Редактировать макет головы</div>
      <div className="base-wrapper mw-1200">
        <form onSubmit={handleSubmit(onsubmit)} noValidate>
          <BaseSelect title="Цвет кожи:" name="skinColorId" control={control} error={errors.skinColorId} require={true}>
            {skinColors.items[0].id &&
              skinColors.items?.map((item: ISkinColorType) => {
                return (
                  <Option key={item.id} value={item.id}>
                    <div className="skin-color-options">
                      <img src={item.image.small} alt="цвет кожи" />
                      {item.name}
                    </div>
                  </Option>
                );
              })}
          </BaseSelect>
          <BaseSelect
            require={true}
            title="Тематика кружки:"
            name="numberOfObject"
            control={control}
            error={errors.numberOfObject}
            handlerChange={handleChangeGirlsCount}
          >
            {!!numberOfGirls?.length &&
              numberOfGirls.map((item: TNumberOfGirls) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                );
              })}
          </BaseSelect>
          <BaseSelect
            title="Позиция:"
            name="itemPositionIndex"
            control={control}
            error={errors.itemPositionIndex}
            require={true}
            disabled={!positions[0]}
          >
            {!!positions[0] &&
              positions.map((item: number) => {
                return (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                );
              })}
          </BaseSelect>
          <ImgUploader
            maxFileList={1}
            required={true}
            control={control}
            error={errors.layoutImageId}
            label="Макет 2000x2000 пикселей формата png:"
            name="layoutImageId"
            defaultList={head?.layout}
          />
          <Button type="primary" htmlType="submit">
            Сохранить
          </Button>
        </form>
      </div>
    </HomeTemplate>
  );
};

export default EditHead;
