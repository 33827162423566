import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TResponseWithoutPagination } from '../../../api/types';
import { isConfirmDelete, parseDateToDMY, parseTimeToHM } from '../../../helpers/functions';
import { api } from '../../../api';
import { Empty, message, Space } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import HomeTemplate from '../../Templates/homeTemplate';
import HomeHeader from '../../Molecules/HomeHeader';
import BaseTable from '../../Organisms/BaseTable';
import { initialImage } from '../../../helpers/initialValues';
import { TNumberOfGirls } from '../../../types/app/numberOfGirls';

export const initialNumberOfGirlsItems = (): TNumberOfGirls => ({
  id: 0,
  countItem: 0,
  background: initialImage,
  name: '',
  updatedAt: `${new Date()}`,
});

const NumberOfGirlsPage = () => {
  const navigate = useNavigate();

  const [itemsLoader, setItemsLoader] = useState<boolean>(true);
  const [numberOfGirls, setNumberOfGirls] = useState<TResponseWithoutPagination<TNumberOfGirls>>({
    items: [initialNumberOfGirlsItems()],
  });

  const buildData = useCallback(() => {
    return numberOfGirls.items.map((item: TNumberOfGirls) => {
      return {
        key: item.id,
        id: item.id,
        countItem: item.countItem,
        background: { src: item.background?.small, name: item.background?.filename },
        name: item.name,
        updated: `${parseDateToDMY(item.updatedAt)} ${parseTimeToHM(item.updatedAt)}`,
      };
    });
  }, [numberOfGirls.items]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setItemsLoader(true);
      const response = await api.getAllWithoutPagination<TNumberOfGirls>('/number-of-object');
      setNumberOfGirls(response.data);
      setItemsLoader(false);
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Фон',
      dataIndex: 'background',
      key: 'background',
      render: (item: any) => {
        return (
          item?.src && (
            <div className="small-table-image">
              <img src={item.src} alt={item.name} />
            </div>
          )
        );
      },
    },
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Количество объектов',
      dataIndex: 'countItem',
      key: 'countItem',
    },
    {
      title: 'Дата изменения',
      dataIndex: 'updated',
      key: 'updated',
    },
    {
      title: 'Действия',
      key: 'action',
      render: (text: TNumberOfGirls) => (
        <Space size="middle" className="actions">
          <EditOutlined onClick={() => editHandler(text)} />
          <DeleteOutlined onClick={() => isConfirmDelete<TNumberOfGirls>(text, deleteHandler)} />
        </Space>
      ),
    },
  ];

  const editHandler = (item: TNumberOfGirls) => {
    navigate(`${item.id}/edit`);
  };

  const deleteHandler = async (item: TNumberOfGirls) => {
    try {
      setItemsLoader(true);
      await api.delete('/number-of-object', item.id);
      await getData();
      message.success('Тип тематики кружек успешно удален!');
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const createHandler = () => {
    navigate('create');
  };

  return (
    <HomeTemplate classes="products-root" withoutScroll={true}>
      <HomeHeader onClick={createHandler} title="Типы тематик кружек" />
      {numberOfGirls.items.length ? (
        <BaseTable columns={columns} data={buildData()} loader={itemsLoader} />
      ) : (
        <Empty description="Нет данных" />
      )}
    </HomeTemplate>
  );
};

export default NumberOfGirlsPage;
