import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../hooks/redux';
import { appSlice } from '../../../../redux/reducers/slices/app';
import { TResponse, TResponseImages } from '../../../../api/types';
import { TImage } from '../../../../api/types/images';
import { api } from '../../../../api';
import { Button, message } from 'antd';
import { errorHandler } from '../../../../helpers/functions';
import { TPackagingTypeData } from '../../../../types/app/packagingTypes';
import { TRequestPackagingTypeData } from '../../../../api/types/packagingTypes';
import HomeTemplate from '../../../Templates/homeTemplate';
import BaseInput from '../../../Atoms/BaseInput';
import ImgUploader from '../../../Molecules/ImgUploader';

const CreatePackagingType = () => {
  const navigate = useNavigate();
  const [imageId, setImageId] = useState<number[]>([0]);

  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors },
  } = useForm();

  const dispatch = useAppDispatch();

  const create = async ({ name, images }: TPackagingTypeData) => {
    try {
      dispatch(appSlice.actions.setContentLoader(true));
      const formData = new FormData();
      const packagingTypeData: TRequestPackagingTypeData = {
        name: name,
        imageId: imageId[0],
      };
      if (!imageId[0]) {
        images.forEach((item: any) => {
          formData.append('files[]', item.originFileObj);
        });
        const imageResponse: TResponse<TResponseImages<TImage>> = await api.uploadImages(formData);
        packagingTypeData.imageId = imageResponse.data.items[0].id;
        setImageId([imageResponse.data.items[0].id]);
      }

      await api.create<TRequestPackagingTypeData>('/type-packaging', packagingTypeData);

      message.success('Тип упаковки успешно создан!');
      dispatch(appSlice.actions.setContentLoader(false));
      navigate('/packaging-types');
    } catch (e: any) {
      dispatch(appSlice.actions.setContentLoader(false));
      errorHandler(e, setError);
    }
  };

  const onsubmit = async (data: any) => {
    try {
      create(data);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  return (
    <HomeTemplate classes="faq-root" withoutScroll={false}>
      <div className="simple-headline">Типы упаковок</div>
      <div className="base-wrapper mw-1200">
        <form onSubmit={handleSubmit(onsubmit)} noValidate>
          <BaseInput name="name" title="Имя:" error={errors.name} required={true}>
            <input
              id="name"
              {...register('name', {
                required: 'Введите имя',
                maxLength: { value: 100, message: 'Максимальное количество символов 100' },
              })}
            />
          </BaseInput>
          <ImgUploader
            setImageId={setImageId}
            maxFileList={1}
            control={control}
            error={errors.images}
            label="Изображение:"
          />
          <Button type="primary" htmlType="submit">
            Создать
          </Button>
        </form>
      </div>
    </HomeTemplate>
  );
};

export default CreatePackagingType;
