import React, { useCallback, useEffect, useState } from 'react';
import HomeTemplate from '../../Templates/homeTemplate';
import HomeHeader from '../../Molecules/HomeHeader';
import BaseTable from '../../Organisms/BaseTable';
import { Empty, message, Space } from 'antd';
import { IPrivacyPolicy } from '../../../types/app/privacyPolicy';
import { useNavigate } from 'react-router-dom';
import { TResponseItems } from '../../../api/types';
import { initialItems, parseDateToDMY, parseTimeToHM } from '../../../helpers/functions';
import { api } from '../../../api';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { initialImage } from '../../../helpers/initialValues';

const initialPrivacyPolicyItems = (): IPrivacyPolicy => ({
  id: 0,
  name: '',
  slug: '',
  text: '',
  image: initialImage,
  updatedAt: `${new Date()}`,
  meta: {
    title: '',
    keywords: '',
    description: '',
  },
});

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  const [itemsLoader, setItemsLoader] = useState<boolean>(false);
  const [privacyPolicy, setPrivacyPolicy] = useState<TResponseItems<IPrivacyPolicy>>(
    initialItems(initialPrivacyPolicyItems()),
  );

  const buildFaq = useCallback(() => {
    return privacyPolicy.items.map((item: IPrivacyPolicy) => {
      return {
        key: item.id,
        id: item.id,
        name: item.name,
        slug: item.slug,
        image: { src: item?.image?.small, name: item.name },
        updated: `${parseDateToDMY(item.updatedAt)} ${parseTimeToHM(item.updatedAt)}`,
      };
    });
  }, [privacyPolicy.items]);

  useEffect(() => {
    getPrivacyPolicy();
  }, []);

  const getPrivacyPolicy = async (page?: number) => {
    try {
      setItemsLoader(true);
      const faqResponse = await api.getAll<IPrivacyPolicy>('/legal-information', page);
      setPrivacyPolicy(faqResponse.data);
      setItemsLoader(false);
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      key: 'slug',
    },
    {
      title: 'Изображение',
      dataIndex: 'image',
      key: 'image',
      render: (item: any) => {
        return (
          item.src && (
            <div className="small-table-image">
              <img src={item.src} alt={item.name} />
            </div>
          )
        );
      },
    },
    {
      title: 'Дата изменения',
      dataIndex: 'updated',
      key: 'updated',
    },
    {
      title: 'Действия',
      key: 'action',
      render: (text: IPrivacyPolicy) => (
        <Space size="middle" className="actions">
          <EditOutlined onClick={() => editHandler(text)} />
          <DeleteOutlined onClick={() => deleteHandler(text)} />
        </Space>
      ),
    },
  ];

  const editHandler = (privacyPolicyItem: IPrivacyPolicy) => {
    navigate(`${privacyPolicyItem.slug}/edit`);
  };

  const deleteHandler = async (privacyPolicyItem: IPrivacyPolicy) => {
    try {
      setItemsLoader(true);
      const pageNumber =
        (privacyPolicy.totalCount - 1) % 10 === 0 && privacyPolicy.currentPageNumber > 1
          ? privacyPolicy.currentPageNumber - 1
          : privacyPolicy.currentPageNumber;
      await api.delete('/legal-information', privacyPolicyItem.id);
      await getPrivacyPolicy(pageNumber);
      message.success('Категория успешно удалена!');
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const onPageChange = (page: number) => {
    getPrivacyPolicy(page);
  };

  const createHandler = () => {
    navigate('create');
  };

  return (
    <HomeTemplate classes="privacy-policy-root" withoutScroll={true}>
      <HomeHeader onClick={createHandler} title="Категории политики и конфиденциальности" />
      {privacyPolicy.totalCount ? (
        <BaseTable
          columns={columns}
          data={buildFaq()}
          loader={itemsLoader}
          currentPageNumber={privacyPolicy.currentPageNumber}
          numItemsPerPage={privacyPolicy.numItemsPerPage}
          totalCount={privacyPolicy.totalCount}
          onPageChange={onPageChange}
        />
      ) : (
        <Empty description="Нет данных" />
      )}
    </HomeTemplate>
  );
};

export default PrivacyPolicy;
