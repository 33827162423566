import { TImage } from '../api/types/images';

export const initialImage: TImage = {
  id: 0,
  filename: '',
  originalFilename: '',
  mimeType: '',
  extension: '',
  width: 0,
  height: 0,
  original: '',
  small: '',
};
