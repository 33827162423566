import React from 'react';
import { Spin } from 'antd';
import './style.scss';

const ContentLoader = () => {
  return (
    <div className="content-loader">
      <Spin size="large" />
    </div>
  );
};

export default ContentLoader;
