import React, { useCallback, useEffect, useState } from 'react';
import { Empty, message, Space } from 'antd';
import HomeTemplate from '../../Templates/homeTemplate';
import BaseTable from '../../Organisms/BaseTable';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Faq } from '../../../types/app/faq';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import { TResponseItems } from '../../../api/types';
import HomeHeader from '../../Molecules/HomeHeader';
import { initialItems, parseDateToDMY, parseTimeToHM } from '../../../helpers/functions';
import { api } from '../../../api';
import { initialImage } from '../../../helpers/initialValues';

const initialFaqItems = (): Faq => ({
  id: 0,
  name: '',
  slug: '',
  text: '',
  image: initialImage,
  updatedAt: `${new Date()}`,
  meta: {
    title: '',
    keywords: '',
    description: '',
  },
});

const FaqPage = () => {
  const navigate = useNavigate();

  const [itemsLoader, setItemsLoader] = useState<boolean>(false);
  const [faq, setFaq] = useState<TResponseItems<Faq>>(initialItems(initialFaqItems()));

  const buildFaq = useCallback(() => {
    return faq.items.map((item: Faq) => {
      return {
        key: item.id,
        id: item.id,
        name: item.name,
        slug: item.slug,
        updated: `${parseDateToDMY(item.updatedAt)} ${parseTimeToHM(item.updatedAt)}`,
        image: { src: item.image.small, name: item.name },
      };
    });
  }, [faq.items]);

  useEffect(() => {
    getFaq();
  }, []);

  const getFaq = async (page?: number) => {
    try {
      setItemsLoader(true);
      const faqResponse = await api.getAll<Faq>('/faq', page);
      setFaq(faqResponse.data);
      setItemsLoader(false);
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      key: 'slug',
    },
    {
      title: 'Изображение',
      dataIndex: 'image',
      key: 'image',
      render: (item: any) => (
        <div className="small-table-image">
          <img src={item.src} alt={item.name} />
        </div>
      ),
    },
    {
      title: 'Дата изменения',
      dataIndex: 'updated',
      key: 'updated',
    },
    {
      title: 'Действия',
      key: 'action',
      render: (text: Faq) => (
        <Space size="middle" className="actions">
          <EditOutlined onClick={() => editHandler(text)} />
          <DeleteOutlined onClick={() => deleteHandler(text)} />
        </Space>
      ),
    },
  ];

  const editHandler = (faqItem: Faq) => {
    navigate(`${faqItem.slug}/edit`);
  };

  const deleteHandler = async (faqItem: Faq) => {
    try {
      setItemsLoader(true);
      const pageNumber =
        (faq.totalCount - 1) % 10 === 0 && faq.currentPageNumber > 1
          ? faq.currentPageNumber - 1
          : faq.currentPageNumber;
      await api.delete('/faq', faqItem.id);
      await getFaq(pageNumber);
      message.success('Faq успешно удален!');
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const onPageChange = (page: number) => {
    getFaq(page);
  };

  const createHandler = () => {
    navigate('create');
  };

  return (
    <HomeTemplate classes="faq-root" withoutScroll={true}>
      <HomeHeader onClick={createHandler} title="Вопросы и ответы" />
      {faq.totalCount ? (
        <BaseTable
          columns={columns}
          data={buildFaq()}
          loader={itemsLoader}
          currentPageNumber={faq.currentPageNumber}
          numItemsPerPage={faq.numItemsPerPage}
          totalCount={faq.totalCount}
          onPageChange={onPageChange}
        />
      ) : (
        <Empty description="Нет данных" />
      )}
    </HomeTemplate>
  );
};

export default FaqPage;
