import React, { useCallback, useEffect, useState } from 'react';
import { IPackagingType } from '../../../types/app/packagingTypes';
import { useNavigate } from 'react-router-dom';
import { TResponseItems } from '../../../api/types';
import { initialItems, parseDateToDMY, parseTimeToHM } from '../../../helpers/functions';
import { api } from '../../../api';
import { Empty, message, Space } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import HomeTemplate from '../../Templates/homeTemplate';
import HomeHeader from '../../Molecules/HomeHeader';
import BaseTable from '../../Organisms/BaseTable';
import { initialImage } from '../../../helpers/initialValues';

const initialPostcardItems: IPackagingType = {
  id: 0,
  name: '',
  image: initialImage,
  updatedAt: `${new Date()}`,
};

const PackagingType = () => {
  const navigate = useNavigate();

  const [itemsLoader, setItemsLoader] = useState<boolean>(true);
  const [packagingTypes, setPackagingTypes] = useState<Pick<TResponseItems<IPackagingType>, 'items'>>(
    initialItems(initialPostcardItems),
  );

  const buildPackagingTypes = useCallback(() => {
    return packagingTypes.items.map((item: IPackagingType) => {
      return {
        key: item.id,
        id: item.id,
        name: item.name,
        updated: `${parseDateToDMY(item.updatedAt)} ${parseTimeToHM(item.updatedAt)}`,
        image: { src: item.image?.small, name: item.image?.filename },
      };
    });
  }, [packagingTypes.items]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async (page?: number) => {
    try {
      setItemsLoader(true);
      const slidesResponse = await api.getAll<IPackagingType>('/type-packaging', page);
      setPackagingTypes(slidesResponse.data);
      setItemsLoader(false);
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Изображение',
      dataIndex: 'image',
      key: 'image',
      render: (item: any) => (
        <div className="small-table-image">
          <img src={item.src} alt={item.name} />
        </div>
      ),
    },
    {
      title: 'Дата изменения',
      dataIndex: 'updated',
      key: 'updated',
    },
    {
      title: 'Действия',
      key: 'action',
      render: (text: IPackagingType) => (
        <Space size="middle" className="actions">
          <EditOutlined onClick={() => editHandler(text)} />
          <DeleteOutlined onClick={() => deleteHandler(text)} />
        </Space>
      ),
    },
  ];

  const editHandler = (slideItem: IPackagingType) => {
    navigate(`${slideItem.id}/edit`);
  };

  const deleteHandler = async (item: IPackagingType) => {
    try {
      setItemsLoader(true);
      await api.delete('/type-packaging', item.id);
      await getData();
      message.success('Тип упаковки успешно удален!');
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const createHandler = () => {
    navigate('create');
  };

  return (
    <HomeTemplate classes="slider-root" withoutScroll={true}>
      <HomeHeader onClick={createHandler} title="Типы упаковок" />
      {packagingTypes.items?.length ? (
        <BaseTable columns={columns} data={buildPackagingTypes()} loader={itemsLoader} />
      ) : (
        <Empty description="Нет данных" />
      )}
    </HomeTemplate>
  );
};

export default PackagingType;
