import React, { useEffect, useState } from 'react';
import HomeTemplate from '../../../Templates/homeTemplate';
import { useNavigate } from 'react-router-dom';
import { TResponse, TResponseImages, TResponseItems } from '../../../../api/types';
import { errorHandler, initialItems, uploadImage } from '../../../../helpers/functions';
import { useForm } from 'react-hook-form';
import { IHairTypeType } from '../../../../types/app/hairTypes';
import { initialHairTypeItems } from '../../HairType';
import { useAppDispatch } from '../../../../hooks/redux';
import { api } from '../../../../api';
import { itemsApi } from '../../../../api/items';
import { appSlice } from '../../../../redux/reducers/slices/app';
import { TImage } from '../../../../api/types/images';
import { Button, message, Select } from 'antd';
import { THairData } from '../../../../types/app/hair';
import { TRequestHairData } from '../../../../api/types/hair';
import BaseSelect from '../../../Atoms/BaseAsyncSelect';
import ImgUploader from '../../../Molecules/ImgUploader';
import { TNumberOfGirls } from '../../../../types/app/numberOfGirls';

const { Option } = Select;

const CreateHair = () => {
  const navigate = useNavigate();
  const [layoutId, setLayoutId] = useState<number[]>([0]);
  const [previewId, setPreviewId] = useState<number[]>([0]);
  const [hairTypes, setHairTypes] = useState<Pick<TResponseItems<IHairTypeType>, 'items'>>(
    initialItems(initialHairTypeItems),
  );
  const [numberOfGirls, setNumberOfGirls] = useState<TNumberOfGirls[]>();
  const [positions, setPositions] = useState<number[]>([0]);

  const {
    handleSubmit,
    setError,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const getHairTypesAsync = async () => {
      const response = await api.getAll<IHairTypeType>('/hair-types');
      setHairTypes(response.data);
    };
    getHairTypesAsync();
    getNumberOfGirls();
  }, []);

  const getNumberOfGirls = async () => {
    const response = await api.getAllWithoutPagination<TNumberOfGirls>('/number-of-object');
    setNumberOfGirls(response.data.items);
  };

  const create = async ({
    layoutImageId,
    previewImageId,
    hairTypeId,
    numberOfObject,
    itemPositionIndex,
  }: THairData) => {
    try {
      dispatch(appSlice.actions.setContentLoader(true));
      const data: TRequestHairData = {
        hairTypeId: hairTypeId,
        layoutImageId: layoutId[0],
        previewImageId: previewId[0],
        numberOfObjectId: numberOfObject,
        itemPositionIndex: itemPositionIndex,
      };
      if (!layoutId[0]) {
        try {
          const imageResponse: TResponse<TResponseImages<TImage>> = await uploadImage(layoutImageId);
          data.layoutImageId = imageResponse.data.items[0].id;
          setLayoutId([imageResponse.data.items[0].id]);
        } catch (e: any) {
          dispatch(appSlice.actions.setContentLoader(false));
          return errorHandler(e, setError, 'layoutImageId');
        }
      }
      if (!previewId[0]) {
        try {
          const imageResponse: TResponse<TResponseImages<TImage>> = await uploadImage(previewImageId);
          data.previewImageId = imageResponse.data.items[0].id;
          setPreviewId([imageResponse.data.items[0].id]);
        } catch (e: any) {
          dispatch(appSlice.actions.setContentLoader(false));
          return errorHandler(e, setError, 'previewImageId');
        }
      }

      await api.create<TRequestHairData>('/hair', data);

      message.success('Прическа успешно добавлена!');
      dispatch(appSlice.actions.setContentLoader(false));
      navigate('/hairs');
    } catch (e: any) {
      dispatch(appSlice.actions.setContentLoader(false));
      errorHandler(e, setError);
    }
  };

  const onsubmit = async (data: any) => {
    try {
      create(data);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  const handleChangeGirlsCount = async (item: any) => {
    setValue('itemPositionIndex', '');
    setPositions([0]);
    const response = await itemsApi.getPositionIndex<number[]>('hair', item);
    setPositions(response.data.items);
  };

  return (
    <HomeTemplate withoutScroll={false}>
      <div className="simple-headline">Создать прическу</div>
      <div className="base-wrapper mw-1200">
        <form onSubmit={handleSubmit(onsubmit)} noValidate>
          <BaseSelect title="Тип волос:" name="hairTypeId" control={control} error={errors.hairTypeId} require={true}>
            {hairTypes.items[0].id &&
              hairTypes.items?.map((item: IHairTypeType) => {
                return (
                  <Option key={item.id} value={item.id}>
                    <div className="skin-color-options">{item.name}</div>
                  </Option>
                );
              })}
          </BaseSelect>
          <BaseSelect
            require={true}
            title="Тематика кружки:"
            name="numberOfObject"
            control={control}
            error={errors.numberOfObject}
            handlerChange={handleChangeGirlsCount}
          >
            {!!numberOfGirls?.length &&
              numberOfGirls.map((item: TNumberOfGirls) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                );
              })}
          </BaseSelect>
          <BaseSelect
            title="Позиция:"
            name="itemPositionIndex"
            control={control}
            error={errors.itemPositionIndex}
            require={true}
            disabled={!positions[0]}
          >
            {!!positions[0] &&
              positions.map((item: number) => {
                return (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                );
              })}
          </BaseSelect>
          <ImgUploader
            setImageId={setPreviewId}
            maxFileList={1}
            control={control}
            error={errors.previewImageId}
            name="previewImageId"
            label="Превью 100x100 пикселей:"
          />
          <ImgUploader
            setImageId={setLayoutId}
            maxFileList={1}
            control={control}
            error={errors.layoutImageId}
            name="layoutImageId"
            label="Макет 2000x2000 пикселей формата png:"
          />
          <Button type="primary" htmlType="submit">
            Создать
          </Button>
        </form>
      </div>
    </HomeTemplate>
  );
};

export default CreateHair;
