import React, { useCallback, useEffect, useState } from 'react';
import HomeHeader from '../../Molecules/HomeHeader';
import HomeTemplate from '../../Templates/homeTemplate';
import { IHairTypeType } from '../../../types/app/hairTypes';
import { useNavigate } from 'react-router-dom';
import { TResponseItems } from '../../../api/types';
import { initialItems, isConfirmDelete, parseDateToDMY, parseTimeToHM } from '../../../helpers/functions';
import { api } from '../../../api';
import { Empty, message, Space } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import BaseTable from '../../Organisms/BaseTable';
import './style.scss';

export const initialHairTypeItems: IHairTypeType = {
  id: 0,
  name: '',
  updatedAt: `${new Date()}`,
};

const HairTypesPage = () => {
  const navigate = useNavigate();

  const [itemsLoader, setItemsLoader] = useState<boolean>(true);
  const [hairType, setHairType] = useState<Pick<TResponseItems<IHairTypeType>, 'items'>>(
    initialItems(initialHairTypeItems),
  );

  const buildData = useCallback(() => {
    return hairType.items.map((item: IHairTypeType) => {
      return {
        key: item.id,
        id: item.id,
        name: item.name,
        updated: `${parseDateToDMY(item.updatedAt)} ${parseTimeToHM(item.updatedAt)}`,
      };
    });
  }, [hairType.items]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async (page?: number) => {
    try {
      setItemsLoader(true);
      const response = await api.getAll<IHairTypeType>('/hair-types', page);
      setHairType(response.data);
      setItemsLoader(false);
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Дата изменения',
      dataIndex: 'updated',
      key: 'updated',
    },
    {
      title: 'Действия',
      key: 'action',
      render: (text: IHairTypeType) => (
        <Space size="middle" className="actions">
          <EditOutlined onClick={() => editHandler(text)} />
          <DeleteOutlined onClick={() => isConfirmDelete<IHairTypeType>(text, deleteHandler)} />
        </Space>
      ),
    },
  ];

  const editHandler = (item: IHairTypeType) => {
    navigate(`${item.id}/edit`);
  };

  const deleteHandler = async (item: IHairTypeType) => {
    try {
      setItemsLoader(true);
      await api.delete('/hair-type', item.id);
      await getData();
      message.success('Тип волос успешно удален!');
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const createHandler = () => {
    navigate('create');
  };

  return (
    <HomeTemplate withoutScroll={true}>
      <HomeHeader onClick={createHandler} title="Типы волос" />
      {hairType.items.length ? (
        <BaseTable columns={columns} data={buildData()} loader={itemsLoader} />
      ) : (
        <Empty description="Нет данных" />
      )}
    </HomeTemplate>
  );
};

export default HairTypesPage;
