import React, { useEffect, useState } from 'react';
import HomeTemplate from '../../../Templates/homeTemplate';
import { Button, message, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { TResponse, TResponseImages, TResponseItems } from '../../../../api/types';
import { ICategoryType } from '../../../../types/app/categories';
import { errorHandler, initialItems, uploadImage } from '../../../../helpers/functions';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../hooks/redux';
import { api } from '../../../../api';
import { initialCategoryItems } from '../../Categories';
import { appSlice } from '../../../../redux/reducers/slices/app';
import { TImage } from '../../../../api/types/images';
import { TPromotedCategoryData } from '../../../../types/app/promotedCategory';
import { TRequestPromotedCategoryData } from '../../../../api/types/promotedCategory';
import BaseInput from '../../../Atoms/BaseInput';
import ImgUploader from '../../../Molecules/ImgUploader';
import BaseSelect from '../../../Atoms/BaseAsyncSelect';

const { Option } = Select;

const CreatePromotedCategory = () => {
  const navigate = useNavigate();
  const [imageId, setImageId] = useState<number[]>([0]);
  const [categories, setCategories] = useState<TResponseItems<ICategoryType>>(initialItems([initialCategoryItems]));

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    control,
    formState: { errors, isSubmitted },
  } = useForm();

  const dispatch = useAppDispatch();

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    try {
      const categoriesResponse = await api.getAll<ICategoryType>('/categories', 1, 500);
      setCategories(categoriesResponse.data);
    } catch (e: any) {
      console.log(e);
    }
  };

  const create = async (data: TPromotedCategoryData) => {
    try {
      dispatch(appSlice.actions.setContentLoader(true));
      const promotedCategoryData: TRequestPromotedCategoryData = {
        categoryId: data.categoryId,
        imageId: imageId[0],
        position: data.position,
        description: data.position,
      };
      if (!imageId[0]) {
        const imageResponse: TResponse<TResponseImages<TImage>> = await uploadImage(data.images);
        promotedCategoryData.imageId = imageResponse.data.items[0].id;
        setImageId([imageResponse.data.items[0].id]);
      }
      await api.create<TRequestPromotedCategoryData>('/promoted-category', promotedCategoryData);

      message.success('Продвигаемая категория успешно добавлена!');
      dispatch(appSlice.actions.setContentLoader(false));
      navigate('/promoted-categories');
    } catch (e: any) {
      dispatch(appSlice.actions.setContentLoader(false));
      errorHandler(e, setError);
    }
  };

  const onsubmit = async (data: any) => {
    try {
      create(data);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  return (
    <HomeTemplate withoutScroll={false}>
      <div className="simple-headline">Добавить продвигаемую категорию</div>
      <div className="base-wrapper mw-1200">
        <form onSubmit={handleSubmit(onsubmit)} noValidate>
          <BaseSelect require={true} title="Категория:" name="categoryId" control={control} error={errors.categoryId}>
            {categories?.items?.map((item: any) => {
              return (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              );
            })}
          </BaseSelect>
          <BaseInput name="description" title="Описание:" error={errors.description} required={true}>
            <input
              id="description"
              {...register('description', {
                required: 'Введите описание',
                maxLength: { value: 255, message: 'Максимальное количество символов 255' },
              })}
            />
          </BaseInput>
          <BaseSelect title="Позиция:" name="position" control={control} error={errors.position} require={true}>
            <Option key={1} value="left">
              Слева
            </Option>
            <Option key={2} value="center">
              По центру
            </Option>
            <Option key={3} value="right">
              Справа
            </Option>
          </BaseSelect>
          <ImgUploader
            setImageId={setImageId}
            maxFileList={1}
            control={control}
            error={errors.images}
            label="Изображение:"
          />
          <Button type="primary" htmlType="submit">
            Создать
          </Button>
        </form>
      </div>
    </HomeTemplate>
  );
};

export default CreatePromotedCategory;
