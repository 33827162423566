import React, { useCallback, useEffect, useState } from 'react';
import { ICategoryType } from '../../../types/app/categories';
import { Link, useNavigate } from 'react-router-dom';
import { TResponseItems } from '../../../api/types';
import { initialItems, isConfirmDelete, parseDateToDMY, parseTimeToHM } from '../../../helpers/functions';
import { api } from '../../../api';
import { Empty, message, Modal, Space } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import HomeTemplate from '../../Templates/homeTemplate';
import HomeHeader from '../../Molecules/HomeHeader';
import BaseTable from '../../Organisms/BaseTable';
import './style.scss';
import { ArrowRightOutlined } from '@ant-design/icons';
import { initialImage } from '../../../helpers/initialValues';

type TParentCategory = {
  slug: string;
  name: string;
};

export const initialCategoryItems: ICategoryType = {
  id: 0,
  name: '',
  additionalConditionsText: '',
  description: '',
  endOfSaleAt: `${new Date()}`,
  newPrice: 0,
  oldPrice: 0,
  parent: null,
  caption: '',
  slug: '',
  warningText: '',
  image: initialImage,
  updatedAt: `${new Date()}`,
  meta: {
    title: '',
    keywords: '',
    description: '',
  },
};

const CategoriesPage = () => {
  const navigate = useNavigate();

  const [itemsLoader, setItemsLoader] = useState<boolean>(true);
  const [categories, setCategories] = useState<TResponseItems<ICategoryType>>(initialItems(initialCategoryItems));

  const buildCategories = useCallback(() => {
    return categories.items.map((item: ICategoryType) => {
      return {
        key: item.id,
        id: item.id,
        name: item.name,
        endOfSaleAt: item.endOfSaleAt ? `${parseDateToDMY(item.endOfSaleAt)} ${parseTimeToHM(item.endOfSaleAt)}` : '',
        newPrice: item.newPrice,
        oldPrice: item.oldPrice,
        parent: item.parent,
        slug: item.slug,
        updated: `${parseDateToDMY(item.updatedAt)} ${parseTimeToHM(item.updatedAt)}`,
        image: { src: item.image?.small, name: item.image?.filename },
      };
    });
  }, [categories.items]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async (page?: number) => {
    try {
      setItemsLoader(true);
      const categoriesResponse = await api.getAll<ICategoryType>('/categories', page);
      setCategories(categoriesResponse.data);
      setItemsLoader(false);
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const buildParents = (item: ICategoryType) => {
    const parents: TParentCategory[] = [];

    const buildParentsLocal = (itemLocal: ICategoryType) => {
      if (!itemLocal) return [];

      parents.push({ name: itemLocal.name, slug: itemLocal.slug });

      if (itemLocal.parent) {
        buildParentsLocal(itemLocal.parent);
      }
    };

    buildParentsLocal(item);

    if (parents.length) {
      return parents.map((item: TParentCategory, index) => (
        <span key={index} className="parent-item">
          <Link to={`/categories/${item.slug}/edit`}>{item.name}</Link>
          {parents.length > 1 && parents.length - 1 !== index && <ArrowRightOutlined style={{ color: '#1890FFFF' }} />}
        </span>
      ));
    }

    return parents;
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Родительская категория',
      dataIndex: 'parent',
      key: 'parent',
      render: buildParents,
    },
    {
      title: 'Конец скидки',
      dataIndex: 'endOfSaleAt',
      key: 'endOfSaleAt',
    },
    {
      title: 'Новая цена',
      dataIndex: 'newPrice',
      key: 'newPrice',
    },
    {
      title: 'Старая цена',
      dataIndex: 'oldPrice',
      key: 'oldPrice',
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      key: 'slug',
    },
    {
      title: 'Изображение',
      dataIndex: 'image',
      key: 'image',
      render: (item: any) => {
        return (
          item.src && (
            <div className="small-table-image">
              <img src={item.src} alt={item.name} />
            </div>
          )
        );
      },
    },
    {
      title: 'Дата изменения',
      dataIndex: 'updated',
      key: 'updated',
    },
    {
      title: 'Действия',
      key: 'action',
      render: (text: ICategoryType) => (
        <Space size="middle" className="actions">
          <EditOutlined onClick={() => editHandler(text)} />
          <DeleteOutlined onClick={() => isConfirmDelete<ICategoryType>(text, deleteHandler)} />
        </Space>
      ),
    },
  ];

  const editHandler = (slideItem: ICategoryType) => {
    navigate(`${slideItem.slug}/edit`);
  };

  const deleteHandler = async (item: ICategoryType) => {
    try {
      setItemsLoader(true);
      const pageNumber =
        (categories.totalCount - 1) % 10 === 0 && categories.currentPageNumber > 1
          ? categories.currentPageNumber - 1
          : categories.currentPageNumber;
      await api.delete('/category', item.id);
      await getData(pageNumber);
      message.success('Категория успешно удалена!');
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const createHandler = () => {
    navigate('create');
  };

  const onPageChange = (page: number) => {
    getData(page);
  };

  return (
    <HomeTemplate classes="faq-root" withoutScroll={true}>
      <HomeHeader onClick={createHandler} title="Категории" />
      {categories.totalCount ? (
        <BaseTable
          columns={columns}
          data={buildCategories()}
          loader={itemsLoader}
          currentPageNumber={categories.currentPageNumber}
          numItemsPerPage={categories.numItemsPerPage}
          totalCount={categories.totalCount}
          onPageChange={onPageChange}
        />
      ) : (
        <Empty description="Нет данных" />
      )}
    </HomeTemplate>
  );
};

export default CategoriesPage;
