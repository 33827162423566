import { config } from '../helpers/config';
import { TResponse, TResponseItems } from './types';
import { privateAxios } from './axiosInstance';

export const itemsApi = {
  getAll: async <Entity>(): Promise<TResponse<{ items: Entity }>> => {
    return await privateAxios.get('/item/count');
  },
  getPositionIndex: async <Entity>(type: string, index: number): Promise<TResponse<{ items: Entity }>> => {
    return await privateAxios.get(`/item/positions-index/${type}/${index}`);
  },
};
