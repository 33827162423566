import React, { useEffect, useState } from 'react';
import HomeTemplate from '../../../Templates/homeTemplate';
import { Button, message, Select } from 'antd';
import { IArmType } from '../../../../types/app/arms';
import { TResponse, TResponseImages, TResponseItem } from '../../../../api/types';
import { errorHandler, uploadImage } from '../../../../helpers/functions';
import { useAppDispatch } from '../../../../hooks/redux';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { initialThingItems } from '../index';
import { IThingType, TThingData } from '../../../../types/app/things';
import { appSlice } from '../../../../redux/reducers/slices/app';
import { api } from '../../../../api';
import { itemsApi } from '../../../../api/items';
import { TImage } from '../../../../api/types/images';
import { TRequestThingData } from '../../../../api/types/things';
import BaseSelect from '../../../Atoms/BaseAsyncSelect';
import ImgUploader from '../../../Molecules/ImgUploader';
import { TNumberOfGirls } from '../../../../types/app/numberOfGirls';

const { Option } = Select;

const EditThing = () => {
  const [thing, setThing] = useState<IThingType>(initialThingItems);

  const [numberOfGirls, setNumberOfGirls] = useState<TNumberOfGirls[]>();
  const [positions, setPositions] = useState<number[]>([0]);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const {
    handleSubmit,
    setValue,
    setError,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    try {
      const getDataAsync = async () => {
        dispatch(appSlice.actions.setContentLoader(true));
        const {
          data: { item },
        } = await api.get<IArmType>('/thing', id);
        setThing(item);
        setValue('numberOfObject', item.numberOfObject?.id || null);
        setValue('layoutImageId', [item.layout]);
        setValue('previewImageId', [item.preview]);
        setValue('itemPositionIndex', item.itemPositionIndex);
        dispatch(appSlice.actions.setContentLoader(false));

        getNumberOfGirls();

        if (item.numberOfObject?.id) {
          getPositionIndex(item.numberOfObject.id);
        }
      };
      getDataAsync();
    } catch (e: any) {}
  }, []);

  const getNumberOfGirls = async () => {
    const response = await api.getAllWithoutPagination<TNumberOfGirls>('/number-of-object');
    setNumberOfGirls(response.data.items);
  };

  const getPositionIndex = async (countItem: number) => {
    const response = await itemsApi.getPositionIndex<number[]>('thing', countItem);
    setPositions(response.data.items);
  };

  const handleChangeGirlsCount = async (item: any) => {
    setValue('itemPositionIndex', '');
    setPositions([0]);
    getPositionIndex(item);
  };

  const edit = async (isUpdateLayout: boolean, isUpdatePreview: boolean, data: TThingData) => {
    try {
      dispatch(appSlice.actions.setContentLoader(true));
      let imageResponse: TResponse<TResponseImages<TImage>>;
      const thingData: TRequestThingData = {
        layoutImageId: thing?.layout.id ?? 0,
        previewImageId: thing?.preview.id ?? 0,
        numberOfObjectId: data.numberOfObject,
        itemPositionIndex: data.itemPositionIndex,
      };
      if (isUpdateLayout) {
        try {
          imageResponse = await uploadImage(data.layoutImageId);
          thingData.layoutImageId = imageResponse.data.items[0].id;
        } catch (e) {
          dispatch(appSlice.actions.setContentLoader(false));
          return errorHandler(e, setError, 'layoutImageId');
        }
      }
      if (isUpdatePreview) {
        try {
          imageResponse = await uploadImage(data.previewImageId);
          thingData.previewImageId = imageResponse.data.items[0].id;
        } catch (e) {
          dispatch(appSlice.actions.setContentLoader(false));
          return errorHandler(e, setError, 'previewImageId');
        }
      }

      const result: TResponse<TResponseItem<IThingType>> = await api.edit<TRequestThingData>('/thing', id, thingData);
      setThing(result.data.item);
      message.success('Предмет успешно изменен!');
      dispatch(appSlice.actions.setContentLoader(false));
    } catch (e: any) {
      dispatch(appSlice.actions.setContentLoader(false));
      errorHandler(e, setError);
    }
  };

  const onsubmit = async (data: any) => {
    try {
      const isEditedLayout: boolean = data.layoutImageId[0].id !== thing?.layout.id;
      const isEditedPreview: boolean = data.previewImageId[0].id !== thing?.preview.id;
      edit(isEditedLayout, isEditedPreview, data);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  return (
    <HomeTemplate withoutScroll={false}>
      <div className="simple-headline">Редактировать предмет</div>
      <div className="base-wrapper mw-1200">
        <form onSubmit={handleSubmit(onsubmit)} noValidate>
          <BaseSelect
            require={true}
            title="Тематика кружки:"
            name="numberOfObject"
            control={control}
            error={errors.numberOfObject}
            handlerChange={handleChangeGirlsCount}
          >
            {!!numberOfGirls?.length &&
              numberOfGirls.map((item: TNumberOfGirls) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                );
              })}
          </BaseSelect>
          <BaseSelect
            title="Позиция:"
            name="itemPositionIndex"
            control={control}
            error={errors.itemPositionIndex}
            require={true}
            disabled={!positions[0]}
          >
            {!!positions[0] &&
              positions.map((item: number) => {
                return (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                );
              })}
          </BaseSelect>
          <ImgUploader
            maxFileList={1}
            required={true}
            control={control}
            error={errors.previewImageId}
            label="Превью 100x100 пикселей:"
            name="previewImageId"
            defaultList={thing?.preview}
          />
          <ImgUploader
            maxFileList={1}
            required={true}
            control={control}
            error={errors.layoutImageId}
            label="Макет 2000x2000 пикселей формата png:"
            name="layoutImageId"
            defaultList={thing?.layout}
          />
          <Button type="primary" htmlType="submit">
            Сохранить
          </Button>
        </form>
      </div>
    </HomeTemplate>
  );
};

export default EditThing;
