import classNames from 'classnames';
import React from 'react';
import './style.scss';
import { FieldError } from 'react-hook-form';

type TBaseInput = {
  children: React.ReactNode;
  name: string;
  title: string;
  className?: string;
  required?: boolean;
  error: FieldError | undefined;
};

const BaseInput = ({ children, name, required, title, className, error }: TBaseInput) => {
  return (
    <div className={classNames('base-input', className, { 'base-input__error': error })}>
      <label htmlFor={name}>
        {title}
        {required && <span>*</span>}
      </label>
      {children}
      {error && <span className="base-input__error-message">{error.message}</span>}
    </div>
  );
};

export default BaseInput;
