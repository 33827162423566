import { configureStore } from '@reduxjs/toolkit';
import authSlice from './reducers/slices/auth';
import appSlice from './reducers/slices/app';
import faqSlice from './reducers/slices/faq';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    app: appSlice,
    faq: faqSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
