import React, { useCallback, useEffect, useState } from 'react';
import HomeHeader from '../../Molecules/HomeHeader';
import HomeTemplate from '../../Templates/homeTemplate';
import { IPaymentIconType } from '../../../types/app/paymentIcons';
import { initialImage } from '../../../helpers/initialValues';
import { useNavigate } from 'react-router-dom';
import { TResponseItems } from '../../../api/types';
import { initialItems, parseDateToDMY, parseTimeToHM } from '../../../helpers/functions';
import { api } from '../../../api';
import { Empty, message, Space } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import BaseTable from '../../Organisms/BaseTable';
import './style.scss';

export const initialPaymentIconItems: IPaymentIconType = {
  id: 0,
  image: initialImage,
  updatedAt: `${new Date()}`,
};

const PaymentIconsPage = () => {
  const navigate = useNavigate();

  const [itemsLoader, setItemsLoader] = useState<boolean>(true);

  const [paymentIcons, setPaymentIcons] = useState<Pick<TResponseItems<IPaymentIconType>, 'items'>>(
    initialItems(initialPaymentIconItems),
  );

  const buildData = useCallback(() => {
    return paymentIcons.items.map((item: IPaymentIconType) => {
      return {
        key: item.id,
        id: item.id,
        image: { src: item.image?.original, name: item.image?.filename },
        updated: `${parseDateToDMY(item.updatedAt)} ${parseTimeToHM(item.updatedAt)}`,
      };
    });
  }, [paymentIcons.items]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async (page?: number) => {
    try {
      setItemsLoader(true);
      const response = await api.getAll<IPaymentIconType>('/payment-icons', page);
      setPaymentIcons(response.data);
      setItemsLoader(false);
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Иконка',
      dataIndex: 'image',
      key: 'image',
      render: (item: any) => {
        return (
          item?.src && (
            <div className="payment-icon">
              <img src={item.src} alt={item.name} />
            </div>
          )
        );
      },
    },
    {
      title: 'Дата изменения',
      dataIndex: 'updated',
      key: 'updated',
    },
    {
      title: 'Действия',
      key: 'action',
      render: (text: IPaymentIconType) => (
        <Space size="middle" className="actions">
          <DeleteOutlined onClick={() => deleteHandler(text)} />
        </Space>
      ),
    },
  ];

  const deleteHandler = async (item: IPaymentIconType) => {
    try {
      setItemsLoader(true);
      await api.delete('/payment-icon', item.id);
      await getData();
      message.success('Иконка успешно удалена!');
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const createHandler = () => {
    navigate('create');
  };

  return (
    <HomeTemplate classes="left-arm-root" withoutScroll={true}>
      <HomeHeader onClick={createHandler} title="Платежные иконки" />
      {paymentIcons.items?.length ? (
        <BaseTable columns={columns} data={buildData()} loader={itemsLoader} />
      ) : (
        <Empty description="Нет данных" />
      )}
    </HomeTemplate>
  );
};

export default PaymentIconsPage;
