import React, { useEffect, useState } from 'react';
import HomeTemplate from '../../../Templates/homeTemplate';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../hooks/redux';
import BaseInput from '../../../Atoms/BaseInput';
import ImgUploader from '../../../Molecules/ImgUploader';
import { Button, message, Select } from 'antd';
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import { appSlice } from '../../../../redux/reducers/slices/app';
import { TResponse, TResponseImages, TResponseItems } from '../../../../api/types';
import { TImage } from '../../../../api/types/images';
import { api } from '../../../../api';
import { deleteEmptyProperties, errorHandler, initialItems } from '../../../../helpers/functions';
import { ICategoryType, TCategoryData } from '../../../../types/app/categories';
import { TRequestCategoryData } from '../../../../api/types/categories';
import _ from 'lodash';
import BaseDateTimePicker from '../../../Atoms/BaseDateTimePicker';
import BaseSelect from '../../../Atoms/BaseAsyncSelect';
import { initialCategoryItems } from '../index';

const { Option } = Select;

const CreateCategory = () => {
  const navigate = useNavigate();
  const [imageId, setImageId] = useState<number[]>([0]);

  const [categories, setCategories] = useState<TResponseItems<ICategoryType>>(initialItems(initialCategoryItems));

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    control,
    formState: { errors, isSubmitted },
  } = useForm();

  const dispatch = useAppDispatch();

  const nameHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setValue('slug', new CyrillicToTranslit().transform(e?.currentTarget.value, '-'), { shouldValidate: isSubmitted });
  };

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    try {
      const categoriesResponse = await api.getAll<ICategoryType>('/categories', 1, 500);
      setCategories(categoriesResponse.data);
    } catch (e: any) {
      console.log(e);
    }
  };

  const create = async (data: TCategoryData) => {
    const cloneData: any = { ...data };
    cloneData.newPrice = +cloneData.newPrice;
    cloneData.oldPrice = +cloneData.oldPrice;
    delete cloneData.images;
    delete cloneData.title;
    delete cloneData.metaDescription;
    delete cloneData.keywords;

    try {
      dispatch(appSlice.actions.setContentLoader(true));
      const formData = new FormData();
      const categoryData: TRequestCategoryData = {
        ...cloneData,
        imageId: imageId[0],
        meta: {
          title: data.title || null,
          description: data.metaDescription || null,
          keywords: data.keywords || null,
        },
      };
      if (!imageId[0] && !_.isEmpty(data.images)) {
        data.images?.forEach((item: any) => {
          formData.append('files[]', item.originFileObj);
        });
        const imageResponse: TResponse<TResponseImages<TImage>> = await api.uploadImages(formData);
        categoryData.imageId = imageResponse.data.items[0].id;
        setImageId([imageResponse.data.items[0].id]);
      }

      if (_.isEmpty(data.images)) {
        delete categoryData.imageId;
      }

      await api.create<TRequestCategoryData>('/category', deleteEmptyProperties(categoryData));

      message.success('Категория успешно создана!');
      dispatch(appSlice.actions.setContentLoader(false));
      navigate('/categories');
    } catch (e: any) {
      dispatch(appSlice.actions.setContentLoader(false));
      errorHandler(e, setError);
    }
  };

  const onsubmit = async (data: any) => {
    try {
      create(data);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  return (
    <HomeTemplate classes="faq-edit" withoutScroll={false}>
      <div className="simple-headline">Добавить категорию</div>
      <div className="base-wrapper mw-1200">
        <form onSubmit={handleSubmit(onsubmit)} noValidate>
          <BaseInput name="name" title="Имя:" error={errors.name} required={true}>
            <input
              id="name"
              onInput={nameHandler}
              {...register('name', {
                required: 'Введите имя',
                maxLength: { value: 100, message: 'Максимальное количество символов 100' },
              })}
            />
          </BaseInput>
          <BaseInput name="slug" title="Slug:" error={errors.slug} required={true}>
            <input
              id="slug"
              {...register('slug', {
                required: 'Введите slug',
                maxLength: { value: 255, message: 'Максимальное количество символов 255' },
              })}
            />
          </BaseInput>
          <BaseSelect title="Родительская категория:" name="categoryId" control={control}>
            <Option key={0} value={0}>
              Без категории
            </Option>
            {categories.items?.map((item: any) => {
              return (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              );
            })}
          </BaseSelect>
          <BaseInput name="oldPrice" title="Старая цена:" error={errors.oldPrice} required={true}>
            <input
              id="oldPrice"
              type="number"
              {...register('oldPrice', {
                required: 'Укажите цену',
                min: { value: 1, message: 'Укажите положительную цену' },
              })}
            />
          </BaseInput>
          <BaseInput name="newPrice" title="Новая цена:" error={errors.newPrice} required={false}>
            <input
              id="newPrice"
              type="number"
              {...register('newPrice', {
                min: { value: 1, message: 'Укажите положительную цену' },
              })}
            />
          </BaseInput>
          <BaseDateTimePicker label="Конец скидки:" required={false} control={control} name="endOfSaleAt" />
          <BaseInput name="warningText" title="Предупреждение:" error={errors.warningText} required={false}>
            <input
              id="warningText"
              {...register('warningText', {
                maxLength: { value: 255, message: 'Максимальное количество символов 255' },
              })}
            />
          </BaseInput>
          <BaseInput name="description" title="Описание:" error={errors.description} required={true}>
            <input
              id="description"
              {...register('description', {
                required: 'Введите описание',
                maxLength: { value: 500, message: 'Максимальное количество символов 500' },
              })}
            />
          </BaseInput>
          <BaseInput name="caption" title="Заголовок:" error={errors.caption} required={true}>
            <textarea
              id="caption"
              {...register('caption', {
                required: 'Введите заголовок',
                maxLength: { value: 255, message: 'Максимальное количество символов 255' },
              })}
            />
          </BaseInput>
          <BaseInput
            name="additionalConditionsText"
            title="Дополнительная информация:"
            error={errors.additionalConditionsText}
            required={false}
          >
            <input
              id="additionalConditionsText"
              {...register('additionalConditionsText', {
                maxLength: { value: 255, message: 'Максимальное количество символов 255' },
              })}
            />
          </BaseInput>
          <BaseInput name="title" title="Meta title:" error={errors.title}>
            <input id="title" {...register('title')} />
          </BaseInput>
          <BaseInput name="metaDescription" title="Meta description:" error={errors.metaDescription}>
            <textarea {...register('metaDescription', {})} id="metaDescription" />
          </BaseInput>
          <BaseInput name="keywords" title="Meta Keywords:" error={errors.keywords}>
            <textarea {...register('keywords', {})} id="keywords" />
          </BaseInput>
          <ImgUploader
            setImageId={setImageId}
            maxFileList={1}
            control={control}
            error={errors.images}
            required={false}
            label="Изображение:"
          />
          <Button type="primary" htmlType="submit">
            Создать
          </Button>
        </form>
      </div>
    </HomeTemplate>
  );
};

export default CreateCategory;
