import React, { ReactNode } from 'react';
import { Button } from 'antd';
import './style.scss';

type THomeHeader = {
  title: string;
  onClick?: () => void;
  buttonName?: string;
  anotherButton?: ReactNode;
};

const HomeHeader: React.FC<THomeHeader> = ({ onClick, title, buttonName, anotherButton }) => {
  return (
    <div className="home-header">
      <div className="simple-headline">{title}</div>
      <div className="">
        {!!anotherButton && anotherButton}
        {!!onClick && (
          <Button type="primary" onClick={onClick}>
            {buttonName ?? 'Создать'}
          </Button>
        )}
      </div>
    </div>
  );
};

export default HomeHeader;
