import React from 'react';
import HomeTemplate from '../../../Templates/homeTemplate';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../hooks/redux';
import { appSlice } from '../../../../redux/reducers/slices/app';
import { errorHandler } from '../../../../helpers/functions';
import { api } from '../../../../api';
import { Button, message } from 'antd';
import { THairTypeData } from '../../../../types/app/hairTypes';
import { TRequestHairTypeData } from '../../../../api/types/hairType';
import BaseInput from '../../../Atoms/BaseInput';

const CreateHairTypePage = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  const dispatch = useAppDispatch();

  const create = async ({ name }: THairTypeData) => {
    try {
      dispatch(appSlice.actions.setContentLoader(true));
      const data: TRequestHairTypeData = {
        name: name,
      };

      await api.create<TRequestHairTypeData>('/hair-type', data);

      message.success('Тип волос успешно добавлен!');
      dispatch(appSlice.actions.setContentLoader(false));
      navigate('/hair-types');
    } catch (e: any) {
      dispatch(appSlice.actions.setContentLoader(false));
      errorHandler(e, setError);
    }
  };

  const onsubmit = async (data: any) => {
    try {
      create(data);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  return (
    <HomeTemplate classes="hair-type-create" withoutScroll={false}>
      <div className="simple-headline">Создать тип волос</div>
      <div className="base-wrapper mw-1200">
        <form onSubmit={handleSubmit(onsubmit)} noValidate>
          <BaseInput
            name="name"
            title="Тип волос"
            error={errors.name}
            required={true}
            className="hair-type-create__name"
          >
            <input
              id="name"
              {...register('name', {
                required: 'Введите тип волос',
                maxLength: { value: 100, message: 'Максимальное количество символов 100' },
              })}
            />
          </BaseInput>
          <Button type="primary" htmlType="submit">
            Создать
          </Button>
        </form>
      </div>
    </HomeTemplate>
  );
};

export default CreateHairTypePage;
