import Button from 'antd/lib/button';
import React, { FormEventHandler, ReactElement, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import BaseInput from '../../Atoms/BaseInput';
import './style.scss';
import { message, Spin } from 'antd';
import { publicAxios } from '../../../api/axiosInstance';
import { FormValues } from '../../../types/auth';
import { AuthApi } from '../../../api/auth';
import { authActions } from '../../../redux/reducers/actionCreators/auth';
import { useAppDispatch } from '../../../hooks/redux';
import { useNavigate } from 'react-router-dom';

const LoginForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm();
  const [loader, setLoader] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onsubmit = async (data: FormValues) => {
    try {
      setLoader(true);
      await dispatch(authActions.login(data));
      navigate('/');
    } catch (e: any) {
      message.error(e.response.data.message);
    } finally {
      setLoader(false);
    }
  };

  return (
    <Spin spinning={loader}>
      <form onSubmit={handleSubmit(onsubmit)} noValidate className="login-form">
        <span className="login-form__headline">Аутентификация</span>
        <BaseInput name="email" title="Ваш email:" error={errors.email} className="login-form__item">
          <input
            type="email"
            id="email"
            {...register('email', {
              required: 'Введите электронный адрес',
              pattern: {
                value: /^.+@\S+.\S+$/,
                message: 'Введите корректный электронный адрес.',
              },
            })}
          />
        </BaseInput>
        <BaseInput name="password" title="Ваш пароль:" error={errors.password} className="login-form__item">
          <input
            type="password"
            id="password"
            {...register('password', {
              required: 'Введите ваш пароль',
              minLength: { value: 8, message: 'Пароль должен состоять не менее чем из 8 символов.' },
              maxLength: { value: 50, message: 'Пароль должен состоять не более чем из 50 символов.' },
            })}
          />
        </BaseInput>
        <Button type="primary" htmlType="submit">
          Войти
        </Button>
      </form>
    </Spin>
  );
};

export default LoginForm;
