import React, { useCallback, useEffect, useState } from 'react';
import HomeHeader from '../../Molecules/HomeHeader';
import HomeTemplate from '../../Templates/homeTemplate';
import { IPromoCodeType } from '../../../types/app/promoCodes';
import { useNavigate } from 'react-router-dom';
import { TResponseItems } from '../../../api/types';
import { initialItems, parseDateToDMY, parseTimeToHM } from '../../../helpers/functions';
import { api } from '../../../api';
import { Empty, message, Space } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import BaseTable from '../../Organisms/BaseTable';

export const initialPromoCodeItems: IPromoCodeType = {
  id: 0,
  type: undefined,
  value: 0,
  name: '',
  description: '',
  updatedAt: `${new Date()}`,
};

const PromoCodesPage = () => {
  const navigate = useNavigate();

  const [itemsLoader, setItemsLoader] = useState<boolean>(true);
  const [promoCodes, setPromoCodes] = useState<TResponseItems<IPromoCodeType>>(initialItems(initialPromoCodeItems));

  const buildData = useCallback(() => {
    return promoCodes.items.map((item: IPromoCodeType) => {
      return {
        key: item.id,
        id: item.id,
        type: item.type,
        value: item.value,
        name: item.name,
        description: item.description,
        updated: `${parseDateToDMY(item.updatedAt)} ${parseTimeToHM(item.updatedAt)}`,
      };
    });
  }, [promoCodes.items]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async (page?: number) => {
    try {
      setItemsLoader(true);
      const response = await api.getAll<IPromoCodeType>('/promo-codes', page);
      setPromoCodes(response.data);
      setItemsLoader(false);
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      key: 'type',
      render: (item: 1 | 2) => {
        return item === 1 ? 'Процент' : 'Значение';
      },
    },
    {
      title: 'Значение',
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Дата изменения',
      dataIndex: 'updated',
      key: 'updated',
    },
    {
      title: 'Действия',
      key: 'action',
      render: (text: IPromoCodeType) => (
        <Space size="middle" className="actions">
          <EditOutlined onClick={() => editHandler(text)} />
          <DeleteOutlined onClick={() => deleteHandler(text)} />
        </Space>
      ),
    },
  ];

  const editHandler = (item: IPromoCodeType) => {
    navigate(`${item.id}/edit`);
  };

  const deleteHandler = async (item: IPromoCodeType) => {
    try {
      setItemsLoader(true);
      const pageNumber =
        (promoCodes.totalCount - 1) % 10 === 0 && promoCodes.currentPageNumber > 1
          ? promoCodes.currentPageNumber - 1
          : promoCodes.currentPageNumber;
      await api.delete('/promo-code', item.id);
      await getData(pageNumber);
      message.success('Промокод успешно удален!');
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const createHandler = () => {
    navigate('create');
  };

  const onPageChange = (page: number) => {
    getData(page);
  };

  return (
    <HomeTemplate withoutScroll={true}>
      <HomeHeader onClick={createHandler} title="Промокоды" />
      {promoCodes.totalCount ? (
        <BaseTable
          columns={columns}
          data={buildData()}
          loader={itemsLoader}
          currentPageNumber={promoCodes.currentPageNumber}
          numItemsPerPage={promoCodes.numItemsPerPage}
          totalCount={promoCodes.totalCount}
          onPageChange={onPageChange}
        />
      ) : (
        <Empty description="Нет данных" />
      )}
    </HomeTemplate>
  );
};

export default PromoCodesPage;
