import React, { useEffect, useState } from 'react';
import HomeTemplate from '../../../Templates/homeTemplate';
import { TResponse, TResponseImages, TResponseItem } from '../../../../api/types';
import { errorHandler, uploadImage } from '../../../../helpers/functions';
import { useAppDispatch } from '../../../../hooks/redux';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { appSlice } from '../../../../redux/reducers/slices/app';
import { api } from '../../../../api';
import { initialBackTitleItems } from '../index';
import { IBackTextType, TBackTextData } from '../../../../types/app/backTitle';
import { TImage } from '../../../../api/types/images';
import { Button, message } from 'antd';
import { TRequestBackTextData } from '../../../../api/types/backText';
import ImgUploader from '../../../Molecules/ImgUploader';
import BaseInput from '../../../Atoms/BaseInput';

const EditBackSideText = () => {
  const [backText, setBackText] = useState<IBackTextType>(initialBackTitleItems);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const {
    handleSubmit,
    setValue,
    setError,
    control,
    register,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    try {
      const getDataAsync = async () => {
        dispatch(appSlice.actions.setContentLoader(true));
        const {
          data: { item },
        } = await api.get<IBackTextType>('/back-side-text', id);
        setBackText(item);
        setValue('text', item.text);
        setValue('layoutImageId', [item.layout]);
        setValue('previewImageId', [item.preview]);
        dispatch(appSlice.actions.setContentLoader(false));
      };
      getDataAsync();
    } catch (e: any) {}
  }, []);

  const edit = async (isUpdateLayout: boolean, isUpdatePreview: boolean, data: TBackTextData) => {
    try {
      dispatch(appSlice.actions.setContentLoader(true));
      let imageResponse: TResponse<TResponseImages<TImage>>;
      const backTextData: TRequestBackTextData = {
        text: data.text,
        layoutImageId: backText?.layout.id ?? 0,
        previewImageId: backText?.preview.id ?? 0,
      };
      if (isUpdateLayout) {
        try {
          imageResponse = await uploadImage(data.layoutImageId);
          backTextData.layoutImageId = imageResponse.data.items[0].id;
        } catch (e) {
          dispatch(appSlice.actions.setContentLoader(false));
          return errorHandler(e, setError, 'layoutImageId');
        }
      }
      if (isUpdatePreview) {
        try {
          imageResponse = await uploadImage(data.previewImageId);
          backTextData.previewImageId = imageResponse.data.items[0].id;
        } catch (e) {
          dispatch(appSlice.actions.setContentLoader(false));
          return errorHandler(e, setError, 'previewImageId');
        }
      }

      const result: TResponse<TResponseItem<IBackTextType>> = await api.edit<TRequestBackTextData>(
        '/back-side-text',
        id,
        backTextData,
      );
      setBackText(result.data.item);
      message.success('Надпись успешно изменена!');
      dispatch(appSlice.actions.setContentLoader(false));
    } catch (e: any) {
      dispatch(appSlice.actions.setContentLoader(false));
      errorHandler(e, setError);
    }
  };

  const onsubmit = async (data: any) => {
    try {
      const isEditedLayout: boolean = data.layoutImageId[0].id !== backText?.layout.id;
      const isEditedPreview: boolean = data.previewImageId[0].id !== backText?.preview.id;
      edit(isEditedLayout, isEditedPreview, data);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  return (
    <HomeTemplate withoutScroll={false}>
      <div className="simple-headline">Редактировать надпись на задней части чашки</div>
      <div className="base-wrapper mw-1200">
        <form onSubmit={handleSubmit(onsubmit)} noValidate>
          <BaseInput name="text" title="Надпись:" error={errors.text} required={true}>
            <input
              id="text"
              {...register('text', {
                required: 'Введите надпись',
                maxLength: { value: 100, message: 'Максимальное количество символов 100' },
              })}
            />
          </BaseInput>
          <ImgUploader
            maxFileList={1}
            required={true}
            control={control}
            error={errors.previewImageId}
            label="Превью 100x100 пикселей:"
            name="previewImageId"
            defaultList={backText?.preview}
          />
          <ImgUploader
            maxFileList={1}
            required={true}
            control={control}
            error={errors.layoutImageId}
            label="Макет 2000x2000 пикселей формата png:"
            name="layoutImageId"
            defaultList={backText?.layout}
          />
          <Button type="primary" htmlType="submit">
            Сохранить
          </Button>
        </form>
      </div>
    </HomeTemplate>
  );
};

export default EditBackSideText;
