import React, { useCallback, useEffect, useState } from 'react';
import HomeHeader from '../../Molecules/HomeHeader';
import HomeTemplate from '../../Templates/homeTemplate';
import { initialImage } from '../../../helpers/initialValues';
import { IBackTextType } from '../../../types/app/backTitle';
import { useNavigate } from 'react-router-dom';
import { TResponseItems } from '../../../api/types';
import { initialItems, parseDateToDMY, parseTimeToHM } from '../../../helpers/functions';
import { api } from '../../../api';
import { Empty, message, Space } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import BaseTable from '../../Organisms/BaseTable';

export const initialBackTitleItems: IBackTextType = {
  id: 0,
  layout: initialImage,
  preview: initialImage,
  text: '',
  updatedAt: `${new Date()}`,
};

const BackSideTextPage = () => {
  const navigate = useNavigate();

  const [itemsLoader, setItemsLoader] = useState<boolean>(true);
  const [backText, setBackText] = useState<Pick<TResponseItems<IBackTextType>, 'items'>>(
    initialItems(initialBackTitleItems),
  );

  const buildData = useCallback(() => {
    return backText.items.map((item: IBackTextType) => {
      return {
        key: item.id,
        id: item.id,
        text: item.text,
        layout: { src: item.layout?.small, name: item.layout?.filename },
        preview: { src: item.preview?.small, name: item.preview?.filename },
        updated: `${parseDateToDMY(item.updatedAt)} ${parseTimeToHM(item.updatedAt)}`,
      };
    });
  }, [backText.items]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async (page?: number) => {
    try {
      setItemsLoader(true);
      const response = await api.getAll<IBackTextType>('/back-side-texts', page);
      setBackText(response.data);
      setItemsLoader(false);
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Превью',
      dataIndex: 'preview',
      key: 'preview',
      render: (item: any) => {
        return (
          item?.src && (
            <div className="small-table-image">
              <img src={item.src} alt={item.name} />
            </div>
          )
        );
      },
    },
    {
      title: 'Макет',
      dataIndex: 'layout',
      key: 'layout',
      render: (item: any) => {
        return (
          item?.src && (
            <div className="small-table-image">
              <img src={item.src} alt={item.name} />
            </div>
          )
        );
      },
    },
    {
      title: 'Текст',
      dataIndex: 'text',
      key: 'text',
    },
    {
      title: 'Дата изменения',
      dataIndex: 'updated',
      key: 'updated',
    },
    {
      title: 'Действия',
      key: 'action',
      render: (text: IBackTextType) => (
        <Space size="middle" className="actions">
          <EditOutlined onClick={() => editHandler(text)} />
          <DeleteOutlined onClick={() => deleteHandler(text)} />
        </Space>
      ),
    },
  ];

  const editHandler = (item: IBackTextType) => {
    navigate(`${item.id}/edit`);
  };

  const deleteHandler = async (item: IBackTextType) => {
    try {
      setItemsLoader(true);
      await api.delete('/back-side-text', item.id);
      await getData();
      message.success('Надпись успешно удалена!');
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const createHandler = () => {
    navigate('create');
  };

  return (
    <HomeTemplate withoutScroll={true}>
      <HomeHeader onClick={createHandler} title="Надписи на задней части чашки" />
      {backText.items.length ? (
        <BaseTable columns={columns} data={buildData()} loader={itemsLoader} />
      ) : (
        <Empty description="Нет данных" />
      )}
    </HomeTemplate>
  );
};

export default BackSideTextPage;
