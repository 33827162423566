import React, { useCallback, useEffect, useState } from 'react';
import HomeHeader from '../../Molecules/HomeHeader';
import HomeTemplate from '../../Templates/homeTemplate';
import { initialImage } from '../../../helpers/initialValues';
import { initialSkinColorItems } from '../SkinColor';
import { IBodyType } from '../../../types/app/body';
import { Link, useNavigate } from 'react-router-dom';
import { TResponseItems } from '../../../api/types';
import { initialItems, parseDateToDMY, parseTimeToHM } from '../../../helpers/functions';
import { api } from '../../../api';
import { Empty, message, Space } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import BaseTable from '../../Organisms/BaseTable';
import { initialNumberOfGirlsItems } from '../NumberOfGirls';
import { TNumberOfGirls } from '../../../types/app/numberOfGirls';

export const initialBodyItems: IBodyType = {
  id: 0,
  layout: initialImage,
  preview: initialImage,
  skinColor: initialSkinColorItems(),
  numberOfObject: initialNumberOfGirlsItems(),
  itemPositionIndex: 0,
  updatedAt: `${new Date()}`,
};

const LowerBodyPage = () => {
  const navigate = useNavigate();

  const [itemsLoader, setItemsLoader] = useState<boolean>(true);
  const [lowerBodies, setLowerBodies] = useState<TResponseItems<IBodyType>>(initialItems(initialBodyItems));

  const buildData = useCallback(() => {
    return lowerBodies.items.map((item: IBodyType) => {
      return {
        key: item.id,
        id: item.id,
        numberOfObject: item.numberOfObject || 0,
        itemPositionIndex: item.itemPositionIndex,
        layout: { src: item.layout?.small, name: item.layout?.filename },
        preview: { src: item.preview?.small, name: item.preview?.filename },
        skinColor: { src: item.skinColor?.image.small, name: item.skinColor?.image.filename },
        updated: `${parseDateToDMY(item.updatedAt)} ${parseTimeToHM(item.updatedAt)}`,
      };
    });
  }, [lowerBodies.items]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async (page?: number) => {
    try {
      setItemsLoader(true);
      const response = await api.getAll<IBodyType>('/lower-bodies', page);
      setLowerBodies(response.data);
      setItemsLoader(false);
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Цвет кожи',
      dataIndex: 'skinColor',
      key: 'skinColor',
      render: (item: any) => {
        return (
          item?.src && (
            <div className="small-table-image">
              <img src={item.src} alt={item.name} />
            </div>
          )
        );
      },
    },
    {
      title: 'Превью',
      dataIndex: 'preview',
      key: 'preview',
      render: (item: any) => {
        return (
          item?.src && (
            <div className="small-table-image">
              <img src={item.src} alt={item.name} />
            </div>
          )
        );
      },
    },
    {
      title: 'Макет',
      dataIndex: 'layout',
      key: 'layout',
      render: (item: any) => {
        return (
          item?.src && (
            <div className="small-table-image">
              <img src={item.src} alt={item.name} />
            </div>
          )
        );
      },
    },
    {
      title: 'Тематика кружки',
      dataIndex: 'numberOfObject',
      key: 'numberOfObject',
      render: (value: TNumberOfGirls) => {
        return value ? <Link to={`/number-of-girls/${value.id}/edit`}>{value.name}</Link> : null;
      },
    },
    {
      title: 'Позиция',
      dataIndex: 'itemPositionIndex',
      key: 'itemPositionIndex',
    },
    {
      title: 'Дата изменения',
      dataIndex: 'updated',
      key: 'updated',
    },
    {
      title: 'Действия',
      key: 'action',
      render: (text: IBodyType) => (
        <Space size="middle" className="actions">
          <EditOutlined onClick={() => editHandler(text)} />
          <DeleteOutlined onClick={() => deleteHandler(text)} />
        </Space>
      ),
    },
  ];

  const editHandler = (item: IBodyType) => {
    navigate(`${item.id}/edit`);
  };

  const deleteHandler = async (item: IBodyType) => {
    try {
      setItemsLoader(true);
      const pageNumber =
        (lowerBodies.totalCount - 1) % 10 === 0 && lowerBodies.currentPageNumber > 1
          ? lowerBodies.currentPageNumber - 1
          : lowerBodies.currentPageNumber;
      await api.delete('/lower-body', item.id);
      await getData(pageNumber);
      message.success('Макет ног успешно удален!');
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const createHandler = () => {
    navigate('create');
  };

  const onPageChange = (page: number) => {
    getData(page);
  };

  return (
    <HomeTemplate classes="lower-body-root" withoutScroll={true}>
      <HomeHeader onClick={createHandler} title="Макеты ног" />
      {lowerBodies.totalCount ? (
        <BaseTable
          columns={columns}
          data={buildData()}
          loader={itemsLoader}
          currentPageNumber={lowerBodies.currentPageNumber}
          numItemsPerPage={lowerBodies.numItemsPerPage}
          totalCount={lowerBodies.totalCount}
          onPageChange={onPageChange}
        />
      ) : (
        <Empty description="Нет данных" />
      )}
    </HomeTemplate>
  );
};

export default LowerBodyPage;
