import React, { useState } from 'react';
import HomeTemplate from '../../../Templates/homeTemplate';
import { useNavigate } from 'react-router-dom';
import { TResponse, TResponseImages } from '../../../../api/types';
import { errorHandler, uploadImage } from '../../../../helpers/functions';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../hooks/redux';
import { api } from '../../../../api';
import { appSlice } from '../../../../redux/reducers/slices/app';
import { TImage } from '../../../../api/types/images';
import { Button, message } from 'antd';
import { TBackTextData } from '../../../../types/app/backTitle';
import { TRequestBackTextData } from '../../../../api/types/backText';
import BaseInput from '../../../Atoms/BaseInput';
import ImgUploader from '../../../Molecules/ImgUploader';

const CreateBackSideText = () => {
  const navigate = useNavigate();
  const [layoutId, setLayoutId] = useState<number[]>([0]);
  const [previewId, setPreviewId] = useState<number[]>([0]);

  const {
    handleSubmit,
    setError,
    control,
    register,
    formState: { errors },
  } = useForm();

  const dispatch = useAppDispatch();

  const create = async ({ layoutImageId, previewImageId, text }: TBackTextData) => {
    try {
      dispatch(appSlice.actions.setContentLoader(true));
      const data: TRequestBackTextData = {
        text: text,
        layoutImageId: layoutId[0],
        previewImageId: previewId[0],
      };
      if (!layoutId[0]) {
        try {
          const imageResponse: TResponse<TResponseImages<TImage>> = await uploadImage(layoutImageId);
          data.layoutImageId = imageResponse.data.items[0].id;
          setLayoutId([imageResponse.data.items[0].id]);
        } catch (e: any) {
          dispatch(appSlice.actions.setContentLoader(false));
          return errorHandler(e, setError, 'layoutImageId');
        }
      }
      if (!previewId[0]) {
        try {
          const imageResponse: TResponse<TResponseImages<TImage>> = await uploadImage(previewImageId);
          data.previewImageId = imageResponse.data.items[0].id;
          setPreviewId([imageResponse.data.items[0].id]);
        } catch (e: any) {
          dispatch(appSlice.actions.setContentLoader(false));
          return errorHandler(e, setError, 'previewImageId');
        }
      }

      await api.create<TRequestBackTextData>('/back-side-text', data);

      message.success('Надпись успешно добавлена!');
      dispatch(appSlice.actions.setContentLoader(false));
      navigate('/back-side-text');
    } catch (e: any) {
      dispatch(appSlice.actions.setContentLoader(false));
      errorHandler(e, setError);
    }
  };

  const onsubmit = async (data: any) => {
    try {
      create(data);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  return (
    <HomeTemplate classes="lower-body-create" withoutScroll={false}>
      <div className="simple-headline">Создать надпись для задней части чашки</div>
      <div className="base-wrapper mw-1200">
        <form onSubmit={handleSubmit(onsubmit)} noValidate>
          <BaseInput name="text" title="Надпись:" error={errors.text} required={true}>
            <input
              id="text"
              {...register('text', {
                required: 'Введите надпись',
                maxLength: { value: 100, message: 'Максимальное количество символов 100' },
              })}
            />
          </BaseInput>
          <ImgUploader
            setImageId={setPreviewId}
            maxFileList={1}
            control={control}
            error={errors.previewImageId}
            name="previewImageId"
            label="Превью 100x100 пикселей:"
          />
          <ImgUploader
            setImageId={setLayoutId}
            maxFileList={1}
            control={control}
            error={errors.layoutImageId}
            name="layoutImageId"
            label="Макет 2000x2000 пикселей формата png:"
          />
          <Button type="primary" htmlType="submit">
            Создать
          </Button>
        </form>
      </div>
    </HomeTemplate>
  );
};

export default CreateBackSideText;
