import React, { useEffect } from 'react';
import LoginForm from '../../../Organisms/LoginForm';
import './style.scss';
import { useAppSelector } from '../../../../hooks/redux';
import { RootState } from '../../../../redux/store';
import { useNavigate } from 'react-router-dom';
import { token } from '../../../../helpers';

const LoginPage = () => {
  const { isLoggedIn } = useAppSelector((state: RootState) => state.app);
  const navigate = useNavigate();

  useEffect(() => {
    if (token.get().token && isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn]);

  return (
    <div className="login-page">
      <div className="login-page__wrapper">
        <LoginForm />
      </div>
    </div>
  );
};

export default LoginPage;
