import React, { useCallback, useEffect, useState } from 'react';
import HomeHeader from '../../Molecules/HomeHeader';
import HomeTemplate from '../../Templates/homeTemplate';
import { ISubscriptionType } from '../../../types/app/subscription';
import { useNavigate } from 'react-router-dom';
import { TResponseItems } from '../../../api/types';
import { initialItems, parseDateToDMY, parseTimeToHM } from '../../../helpers/functions';
import { api } from '../../../api';
import { Button, Empty, message, Modal, Space } from 'antd';
import { DeleteOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';
import BaseTable from '../../Organisms/BaseTable';
import ShowDetails from '../Orders/ShowDetails';
import BaseDateTimePicker from '../../Atoms/BaseDateTimePicker';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { API_URL } from '../../../api/axiosInstance';
import { token } from '../../../helpers';

export const initialSubscriptionItems: ISubscriptionType = {
  id: 0,
  name: '',
  phone: '',
  email: '',
  createdAt: `${new Date()}`,
};

const SubscriptionsPage = () => {
  const navigate = useNavigate();

  const [itemsLoader, setItemsLoader] = useState<boolean>(true);
  const [subscriptions, setSubscriptions] = useState<TResponseItems<ISubscriptionType>>(
    initialItems(initialSubscriptionItems),
  );
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    control,
    formState: { errors, isSubmitted },
  } = useForm();

  const [showCsvModal, setShowCsvModal] = useState(false);

  const buildData = useCallback(() => {
    return subscriptions.items.map((item: ISubscriptionType) => {
      return {
        key: item.id,
        id: item.id,
        name: item.name,
        phone: item.phone,
        email: item.email,
        createdAt: `${parseDateToDMY(item.createdAt)} ${parseTimeToHM(item.createdAt)}`,
      };
    });
  }, [subscriptions.items]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async (page?: number) => {
    try {
      setItemsLoader(true);
      const response = await api.getAll<ISubscriptionType>('/subscriptions', page);
      setSubscriptions(response.data);
      setItemsLoader(false);
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Дата создания',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Действия',
      key: 'action',
      render: (text: ISubscriptionType) => (
        <Space size="middle" className="actions">
          <DeleteOutlined onClick={() => deleteHandler(text)} />
        </Space>
      ),
    },
  ];

  const deleteHandler = async (item: ISubscriptionType) => {
    try {
      setItemsLoader(true);
      const pageNumber =
        (subscriptions.totalCount - 1) % 10 === 0 && subscriptions.currentPageNumber > 1
          ? subscriptions.currentPageNumber - 1
          : subscriptions.currentPageNumber;
      await api.delete('/subscription', item.id);
      await getData(pageNumber);
      message.success('Подписка успешно удалена!');
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const createHandler = () => {
    navigate('create');
  };

  const onPageChange = (page: number) => {
    getData(page);
  };

  const onSubmit = async (values: any) => {
    const reqData = { ...values };

    if (!values.startAt) delete reqData.startAt;
    if (!values.endAt) delete reqData.endAt;

    try {
      setLoading(true);
      const pdf = await axios.get(`${API_URL}/subscriptions/export/csv`, {
        params: reqData,
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token.get().token}`,
        },
      });
      const blob = new Blob([pdf.data], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'subscriptions.csv';
      link.click();
    } finally {
      setLoading(false);
      setShowCsvModal(false);
    }
  };

  return (
    <HomeTemplate withoutScroll={true}>
      <HomeHeader
        onClick={createHandler}
        title="Подписки"
        anotherButton={
          <Button htmlType="button" onClick={() => setShowCsvModal(true)} style={{ marginRight: 10 }}>
            Экспорт CSV
          </Button>
        }
      />
      {subscriptions.totalCount ? (
        <BaseTable
          columns={columns}
          data={buildData()}
          loader={itemsLoader}
          currentPageNumber={subscriptions.currentPageNumber}
          numItemsPerPage={subscriptions.numItemsPerPage}
          totalCount={subscriptions.totalCount}
          onPageChange={onPageChange}
        />
      ) : (
        <Empty description="Нет данных" />
      )}
      {showCsvModal && (
        <Modal visible={true} title="Экспорт CSV" footer={null} onCancel={() => setShowCsvModal(false)}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div>
              <h2>
                Диапазон дат <span style={{ fontSize: 12 }}>(не объязательно)</span>
              </h2>
              <BaseDateTimePicker label="Период с:" required={false} control={control} name="startAt" />
              <BaseDateTimePicker label="Период по:" required={false} control={control} name="endAt" />
            </div>
            <Button type="primary" htmlType="submit">
              Экспорт {loading && <LoadingOutlined />}
            </Button>
          </form>
        </Modal>
      )}
    </HomeTemplate>
  );
};

export default SubscriptionsPage;
