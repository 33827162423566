import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { token } from '../../helpers';
import { useAppSelector } from '../../hooks/redux';
import { RootState } from '../../redux/store';

const PrivateRoute = (): any => {
  const { isLoggedIn } = useAppSelector((state: RootState) => state.app);

  return token.get().token && isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
