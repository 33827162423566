import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoginData } from '../../../types/auth';

const initialState: ILoginData = {
  item: {
    id: null,
    email: '',
    roles: [],
  },
  createdAt: '',
  token: '',
  refreshToken: '',
};

export const authSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    loginSuccess: (state, action: PayloadAction<ILoginData>) => {
      state.item = action.payload.item;
      state.createdAt = action.payload.createdAt;
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
    },
    loginError: (state) => {
      state.item = {
        id: null,
        email: '',
        roles: [],
      };
      state.createdAt = '';
      state.token = '';
      state.refreshToken = '';
    },
  },
});

export default authSlice.reducer;
