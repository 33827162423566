import { createSlice } from '@reduxjs/toolkit';

type TFaqState = {
  test: string;
};

const initialState: TFaqState = {
  test: '',
};

export const faqSlice = createSlice({
  name: 'FAQ',
  initialState,
  reducers: {},
});

export default faqSlice.reducer;
