import React from 'react';
import Checkbox from 'antd/es/checkbox/Checkbox';
import { Controller } from 'react-hook-form';
import './style.scss';

type TBaseCheckBox = {
  control: any;
  name: string;
  label: string;
};

const BaseCheckBox = (props: TBaseCheckBox) => {
  return (
    <div className="base-checkbox">
      <Controller
        control={props.control}
        name={props.name}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <Checkbox onChange={onChange} checked={value}>
            {props.label}
          </Checkbox>
        )}
      />
    </div>
  );
};

export default BaseCheckBox;
