import React, { useCallback, useEffect, useState } from 'react';
import HomeHeader from '../../Molecules/HomeHeader';
import HomeTemplate from '../../Templates/homeTemplate';
import { ISkinColorType } from '../../../types/app/skinColor';
import { useNavigate } from 'react-router-dom';
import { TResponseItems } from '../../../api/types';
import { initialItems, isConfirmDelete, parseDateToDMY, parseTimeToHM } from '../../../helpers/functions';
import { api } from '../../../api';
import { Empty, message, Space } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import BaseTable from '../../Organisms/BaseTable';
import { initialImage } from '../../../helpers/initialValues';

export const initialSkinColorItems = (): ISkinColorType => ({
  id: 0,
  name: '',
  image: initialImage,
  updatedAt: `${new Date()}`,
});

const SkinColorPage = () => {
  const navigate = useNavigate();

  const [itemsLoader, setItemsLoader] = useState<boolean>(true);
  const [skinColors, setSkinColors] = useState<Pick<TResponseItems<ISkinColorType>, 'items'>>(
    initialItems(initialSkinColorItems()),
  );

  const buildData = useCallback(() => {
    return skinColors.items.map((item: ISkinColorType) => {
      return {
        key: item.id,
        id: item.id,
        name: item.name,
        image: { src: item.image?.small, name: item.image?.filename },
        updated: `${parseDateToDMY(item.updatedAt)} ${parseTimeToHM(item.updatedAt)}`,
      };
    });
  }, [skinColors.items]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async (page?: number) => {
    try {
      setItemsLoader(true);
      const response = await api.getAll<ISkinColorType>('/skin-colors', page);
      setSkinColors(response.data);
      setItemsLoader(false);
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Изображение',
      dataIndex: 'image',
      key: 'image',
      render: (item: any) => {
        return (
          item?.src && (
            <div className="small-table-image">
              <img src={item.src} alt={item.name} />
            </div>
          )
        );
      },
    },
    {
      title: 'Дата изменения',
      dataIndex: 'updated',
      key: 'updated',
    },
    {
      title: 'Действия',
      key: 'action',
      render: (text: ISkinColorType) => (
        <Space size="middle" className="actions">
          <EditOutlined onClick={() => editHandler(text)} />
          <DeleteOutlined onClick={() => isConfirmDelete<ISkinColorType>(text, deleteHandler)} />
        </Space>
      ),
    },
  ];

  const editHandler = (item: ISkinColorType) => {
    navigate(`${item.id}/edit`);
  };

  const deleteHandler = async (item: ISkinColorType) => {
    try {
      setItemsLoader(true);
      await api.delete('/skin-color', item.id);
      await getData();
      message.success('Цвет кожи успешно удален!');
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const createHandler = () => {
    navigate('create');
  };

  return (
    <HomeTemplate classes="products-root" withoutScroll={true}>
      <HomeHeader onClick={createHandler} title="Цвета кожи" />
      {skinColors.items.length ? (
        <BaseTable columns={columns} data={buildData()} loader={itemsLoader} />
      ) : (
        <Empty description="Нет данных" />
      )}
    </HomeTemplate>
  );
};

export default SkinColorPage;
