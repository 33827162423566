import React, { useEffect, useState } from 'react';
import HomeTemplate from '../../../Templates/homeTemplate';
import { TResponse, TResponseImages, TResponseItem, TResponseItems } from '../../../../api/types';
import { ICategoryType } from '../../../../types/app/categories';
import { deleteEmptyProperties, errorHandler, initialItems, uploadImage } from '../../../../helpers/functions';
import { useAppDispatch } from '../../../../hooks/redux';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, message, Select } from 'antd';
import { IPromotedCategoryType, TPromotedCategoryData } from '../../../../types/app/promotedCategory';
import { initialCategoryItems } from '../../Categories';
import { api } from '../../../../api';
import { appSlice } from '../../../../redux/reducers/slices/app';
import { TImage } from '../../../../api/types/images';
import { TRequestPromotedCategoryData } from '../../../../api/types/promotedCategory';
import BaseSelect from '../../../Atoms/BaseAsyncSelect';
import BaseInput from '../../../Atoms/BaseInput';
import ImgUploader from '../../../Molecules/ImgUploader';

const { Option } = Select;

const EditPromotedCategory = () => {
  const [promotedCategory, setPromotedCategory] = useState<IPromotedCategoryType>();
  const [categories, setCategories] = useState<TResponseItems<ICategoryType>>(initialItems([initialCategoryItems]));

  const dispatch = useAppDispatch();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    try {
      const categoriesResponse = await api.getAll<ICategoryType>('/categories', 1, 500);
      setCategories(categoriesResponse.data);
    } catch (e: any) {
      console.log(e);
    }
  };

  useEffect(() => {
    try {
      const getDataAsync = async () => {
        dispatch(appSlice.actions.setContentLoader(true));
        const {
          data: { item },
        } = await api.get<IPromotedCategoryType>('/promoted-category', id);
        setPromotedCategory(item);
        setValue('categoryId', item.category.id);
        setValue('position', item.position);
        setValue('images', [item.image]);
        setValue('description', item.description);
        dispatch(appSlice.actions.setContentLoader(false));
      };
      getDataAsync();
    } catch (e: any) {}
  }, []);

  const edit = async (isUpdateImage: boolean, data: TPromotedCategoryData) => {
    try {
      dispatch(appSlice.actions.setContentLoader(true));
      let imageResponse: TResponse<TResponseImages<TImage>>;
      const promotedCategoryData: TRequestPromotedCategoryData = {
        categoryId: data.categoryId,
        description: data.description,
        position: data.position,
        imageId: promotedCategory?.image.id,
      };

      if (isUpdateImage) {
        imageResponse = await uploadImage(data.images);
        promotedCategoryData.imageId = imageResponse.data.items[0].id;
      }

      const result: TResponse<TResponseItem<IPromotedCategoryType>> = await api.edit<TRequestPromotedCategoryData>(
        '/promoted-category',
        id,
        promotedCategoryData,
      );
      setPromotedCategory(result.data.item);
      message.success('Продвигаемая категория успешно сохранена!');
      dispatch(appSlice.actions.setContentLoader(false));
    } catch (e: any) {
      dispatch(appSlice.actions.setContentLoader(false));
      errorHandler(e, setError);
    }
  };

  const onsubmit = async (data: any) => {
    try {
      const isEditedImage: boolean = data.images[0].id !== promotedCategory?.image.id;
      edit(isEditedImage, data);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  return (
    <HomeTemplate withoutScroll={false}>
      <div className="simple-headline">Редактировать продвигаемую категорию</div>
      <div className="base-wrapper mw-1200">
        <form onSubmit={handleSubmit(onsubmit)} noValidate>
          <BaseSelect require={true} title="Категория:" name="categoryId" control={control} error={errors.categoryId}>
            {categories?.items?.map((item: any) => {
              return (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              );
            })}
          </BaseSelect>
          <BaseInput name="description" title="Описание:" error={errors.description} required={true}>
            <input
              id="description"
              {...register('description', {
                required: 'Введите описание',
                maxLength: { value: 255, message: 'Максимальное количество символов 255' },
              })}
            />
          </BaseInput>
          <BaseSelect title="Позиция:" name="position" control={control} error={errors.position} require={true}>
            <Option key={1} value="left">
              Слева
            </Option>
            <Option key={2} value="center">
              По центру
            </Option>
            <Option key={3} value="right">
              Справа
            </Option>
          </BaseSelect>
          <ImgUploader
            maxFileList={1}
            control={control}
            error={errors.images}
            required={true}
            label="Изображение:"
            defaultList={promotedCategory?.image}
          />
          <Button type="primary" htmlType="submit">
            Создать
          </Button>
        </form>
      </div>
    </HomeTemplate>
  );
};

export default EditPromotedCategory;
