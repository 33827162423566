import React, { useEffect } from 'react';
import HomeTemplate from '../../../Templates/homeTemplate';
import { useAppDispatch } from '../../../../hooks/redux';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { IHairTypeType, THairTypeData } from '../../../../types/app/hairTypes';
import { appSlice } from '../../../../redux/reducers/slices/app';
import { api } from '../../../../api';
import { errorHandler } from '../../../../helpers/functions';
import { Button, message } from 'antd';
import { TRequestHairTypeData } from '../../../../api/types/hairType';
import BaseInput from '../../../Atoms/BaseInput';

const EditHairTypePage = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    try {
      const getDataAsync = async () => {
        dispatch(appSlice.actions.setContentLoader(true));
        const {
          data: { item },
        } = await api.get<IHairTypeType>('/hair-type', id);
        setValue('name', item.name);
        dispatch(appSlice.actions.setContentLoader(false));
      };
      getDataAsync();
    } catch (e: any) {}
  }, []);

  const edit = async (data: THairTypeData) => {
    try {
      dispatch(appSlice.actions.setContentLoader(true));
      const skinColorData: TRequestHairTypeData = {
        name: data.name,
      };
      await api.edit<TRequestHairTypeData>('/hair-type', id, skinColorData);
      message.success('Тип волос успешно изменен!');
      dispatch(appSlice.actions.setContentLoader(false));
    } catch (e: any) {
      dispatch(appSlice.actions.setContentLoader(false));
      errorHandler(e, setError);
    }
  };

  const onsubmit = async (data: any) => {
    try {
      edit(data);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  return (
    <HomeTemplate withoutScroll={false} classes="hair-type-edit">
      <div className="simple-headline">Редактировать тип волос</div>
      <div className="base-wrapper mw-1200">
        <form onSubmit={handleSubmit(onsubmit)} noValidate>
          <BaseInput
            name="name"
            title="Тип волос:"
            error={errors.name}
            required={true}
            className="hair-type-edit__name"
          >
            <input
              id="name"
              {...register('name', {
                required: 'Введите тип волос',
                maxLength: { value: 100, message: 'Максимальное количество символов 100' },
              })}
            />
          </BaseInput>
          <Button type="primary" htmlType="submit">
            Сохранить
          </Button>
        </form>
      </div>
    </HomeTemplate>
  );
};

export default EditHairTypePage;
