import { TResponse, TResponseImages, TResponseItems } from '../api/types';
import { api } from '../api';
import { TImage } from '../api/types/images';
import _ from 'lodash';
import { Modal } from 'antd';

export const uploadImage = async (images: any[]): Promise<TResponse<TResponseImages<TImage>>> => {
  const formData = new FormData();
  images.forEach((item: any) => {
    if (item.originFileObj) {
      formData.append('files[]', item.originFileObj);
    }
  });
  const { data, status }: TResponse<TResponseImages<TImage>> = await api.uploadImages(formData);
  return {
    data,
    status,
  };
};

export const errorHandler = (error: any, setError: any, name = 'images') => {
  const data = error?.response?.data?.violations;
  if (data) {
    if (data[0].propertyPath.includes('files')) {
      setError(name, { message: 'Images validation error', backendResponse: data }, { shouldFocus: true });
    } else {
      data.forEach((item: { propertyPath: string; message: string }) => {
        setError(item.propertyPath, { message: item.message }, { shouldFocus: true });
      });
    }
  } else {
    return Promise.reject(error);
  }
};

export const initialItems = (item: any): TResponseItems<typeof item> => ({
  currentPageNumber: 1,
  numItemsPerPage: 10,
  totalCount: 1,
  items: [item],
});

export const parseDateToDMY = (date: string, divider = '.') => {
  const dateParsed = Date.parse(date);
  const dateObject = new Date(dateParsed);

  return `${dateObject.getMonth() + 1 < 10 ? `0${dateObject.getMonth() + 1}` : dateObject.getMonth() + 1}${divider}${
    dateObject.getDate() < 10 ? `0${dateObject.getDate()}` : dateObject.getDate()
  }${divider}${dateObject.getFullYear()}`;
};

export const parseTimeToHM = (date: string, divider = ':') => {
  const dateParsed = Date.parse(date);
  const dateObject = new Date(dateParsed);
  return `${dateObject.getHours() < 10 ? `0${dateObject.getHours()}` : dateObject.getHours()}${divider}${
    dateObject.getMinutes() < 10 ? `0${dateObject.getMinutes()}` : dateObject.getMinutes()
  }`;
};

export const deleteEmptyProperties = (obj: any) => {
  return _.pickBy(obj, function (value) {
    return !(value === undefined || value === '' || value === 0 || _.isEqual(value, [0]));
  });
};

export const isConfirmDelete = <ItemType>(item: ItemType, confirmCallback: (item: ItemType) => void) => {
  Modal.confirm({
    icon: null,
    content: 'Вы действительно хотите удалить запись? После удаления, все связанные элементы будут удалены!',
    okText: 'Подтвердить',
    cancelText: 'Отменить',
    onOk: () => {
      return new Promise((resolve, reject) => {
        return resolve(confirmCallback(item));
      });
    },
  });
};
