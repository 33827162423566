import React, { useCallback, useEffect, useState } from 'react';
import HomeTemplate from '../../Templates/homeTemplate';
import HomeHeader from '../../Molecules/HomeHeader';
import BaseTable from '../../Organisms/BaseTable';
import { useNavigate } from 'react-router-dom';
import { TResponseItems } from '../../../api/types';
import { initialItems, parseDateToDMY, parseTimeToHM } from '../../../helpers/functions';
import { ISlider } from '../../../types/app/slider';
import { Empty, message, Space } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { api } from '../../../api';
import { initialImage } from '../../../helpers/initialValues';

const initialSliderItems: ISlider = {
  id: 0,
  title: '',
  description: '',
  href: '',
  image: initialImage,
  updatedAt: `${new Date()}`,
};

const SliderPage = () => {
  const navigate = useNavigate();

  const [itemsLoader, setItemsLoader] = useState<boolean>(true);
  const [slides, setSlides] = useState<TResponseItems<ISlider>>(initialItems(initialSliderItems));

  const buildSlides = useCallback(() => {
    return slides.items.map((item: ISlider) => {
      return {
        key: item.id,
        id: item.id,
        title: item.title,
        description: item.description,
        href: item.href,
        updated: `${parseDateToDMY(item.updatedAt)} ${parseTimeToHM(item.updatedAt)}`,
        image: { src: item.image?.small, name: item.image?.filename },
      };
    });
  }, [slides.items]);

  useEffect(() => {
    getSlides();
  }, []);

  const getSlides = async (page?: number) => {
    try {
      setItemsLoader(true);
      const slidesResponse = await api.getAll<ISlider>('/slider-items', page);
      setSlides(slidesResponse.data);
      setItemsLoader(false);
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Заголовок',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Ссылка',
      dataIndex: 'href',
      key: 'href',
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Изображение',
      dataIndex: 'image',
      key: 'image',
      render: (item: any) => (
        <div className="small-table-image">
          <img src={item.src} alt={item.name} />
        </div>
      ),
    },
    {
      title: 'Дата изменения',
      dataIndex: 'updated',
      key: 'updated',
    },
    {
      title: 'Действия',
      key: 'action',
      render: (text: ISlider) => (
        <Space size="middle" className="actions">
          <EditOutlined onClick={() => editHandler(text)} />
          <DeleteOutlined onClick={() => deleteHandler(text)} />
        </Space>
      ),
    },
  ];

  const editHandler = (slideItem: ISlider) => {
    navigate(`${slideItem.id}/edit`);
  };

  const deleteHandler = async (slideItem: ISlider) => {
    try {
      setItemsLoader(true);
      const pageNumber =
        (slides.totalCount - 1) % 10 === 0 && slides.currentPageNumber > 1
          ? slides.currentPageNumber - 1
          : slides.currentPageNumber;
      await api.delete('/slider-item', slideItem.id);
      await getSlides(pageNumber);
      message.success('Слайд успешно удален!');
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const onPageChange = (page: number) => {
    getSlides(page);
  };

  const createHandler = () => {
    navigate('create');
  };

  return (
    <HomeTemplate classes="slider-root" withoutScroll={true}>
      <HomeHeader onClick={createHandler} title="Список слайдов" />
      {slides.totalCount ? (
        <BaseTable
          columns={columns}
          data={buildSlides()}
          loader={itemsLoader}
          currentPageNumber={slides.currentPageNumber}
          numItemsPerPage={slides.numItemsPerPage}
          totalCount={slides.totalCount}
          onPageChange={onPageChange}
        />
      ) : (
        <Empty description="Нет данных" />
      )}
    </HomeTemplate>
  );
};

export default SliderPage;
