import React, { useState } from 'react';
import HomeTemplate from '../../../Templates/homeTemplate';
import { useNavigate } from 'react-router-dom';
import { TResponse, TResponseImages } from '../../../../api/types';
import { errorHandler, uploadImage } from '../../../../helpers/functions';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../hooks/redux';
import { api } from '../../../../api';
import { appSlice } from '../../../../redux/reducers/slices/app';
import { TImage } from '../../../../api/types/images';
import { Button, message } from 'antd';
import { TAboutData } from '../../../../types/app/about';
import { TRequestAboutData } from '../../../../api/types/about';
import ImgUploader from '../../../Molecules/ImgUploader';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import BaseInput from '../../../Atoms/BaseInput';
import SimpleEditor from '../../../Atoms/SimpleEditor';

const CreateAbout = () => {
  const navigate = useNavigate();
  const [imageId, setImageId] = useState<number[]>([0]);
  const [editorState, setEditorState] = useState(() => {
    const blocksFromHTML = convertFromHTML('');
    const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

    return EditorState.createWithContent(contentState);
  });
  const [isValidEditor, setValidEditor] = useState<boolean | undefined>(undefined);

  const {
    handleSubmit,
    setError,
    control,
    setValue,
    register,
    formState: { errors },
  } = useForm();

  const dispatch = useAppDispatch();

  const create = async ({ title, image, content }: TAboutData) => {
    try {
      dispatch(appSlice.actions.setContentLoader(true));
      const data: TRequestAboutData = {
        title: title,
        content: content,
        imageId: imageId[0],
      };
      if (!imageId[0]) {
        try {
          const imageResponse: TResponse<TResponseImages<TImage>> = await uploadImage(image);
          data.imageId = imageResponse.data.items[0].id;
          setImageId([imageResponse.data.items[0].id]);
        } catch (e: any) {
          dispatch(appSlice.actions.setContentLoader(false));
          return errorHandler(e, setError, 'image');
        }
      }

      await api.create<TRequestAboutData>('/about', data);

      message.success('Блок о нас успешно добавлен!');
      dispatch(appSlice.actions.setContentLoader(false));
      navigate('/about');
    } catch (e: any) {
      dispatch(appSlice.actions.setContentLoader(false));
      errorHandler(e, setError);
    }
  };

  const onsubmit = async (data: any) => {
    try {
      if (editorState.getCurrentContent().getPlainText().replace(/\s/g, '').length) {
        setValidEditor(true);
        const dataWithEditor: TAboutData = {
          ...data,
          content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        };
        create(dataWithEditor);
      } else {
        setValidEditor(false);
      }
    } catch (e: any) {
      console.log('error', e);
    }
  };

  const onerror = async () => {
    try {
      if (!editorState.getCurrentContent().getPlainText().replace(/\s/g, '').length) {
        setValidEditor(false);
      }
    } catch (e) {}
  };

  return (
    <HomeTemplate classes="lower-body-create" withoutScroll={false}>
      <div className="simple-headline">Создать блок о нас</div>
      <div className="base-wrapper mw-1200">
        <form onSubmit={handleSubmit(onsubmit, onerror)} noValidate>
          <BaseInput name="title" title="Заголовок:" error={errors.title} required={true}>
            <input
              id="title"
              {...register('title', {
                required: 'Введите заголовок',
                maxLength: { value: 50, message: 'Максимальное количество символов 50' },
              })}
            />
          </BaseInput>
          <SimpleEditor
            isValid={isValidEditor}
            required={true}
            editorState={editorState}
            setEditorState={setEditorState}
          />
          <ImgUploader
            setImageId={setImageId}
            maxFileList={1}
            control={control}
            error={errors.image}
            name="image"
            label="Изображение:"
            required={true}
          />
          <Button type="primary" htmlType="submit">
            Создать
          </Button>
        </form>
      </div>
    </HomeTemplate>
  );
};

export default CreateAbout;
