import React, { useState } from 'react';
import BaseInput from '../../../Atoms/BaseInput';
import { Button } from 'antd';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { api } from '../../../../api';
import { LoadingOutlined } from '@ant-design/icons';
import { IOrder } from '../../../../types/app/order';

interface IProps {
  order: IOrder | null;
  onClose: () => void;
  updateOrders: any;
}

const AddTtn = ({ order, onClose, updateOrders }: IProps) => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ttn: order?.ttn === '-' ? '' : order?.ttn || '',
    },
    mode: 'onChange',
  });

  const onsubmit = async (data: any) => {
    try {
      setLoading(true);
      await api.edit<{ ttn: string }>(`order/${order?.id}/ttn`, undefined, data);
      updateOrders((prevState: any) => !prevState);
      onClose();
    } catch (e: any) {
      console.log('error', e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onsubmit)}
      noValidate
      className={`order-modal-form ${!isEmpty(errors) ? 'order-modal-form-has-error' : ''}`}
    >
      <BaseInput name="ttn" title="TTN:" error={errors.ttn} required={true}>
        <input
          id="ttn"
          {...register('ttn', {
            required: 'Введите TTN',
            maxLength: { value: 255, message: 'Максимальное количество символов 255' },
          })}
        />
      </BaseInput>

      <Button type="primary" htmlType="submit">
        Сохранить {loading && <LoadingOutlined />}
      </Button>
    </form>
  );
};

export default AddTtn;
