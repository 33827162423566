export const buildStatus = (status: number) => {
  let statusName = '';
  switch (status) {
    case 0:
      statusName = 'Не оформлен';
      break;
    case 1:
      statusName = 'Оформлен';
      break;
    case 2:
      statusName = 'Не оплачен';
      break;
    case 3:
      statusName = 'Отклонение оплаты';
      break;
    case 4:
      statusName = 'Оплачен';
      break;
    case 5:
      statusName = 'Отправлен';
      break;
    case 6:
      statusName = 'Завершен';
      break;
    default:
      break;
  }
  return statusName;
};
