import React, { useState } from 'react';
import HomeTemplate from '../../../Templates/homeTemplate';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../hooks/redux';
import { appSlice } from '../../../../redux/reducers/slices/app';
import { TResponse, TResponseImages } from '../../../../api/types';
import { TImage } from '../../../../api/types/images';
import { api } from '../../../../api';
import { Button, message } from 'antd';
import { errorHandler } from '../../../../helpers/functions';
import { TMugTypeData } from '../../../../types/app/mugTypes';
import { TRequestMugTypeData } from '../../../../api/types/mugTypes';
import BaseInput from '../../../Atoms/BaseInput';
import ImgUploader from '../../../Molecules/ImgUploader';

const CreateMugType = () => {
  const navigate = useNavigate();
  const [imageId, setImageId] = useState<number[]>([0]);

  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors },
  } = useForm();

  const dispatch = useAppDispatch();

  const create = async ({ name, price, images, weight }: TMugTypeData) => {
    try {
      dispatch(appSlice.actions.setContentLoader(true));
      const formData = new FormData();
      const mugTypeData: TRequestMugTypeData = {
        name: name,
        price: +price,
        weight: +weight,
        imageId: imageId[0],
      };
      if (!imageId[0]) {
        images.forEach((item: any) => {
          formData.append('files[]', item.originFileObj);
        });
        const imageResponse: TResponse<TResponseImages<TImage>> = await api.uploadImages(formData);
        mugTypeData.imageId = imageResponse.data.items[0].id;
        setImageId([imageResponse.data.items[0].id]);
      }

      await api.create<TRequestMugTypeData>('/mug-type', mugTypeData);

      message.success('Тип кружки успешно создан!');
      dispatch(appSlice.actions.setContentLoader(false));
      navigate('/mug-types');
    } catch (e: any) {
      dispatch(appSlice.actions.setContentLoader(false));
      errorHandler(e, setError);
    }
  };

  const onsubmit = async (data: any) => {
    try {
      create(data);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  return (
    <HomeTemplate classes="faq-root" withoutScroll={true}>
      <div className="simple-headline">Создать тип кружки</div>
      <div className="base-wrapper mw-1200">
        <form onSubmit={handleSubmit(onsubmit)} noValidate>
          <BaseInput name="name" title="Имя:" error={errors.name} required={true}>
            <input
              id="name"
              {...register('name', {
                required: 'Введите имя',
                maxLength: { value: 100, message: 'Максимальное количество символов 100' },
              })}
            />
          </BaseInput>
          <BaseInput name="price" title="Цена:" error={errors.price} required={true}>
            <input
              id="price"
              type="number"
              {...register('price', {
                required: 'Укажите цену',
                min: { value: 1, message: 'Укажите положительную цену' },
              })}
            />
          </BaseInput>
          <BaseInput name="weight" title="Вес, грамы:" error={errors.weight} required={true}>
            <input
              id="weight"
              type="number"
              {...register('weight', {
                required: 'Укажите вес',
                min: { value: 1, message: 'Укажите положительное число' },
              })}
            />
          </BaseInput>
          <ImgUploader
            setImageId={setImageId}
            maxFileList={1}
            control={control}
            error={errors.images}
            label="Изображение:"
          />
          <Button type="primary" htmlType="submit">
            Создать
          </Button>
        </form>
      </div>
    </HomeTemplate>
  );
};

export default CreateMugType;
