import React, { useState } from 'react';
import { IOrder } from '../../../../types/app/order';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { Button, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import BaseSelect from '../../../Atoms/BaseAsyncSelect';
import { api } from '../../../../api';
import { buildStatus } from '../helpers';

interface IProps {
  order: IOrder | null;
  onClose: () => void;
  updateOrders: any;
}

const { Option } = Select;

const UpdateStatus = ({ order, onClose, updateOrders }: IProps) => {
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      status: order?.status,
    },
    mode: 'onChange',
  });

  const onsubmit = async (data: any) => {
    try {
      setLoading(true);
      await api.edit<{ status: string }>(`order/${order?.id}/status`, undefined, data);
      updateOrders((prevState: any) => !prevState);
      onClose();
    } catch (e: any) {
      console.log('error', e);
    } finally {
      setLoading(false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const buildStatuses = [...Array(6).keys()].map((item) => {
    return {
      id: item,
      name: buildStatus(item),
    };
  });

  return (
    <form
      onSubmit={handleSubmit(onsubmit)}
      noValidate
      className={`order-modal-form ${!isEmpty(errors) ? 'order-modal-form-has-error' : ''}`}
    >
      <BaseSelect title="Статус:" name="status" control={control}>
        {buildStatuses.map((item: any) => {
          return (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          );
        })}
      </BaseSelect>

      <Button type="primary" htmlType="submit">
        Сохранить {loading && <LoadingOutlined />}
      </Button>
    </form>
  );
};

export default UpdateStatus;
