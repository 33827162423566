import React, { useEffect, useState } from 'react';
import HomeTemplate from '../../../Templates/homeTemplate';
import { useAppDispatch } from '../../../../hooks/redux';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ICategoryType, TCategoryData } from '../../../../types/app/categories';
import { appSlice } from '../../../../redux/reducers/slices/app';
import { api } from '../../../../api';
import { TImage } from '../../../../api/types/images';
import { TResponse, TResponseImages, TResponseItem, TResponseItems } from '../../../../api/types';
import { deleteEmptyProperties, errorHandler, initialItems, uploadImage } from '../../../../helpers/functions';
import { Button, message, Select } from 'antd';
import { TRequestCategoryData } from '../../../../api/types/categories';
import _ from 'lodash';
import BaseSelect from '../../../Atoms/BaseAsyncSelect';
import BaseInput from '../../../Atoms/BaseInput';
import BaseDateTimePicker from '../../../Atoms/BaseDateTimePicker';
import ImgUploader from '../../../Molecules/ImgUploader';
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import { initialCategoryItems } from '../index';

const { Option } = Select;

const EditCategory = () => {
  const [category, setCategory] = useState<ICategoryType>();
  const [categories, setCategories] = useState<TResponseItems<ICategoryType>>(initialItems(initialCategoryItems));
  const [imageId, setImageId] = useState<number[]>([0]);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    control,
    formState: { errors, isSubmitted },
  } = useForm();

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    try {
      const categoriesResponse = await api.getAll<ICategoryType>('/categories', 1, 500);
      setCategories(categoriesResponse.data);
    } catch (e: any) {
      console.log(e);
    }
  };

  useEffect(() => {
    try {
      const getDataAsync = async () => {
        dispatch(appSlice.actions.setContentLoader(true));
        const {
          data: { item },
        } = await api.get<ICategoryType>('/category', id);
        setCategory(item);
        setImageId([Number(item.image?.id)]);
        setValue('name', item.name);
        setValue('slug', item.slug);
        setValue('oldPrice', item.oldPrice);
        setValue('newPrice', item.newPrice);
        setValue('caption', item.caption);
        setValue('endOfSaleAt', item.endOfSaleAt);
        setValue('warningText', item.warningText);
        setValue('additionalConditionsText', item.additionalConditionsText);
        setValue('description', item.description);
        setValue('images', [item.image]);
        setValue('categoryId', item.parent === null ? 0 : item.parent.id);
        setValue('metaDescription', item.meta.description || '');
        setValue('keywords', item.meta.keywords || '');
        setValue('title', item.meta.title || '');
        dispatch(appSlice.actions.setContentLoader(false));
      };
      getDataAsync();
    } catch (e: any) {}
  }, []);

  const edit = async (isUpdateImage: boolean, data: TCategoryData) => {
    const cloneData: any = { ...data };
    cloneData.newPrice = +cloneData.newPrice;
    cloneData.oldPrice = +cloneData.oldPrice;
    delete cloneData.images;
    delete cloneData.title;
    delete cloneData.metaDescription;
    delete cloneData.keywords;
    try {
      dispatch(appSlice.actions.setContentLoader(true));
      let imageResponse: TResponse<TResponseImages<TImage>>;
      const categoryData: TRequestCategoryData = {
        ...cloneData,
        imageId: category?.image?.id ?? 0,
        meta: {
          title: data.title || null,
          description: data.metaDescription || null,
          keywords: data.keywords || null,
        },
      };

      if (isUpdateImage && data.images) {
        imageResponse = await uploadImage(data.images);
        categoryData.imageId = imageResponse.data.items[0].id;
        setImageId([imageResponse.data.items[0].id]);
      }

      if (_.isEmpty(data.images)) {
        delete categoryData.imageId;
      }

      const result: TResponse<TResponseItem<ICategoryType>> = await api.edit<TRequestCategoryData>(
        '/category',
        category?.id,
        deleteEmptyProperties(categoryData),
      );
      setCategory(result.data.item);
      message.success('Категория успешно сохранена!');
      dispatch(appSlice.actions.setContentLoader(false));
    } catch (e: any) {
      dispatch(appSlice.actions.setContentLoader(false));
      errorHandler(e, setError);
    }
  };

  const onsubmit = async (data: any) => {
    try {
      const isEditedImage = (): boolean => {
        if (!data.images.length || !data.images[0]) {
          return false;
        }
        return !imageId[0];
      };

      edit(isEditedImage(), data);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  const nameHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setValue('slug', new CyrillicToTranslit().transform(e?.currentTarget.value, '-'), { shouldValidate: isSubmitted });
  };

  return (
    <HomeTemplate classes="faq-edit" withoutScroll={false}>
      <div className="simple-headline">Редактировать категорию</div>
      <div className="base-wrapper mw-1200">
        <form onSubmit={handleSubmit(onsubmit)} noValidate>
          <BaseInput name="name" title="Имя:" error={errors.name} required={true}>
            <input
              id="name"
              onInput={nameHandler}
              {...register('name', {
                required: 'Введите имя',
                maxLength: { value: 100, message: 'Максимальное количество символов 100' },
              })}
            />
          </BaseInput>
          <BaseInput name="slug" title="Slug:" error={errors.slug} required={true}>
            <input
              id="slug"
              {...register('slug', {
                required: 'Введите slug',
                maxLength: { value: 255, message: 'Максимальное количество символов 255' },
              })}
            />
          </BaseInput>
          <BaseSelect title="Родительская категория:" name="categoryId" control={control}>
            <Option key={0} value={0}>
              Без категории
            </Option>
            {categories.items?.map((item: any) => {
              return (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              );
            })}
          </BaseSelect>
          <BaseInput name="oldPrice" title="Старая цена:" error={errors.oldPrice} required={true}>
            <input
              id="oldPrice"
              type="number"
              {...register('oldPrice', {
                required: 'Укажите цену',
                min: { value: 1, message: 'Укажите положительную цену' },
              })}
            />
          </BaseInput>
          <BaseInput name="newPrice" title="Новая цена:" error={errors.newPrice} required={false}>
            <input
              id="newPrice"
              type="number"
              {...register('newPrice', {
                min: { value: 1, message: 'Укажите положительную цену' },
              })}
            />
          </BaseInput>
          <BaseDateTimePicker label="Конец скидки:" required={false} control={control} name="endOfSaleAt" />
          <BaseInput name="warningText" title="Предупреждение:" error={errors.warningText} required={false}>
            <input
              id="warningText"
              {...register('warningText', {
                maxLength: { value: 255, message: 'Максимальное количество символов 255' },
              })}
            />
          </BaseInput>
          <BaseInput name="description" title="Описание:" error={errors.description} required={true}>
            <textarea
              {...register('description', {
                required: 'Введите описание',
                maxLength: { value: 500, message: 'Максимальное количество символов 500' },
              })}
              id="description"
            />
          </BaseInput>
          <BaseInput name="caption" title="Заголовок:" error={errors.caption} required={true}>
            <textarea
              {...register('caption', {
                required: 'Введите заголовок',
                maxLength: { value: 255, message: 'Максимальное количество символов 255' },
              })}
              id="caption"
            />
          </BaseInput>
          <BaseInput
            name="additionalConditionsText"
            title="Дополнительная информация:"
            error={errors.additionalConditionsText}
            required={false}
          >
            <textarea
              id="additionalConditionsText"
              {...register('additionalConditionsText', {
                maxLength: { value: 255, message: 'Максимальное количество символов 255' },
              })}
            />
          </BaseInput>
          <BaseInput name="title" title="Meta title:" error={errors.title}>
            <input id="title" {...register('title')} />
          </BaseInput>
          <BaseInput name="metaDescription" title="Meta description:" error={errors.metaDescription}>
            <textarea {...register('metaDescription', {})} id="metaDescription" />
          </BaseInput>
          <BaseInput name="keywords" title="Meta Keywords:" error={errors.keywords}>
            <textarea {...register('keywords', {})} id="keywords" />
          </BaseInput>
          <ImgUploader
            setImageId={setImageId}
            maxFileList={1}
            required={false}
            control={control}
            error={errors.images}
            label="Изображение:"
            defaultList={category?.image}
          />
          <Button type="primary" htmlType="submit">
            Сохранить
          </Button>
        </form>
      </div>
    </HomeTemplate>
  );
};

export default EditCategory;
