import React, { useCallback, useEffect, useState } from 'react';
import { IOrder, IOrderItem } from '../../../../types/app/order';
import { api } from '../../../../api';
import './styles.scss';
import { Button, Empty, Modal } from 'antd';
import BaseTable from '../../../Organisms/BaseTable';
import mugType from '../../MugType';
import { Link } from 'react-router-dom';
import MugImage from './MugImage';
import { DeleteOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import { API_URL } from '../../../../api/axiosInstance';
import { token } from '../../../../helpers';

interface IProps {
  order: IOrder;
}

const ShowDetails = ({ order }: IProps) => {
  const [itemsLoader, setItemsLoader] = useState<boolean>(false);
  const [orderItems, setOrderItems] = useState<IOrderItem[]>([]);
  const [showImageModal, setShowImageModal] = useState<(IOrderItem & { side: number }) | null>(null);
  const [loading, setLoading] = useState<{ [key: number]: boolean }>({ 0: false });

  console.log(order);

  useEffect(() => {
    setItemsLoader(true);
    try {
      const getData = async () => {
        const items = await api
          .getAllWithoutPagination<IOrderItem>(`order/${order.id}/items`)
          .then((res) => res.data.items);
        setOrderItems(items);

        const loadingObj: any = {};
        const loadings = items.forEach((item) => {
          loadingObj[item.id] = false;
        });

        setLoading(loadingObj);
        setItemsLoader(false);
      };
      getData();
    } catch (e) {
      setItemsLoader(false);
    }
  }, []);

  const buildData = useCallback(() => {
    return orderItems?.map((item: IOrderItem) => {
      return {
        id: item.id,
        key: item.id,
        frontSideImage: item,
        backSideImage: item,
        product: item.product,
        quantity: item.quantity,
        mugType: item.mugType,
        typePackaging: item.typePackaging,
        price:
          (item.product.newPrice || item.product.oldPrice || item.category.newPrice || item.category.oldPrice) +
          item.mugType.price +
          (item.postcardType?.price || 0),
        postcardType: item.postcardType,
        action: item,
      };
    });
  }, [orderItems]);

  const buildOrderImage = (orderItem: IOrderItem, side: 0 | 1) => {
    return (
      <div className="order-image-preview" onClick={() => setShowImageModal({ ...orderItem, side })}>
        <img
          src={side === 0 ? orderItem.frontSideImage.medium : orderItem.backSideImage.medium}
          alt={side === 0 ? orderItem.frontSideImage.filename : orderItem.backSideImage.filename}
        />
      </div>
    );
  };

  const downloadPdf = async (item: IOrderItem) => {
    try {
      setLoading((prevState) => ({ ...prevState, [item.id]: true }));
      const pdf = await axios.get(`${API_URL}/order-item/${item.id}/layout`, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token.get().token}`,
        },
      });
      const blob = new Blob([pdf.data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      console.log(item);
      link.download = `${order.number}_${item.id}.pdf`;
      link.click();
    } finally {
      setLoading((prevState) => ({ ...prevState, [item.id]: false }));
    }
  };

  const columns = [
    {
      title: 'Лицевая сторона',
      dataIndex: 'frontSideImage',
      key: 'frontSideImage',
      render: (item: IOrderItem) => buildOrderImage(item, 0),
    },
    {
      title: 'Оборотная сторона',
      dataIndex: 'backSideImage',
      key: 'backSideImage',
      render: (item: IOrderItem) => buildOrderImage(item, 1),
    },
    {
      title: 'Товар',
      dataIndex: 'product',
      key: 'product',
      render: (item: IOrderItem['product']) => <Link to={`/products/${item.slug}/edit`}>{item.name}</Link>,
    },
    {
      title: 'Тип кружки',
      dataIndex: 'mugType',
      key: 'mugType',
      render: (item: IOrderItem['mugType']) => <Link to={`/mug-types/${item.id}/edit`}>{item.name}</Link>,
    },
    {
      title: 'Тип открытки',
      dataIndex: 'postcardType',
      key: 'postcardType',
      render: (item: IOrderItem['postcardType'] | null) =>
        item ? <Link to={`/postcard-types/${item.id}/edit`}>{item.name}</Link> : 'Не выбрано',
    },
    {
      title: 'Тип упаковки',
      dataIndex: 'typePackaging',
      key: 'typePackaging',
      render: (item: IOrderItem['typePackaging'] | null) =>
        item ? <Link to={`/packaging-types/${item.id}/edit`}>{item.name}</Link> : 'Не выбрано',
    },
    {
      title: 'Количество',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Цена/шт',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Действие',
      key: 'action',
      width: 150,
      render: (item: IOrderItem) => (
        <div className="actions-wrapper">
          <Button
            disabled={loading[item.id] || false}
            type="primary"
            htmlType="button"
            onClick={() => downloadPdf(item)}
          >
            Скачать PDF {(loading[item.id] || false) && <LoadingOutlined />}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div>
        {orderItems?.length || itemsLoader ? (
          <>
            <BaseTable columns={columns} data={buildData()} loader={itemsLoader} />
          </>
        ) : (
          <Empty description="Нет данных" />
        )}
      </div>
      {showImageModal && (
        <Modal
          width={'max-content'}
          style={{ top: 30 }}
          visible={true}
          title="Персональный дизайн"
          footer={null}
          onCancel={() => setShowImageModal(null)}
        >
          <MugImage orderItem={showImageModal} />
        </Modal>
      )}
    </>
  );
};

export default ShowDetails;
