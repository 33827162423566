import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './components/Layouts/App';
import { store } from './redux/store';
import 'normalize.css';
import './assets/styles/main.scss';
import { BrowserRouter } from 'react-router-dom';
import 'moment/locale/ru';
import ru_RU from 'antd/lib/locale/ru_RU';
import { ConfigProvider } from 'antd';

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={ru_RU}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
