import React, { useEffect, useState } from 'react';
import HomeHeader from '../../../Molecules/HomeHeader';
import HomeTemplate from '../../../Templates/homeTemplate';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../hooks/redux';
import { appSlice } from '../../../../redux/reducers/slices/app';
import { settingsApi } from '../../../../api/settings';
import { TCdekDelivery } from '../../../../types/app/delivery';

export const initialSettings: TCdekDelivery = {
  clientSecret: '',
  clientId: '',
  height: 0,
  initCity: '',
  length: 0,
  width: 0,
};

const CdekPage = () => {
  const [delivery, setDelivery] = useState<TCdekDelivery>(initialSettings);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const getData = async () => {
    try {
      dispatch(appSlice.actions.setContentLoader(true));
      const response = await settingsApi.get<TCdekDelivery>('admin/delivery-service/cdek');
      setDelivery(response.data.item);
      dispatch(appSlice.actions.setContentLoader(false));
    } catch (e: any) {
      console.log(e);
      dispatch(appSlice.actions.setContentLoader(false));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  const editHandler = () => {
    navigate(`edit`);
  };

  return (
    <HomeTemplate classes="delivery" withoutScroll={false}>
      <HomeHeader buttonName="Изменить" onClick={editHandler} title="СДЭК доставка" />
      <div className="settings-item">
        <div className="settings-item__title">Client secret: </div>
        <div className="settings-item__value">{delivery.clientSecret ? delivery.clientSecret : '-'}</div>
      </div>
      <div className="settings-item">
        <div className="settings-item__title">Client id: </div>
        <div className="settings-item__value">{delivery.clientId ? delivery.clientId : '-'}</div>
      </div>
      <div className="settings-item">
        <div className="settings-item__title">Город по умолчанию: </div>
        <div className="settings-item__value">{delivery.initCity ? delivery.initCity : '-'}</div>
      </div>
      <div className="settings-item">
        <div className="settings-item__title">Ширина: </div>
        <div className="settings-item__value">{delivery.width ? delivery.width : '-'}</div>
      </div>
      <div className="settings-item">
        <div className="settings-item__title">Высота: </div>
        <div className="settings-item__value">{delivery.height ? delivery.height : '-'}</div>
      </div>
      <div className="settings-item">
        <div className="settings-item__title">Длина: </div>
        <div className="settings-item__value">{delivery.length ? delivery.length : '-'}</div>
      </div>
    </HomeTemplate>
  );
};

export default CdekPage;
