import React, { useState } from 'react';
import './style.scss';
import HomeTemplate from '../../../Templates/homeTemplate';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import BaseInput from '../../../Atoms/BaseInput';
import { useForm } from 'react-hook-form';
import { Button, Input, message } from 'antd';
import SimpleEditor from '../../../Atoms/SimpleEditor';
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import ImgUploader from '../../../Molecules/ImgUploader';
import { api } from '../../../../api';
import { useAppDispatch } from '../../../../hooks/redux';
import { useNavigate } from 'react-router-dom';
import { appSlice } from '../../../../redux/reducers/slices/app';
import { TFaqData } from '../../../../types/app/faq';
import { TResponse, TResponseImages } from '../../../../api/types';
import { TImage } from '../../../../api/types/images';
import { errorHandler } from '../../../../helpers/functions';
import { TRequestFaqData } from '../../../../api/types/faq';

const FaqCreate = () => {
  const [editorState, setEditorState] = useState(() => {
    const blocksFromHTML = convertFromHTML('');
    const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

    return EditorState.createWithContent(contentState);
  });
  const navigate = useNavigate();
  const [isValidEditor, setValidEditor] = useState<boolean | undefined>(undefined);
  const [imageId, setImageId] = useState<number[]>([0]);

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    control,
    formState: { errors, isSubmitted },
  } = useForm();

  const dispatch = useAppDispatch();

  const createFaq = async ({ name, text, slug, images, keywords, title, description }: TFaqData) => {
    try {
      dispatch(appSlice.actions.setContentLoader(true));
      const formData = new FormData();
      const faqData: TRequestFaqData = {
        name: name,
        slug: slug,
        text: text,
        imageId: imageId[0],
        meta: {
          title,
          keywords,
          description,
        },
      };
      if (!imageId[0]) {
        images.forEach((item: any) => {
          formData.append('files[]', item.originFileObj);
        });
        const imageResponse: TResponse<TResponseImages<TImage>> = await api.uploadImages(formData);
        faqData.imageId = imageResponse.data.items[0].id;
        setImageId([imageResponse.data.items[0].id]);
      }

      await api.create<TRequestFaqData>('/faq', faqData);

      message.success('FAQ успешно создан!');
      dispatch(appSlice.actions.setContentLoader(false));
      navigate('/faq');
    } catch (e: any) {
      dispatch(appSlice.actions.setContentLoader(false));
      errorHandler(e, setError);
    }
  };

  const onsubmit = async (data: any) => {
    try {
      if (editorState.getCurrentContent().getPlainText().replace(/\s/g, '').length) {
        setValidEditor(true);
        const dataWithEditor: TFaqData = { ...data, text: draftToHtml(convertToRaw(editorState.getCurrentContent())) };
        createFaq(dataWithEditor);
      } else {
        setValidEditor(false);
      }
    } catch (e: any) {
      console.log('error', e);
    }
  };

  const onerror = async () => {
    try {
      if (!editorState.getCurrentContent().getPlainText().replace(/\s/g, '').length) {
        setValidEditor(false);
      }
    } catch (e) {}
  };

  const nameHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setValue('slug', new CyrillicToTranslit().transform(e?.currentTarget.value, '-'), { shouldValidate: isSubmitted });
  };

  return (
    <HomeTemplate classes="faq-edit" withoutScroll={false}>
      <div className="simple-headline">Создать FAQ</div>
      <div className="base-wrapper mw-1200">
        <form onSubmit={handleSubmit(onsubmit, onerror)} noValidate>
          <BaseInput name="name" title="Имя:" error={errors.name} required={true}>
            <input
              id="name"
              onInput={nameHandler}
              {...register('name', {
                required: 'Введите имя',
                maxLength: { value: 100, message: 'Максимальное количество символов 100' },
              })}
            />
          </BaseInput>
          <BaseInput name="slug" title="Slug:" error={errors.slug} required={true}>
            <input
              id="slug"
              {...register('slug', {
                required: 'Введите slug',
                maxLength: { value: 255, message: 'Максимальное количество символов 255' },
              })}
            />
          </BaseInput>
          <SimpleEditor
            isValid={isValidEditor}
            required={true}
            editorState={editorState}
            setEditorState={setEditorState}
          />
          <BaseInput name="title" title="Meta title:" error={errors.title}>
            <input id="title" {...register('title')} />
          </BaseInput>
          <BaseInput name="description" title="Meta description:" error={errors.description}>
            <textarea {...register('description', {})} id="description" />
          </BaseInput>
          <BaseInput name="keywords" title="Meta Keywords:" error={errors.keywords}>
            <textarea {...register('keywords', {})} id="keywords" />
          </BaseInput>
          <ImgUploader
            setImageId={setImageId}
            maxFileList={1}
            control={control}
            error={errors.images}
            label="Изображение:"
          />
          <Button type="primary" htmlType="submit">
            Создать
          </Button>
        </form>
      </div>
    </HomeTemplate>
  );
};

export default FaqCreate;
