import React from 'react';
import { Pagination, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import './style.scss';
import { config } from '../../../helpers/config';
import { Simulate } from 'react-dom/test-utils';

interface IBaseTable {
  columns: any;
  data: any;
  loader?: boolean;
  currentPageNumber?: number;
  numItemsPerPage?: number;
  totalCount?: number;
  onPageChange?: (page: number, pageSize?: number | undefined) => void;
  scroll?: any;
}

const BaseTable: React.FC<IBaseTable> = ({
  columns,
  data,
  loader,
  totalCount,
  currentPageNumber,
  numItemsPerPage,
  onPageChange,
  scroll,
}: IBaseTable) => {
  return (
    <>
      <Table
        scroll={scroll}
        columns={columns}
        dataSource={data}
        className="base-table"
        loading={loader}
        pagination={false}
        // sticky={true}
      />
      {(totalCount as number) > config.PAGE_SIZE_FOR_PAGINATION && (
        <Pagination
          current={currentPageNumber}
          pageSize={numItemsPerPage}
          total={totalCount}
          showSizeChanger={false}
          onChange={onPageChange}
        />
      )}
    </>
  );
};

export default BaseTable;
