import React from 'react';
import { DatePicker, Upload } from 'antd';
import './style.scss';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { Moment } from 'moment';
import moment from 'moment';

type TBaseDateTimePicker = {
  classes?: string;
  label: string;
  required?: boolean;
  control: Control<FieldValues>;
  name: string;
};

const BaseDateTimePicker: React.FC<TBaseDateTimePicker> = ({
  classes,
  label,
  required,
  control,
  name,
}: TBaseDateTimePicker) => {
  const dateHandler = (value: Moment | null, dateString: string, onChange: any) => {
    onChange(value ? value.format() : '');
  };

  const getRules = () => {
    const rules: any = {};
    if (required) {
      rules.required = 'Выберите дату';
    }
    return rules;
  };

  return (
    <div className={`base-date-time-picker ${classes ? classes : ''}`}>
      <span className="base-date-time-picker__label">
        {label} {required && <span>*</span>}
      </span>
      <Controller
        control={control}
        name={name}
        rules={getRules()}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <DatePicker
            ref={ref}
            format="YYYY-MM-DD HH:mm"
            showTime={{
              format: 'HH:mm',
            }}
            value={value ? moment(value) : null}
            onChange={(value: Moment | null, dateString: string) => dateHandler(value, dateString, onChange)}
          />
        )}
      />
    </div>
  );
};

export default BaseDateTimePicker;
