import React, { useCallback, useEffect, useState } from 'react';
import HomeTemplate from '../../Templates/homeTemplate';
import HomeHeader from '../../Molecules/HomeHeader';
import BaseTable from '../../Organisms/BaseTable';
import { Empty, Modal, Select } from 'antd';
import { IOrder, TPromoCode } from '../../../types/app/order';
import { initialItems, parseDateToDMY, parseTimeToHM } from '../../../helpers/functions';
import { api } from '../../../api';
import { TResponseItems } from '../../../api/types';
import './styles.scss';
import { createSearchParams, Link, useNavigate, useSearchParams } from 'react-router-dom';
import AddTtn from './AddTtn';
import { isEmpty } from 'lodash';
import UpdateStatus from './UpdateStatus';
import { buildStatus } from './helpers';
import { config } from '../../../helpers/config';
import ShowDetails from './ShowDetails';

const { Option } = Select;

const initialOrder: IOrder = {
  id: 0,
  number: '',
  address: '',
  deliveryDepartmentId: '',
  deliveryPrice: 0,
  deliveryType: null,
  isSendPercentDiscountForNotIssuedOrder: false,
  isAppliedEmailDiscount: false,
  phone: '',
  promoCode: null,
  status: 0,
  ttn: null,
  zip: null,
  totalPrice: 0,
  fullName: '',
  email: '',
  createdAt: '',
  updatedAt: '',
};

const Orders = () => {
  const [itemsLoader, setItemsLoader] = useState<boolean>(true);
  const [orders, setOrders] = useState<TResponseItems<IOrder>>(initialItems(initialOrder));
  const [addTtnModal, setAddTtnModal] = useState<IOrder | null>(null);
  const [updateStatusModal, setUpdateStatusModal] = useState<IOrder | null>(null);
  const [showDetailsModal, setShowDetailsModal] = useState<IOrder | null>(null);
  const [updateOrders, setUpdateOrders] = useState<boolean | null>(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const getData = async (page?: number) => {
    const pageNumber = page || Number(searchParams.get('page')) || 1;
    try {
      setItemsLoader(true);
      const orders = await api.getAll<IOrder>('/orders', pageNumber);
      setOrders(orders.data);
      setItemsLoader(false);
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  useEffect(() => {
    if (updateOrders !== null) {
      getData();
    }
  }, [updateOrders]);

  const actionsHandler = (e: any, item: IOrder) => {
    switch (e) {
      case '1':
        setShowDetailsModal(item);
        break;
      case '2':
        setUpdateStatusModal(item);
        break;
      case '3':
        setAddTtnModal(item);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const buildData = useCallback(() => {
    return orders.items.map((item: IOrder) => {
      return {
        id: item.id,
        key: item.id,
        number: item.number,
        fullName: item.fullName,
        email: item.email,
        phone: item.phone,
        status: item.status,
        totalPrice: item.totalPrice,
        deliveryType: item.deliveryType || '-',
        address: item.address || '-',
        deliveryPrice: item.deliveryPrice || '-',
        zip: item.zip || '-',
        ttn: item.ttn || '-',
        promoCode: item.promoCode,
        isAppliedEmailDiscount: !!item.isAppliedEmailDiscount,
        createdAt: item.createdAt ? `${parseDateToDMY(item.createdAt)} ${parseTimeToHM(item.createdAt)}` : '-',
        updated: item.updatedAt ? `${parseDateToDMY(item.updatedAt)} ${parseTimeToHM(item.updatedAt)}` : '-',
      };
    });
  }, [orders.items]);

  const columns = [
    {
      title: 'Номер заказа',
      dataIndex: 'number',
      key: 'number',
      fixed: 'left',
      width: 130,
    },
    {
      title: 'Полное имя',
      dataIndex: 'fullName',
      key: 'fullName',
      width: 130,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      key: 'phone',
      width: 160,
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      render: (status: number) => <span>{buildStatus(status)}</span>,
      width: 130,
    },
    {
      title: 'Цена',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      width: 125,
    },
    {
      title: 'TTN',
      dataIndex: 'ttn',
      key: 'ttn',
      width: 75,
    },
    {
      title: 'Тип доставки',
      dataIndex: 'deliveryType',
      key: 'deliveryType',
      width: 125,
    },
    {
      title: 'Адрес',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Стоимость доставки',
      dataIndex: 'deliveryPrice',
      key: 'deliveryPrice',
      width: 175,
    },
    {
      title: 'Zip',
      dataIndex: 'zip',
      key: 'zip',
      width: 100,
    },
    {
      title: 'Email скидка',
      dataIndex: 'isAppliedEmailDiscount',
      key: 'isAppliedEmailDiscount',
      render: (emailDiscount: boolean) => <span>{emailDiscount ? 'Да' : 'Нет'}</span>,
      width: 125,
    },
    {
      title: 'Промокод',
      dataIndex: 'promoCode',
      key: 'promoCode',
      render: (promoCode: TPromoCode | null) => (
        <span>{promoCode ? <Link to={`/promo-codes/${promoCode.id}/edit`}>{promoCode.name}</Link> : '-'}</span>
      ),
      width: 110,
    },
    {
      title: 'Дата Создания',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 160,
    },
    {
      title: 'Дата изменения',
      dataIndex: 'updated',
      key: 'updated',
      width: 150,
    },
    {
      title: 'Действие',
      key: 'action',
      fixed: 'right',
      width: 150,
      render: (item: IOrder) => (
        <div className="actions-wrapper">
          <Select
            defaultValue="Действия"
            style={{ width: 140 }}
            value="Действия"
            onSelect={(e) => actionsHandler(e, item)}
            dropdownClassName="order-actions"
          >
            <Option value="Действия">Выбрать</Option>
            <Option value="1">Детали заказа</Option>
            <Option value="2">Обновить статус</Option>
            <Option value="3">Записать ТТН</Option>
          </Select>
        </div>
      ),
    },
  ];

  const onPageChange = (page: number) => {
    getData(page);
    navigate({
      search: createSearchParams({
        page: `${page}`,
      }).toString(),
    });
  };

  return (
    <>
      <HomeTemplate
        classes={`orders-page ${orders.totalCount > config.PAGE_SIZE_FOR_PAGINATION ? 'with-pagination' : ''}`}
        withoutScroll={true}
      >
        <HomeHeader title="Заказы" />
        {orders.items?.length ? (
          <BaseTable
            columns={columns}
            data={buildData()}
            loader={itemsLoader}
            scroll={{ x: 'max-content' }}
            currentPageNumber={orders.currentPageNumber}
            numItemsPerPage={orders.numItemsPerPage}
            totalCount={orders.totalCount}
            onPageChange={onPageChange}
          />
        ) : (
          <Empty description="Нет данных" />
        )}
      </HomeTemplate>
      {!!addTtnModal && !isEmpty(addTtnModal) && (
        <Modal visible={true} title="Добавить TTN" footer={null} onCancel={() => setAddTtnModal(null)}>
          <AddTtn order={addTtnModal} onClose={() => setAddTtnModal(null)} updateOrders={setUpdateOrders} />
        </Modal>
      )}
      {!!updateStatusModal && !isEmpty(updateStatusModal) && (
        <Modal visible={true} title="Обновить статус" footer={null} onCancel={() => setUpdateStatusModal(null)}>
          <UpdateStatus
            order={updateStatusModal}
            onClose={() => setUpdateStatusModal(null)}
            updateOrders={setUpdateOrders}
          />
        </Modal>
      )}
      {showDetailsModal && (
        <Modal
          width={'90%'}
          style={{ top: 30 }}
          visible={true}
          title="Детали заказа"
          footer={null}
          onCancel={() => setShowDetailsModal(null)}
        >
          <ShowDetails order={showDetailsModal} />
        </Modal>
      )}
    </>
  );
};

export default Orders;
