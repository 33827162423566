import React from 'react';
import './style.scss';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';

type TSimpleEditor = {
  required?: boolean;
  editorState: EditorState;
  setEditorState: any;
  isValid: boolean | undefined;
  errorMessage?: string;
};

const SimpleEditor: React.FC<TSimpleEditor> = ({
  required,
  isValid,
  editorState,
  setEditorState,
  errorMessage,
}: TSimpleEditor) => {
  const validateEditor = () => {
    return (
      isValid !== undefined && required && !editorState.getCurrentContent().getPlainText().replace(/\s/g, '').length
    );
  };
  return (
    <div className="simple-editor">
      <div className="simple-editor__title">Контент {required && <span>*</span>}</div>
      <Editor
        toolbar={{
          options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link', 'image', 'history'],
        }}
        editorState={editorState}
        onEditorStateChange={setEditorState}
        wrapperClassName={validateEditor() ? 'simple-editor__error' : ''}
      />
      {validateEditor() && <span className="simple-editor__error-message">{errorMessage ?? 'Заполните контент'}</span>}
    </div>
  );
};

export default SimpleEditor;
