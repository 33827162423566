import React from 'react';
import HomeTemplate from '../../../Templates/homeTemplate';
import { useNavigate } from 'react-router-dom';
import { deleteEmptyProperties, errorHandler } from '../../../../helpers/functions';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../hooks/redux';
import { appSlice } from '../../../../redux/reducers/slices/app';
import { api } from '../../../../api';
import { Button, message } from 'antd';
import { TSubscriptionData } from '../../../../types/app/subscription';
import { TRequestSubscriptionData } from '../../../../api/types/substiptions';
import BaseInput from '../../../Atoms/BaseInput';

const CreateSubscription = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    setError,
    register,
    formState: { errors },
  } = useForm();

  const dispatch = useAppDispatch();

  const create = async ({ name, email, phone }: TSubscriptionData) => {
    try {
      dispatch(appSlice.actions.setContentLoader(true));
      const data: TRequestSubscriptionData = {
        name,
        email,
        phone,
      };

      await api.create<TRequestSubscriptionData>('/subscription', deleteEmptyProperties(data));

      message.success('Подписка успешно добавлена!');
      dispatch(appSlice.actions.setContentLoader(false));
      navigate('/subscriptions');
    } catch (e: any) {
      dispatch(appSlice.actions.setContentLoader(false));
      errorHandler(e, setError);
    }
  };

  const onsubmit = async (data: any) => {
    try {
      create(data);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  return (
    <HomeTemplate classes="lower-body-create" withoutScroll={false}>
      <div className="simple-headline">Создать подписку</div>
      <div className="base-wrapper mw-1200">
        <form onSubmit={handleSubmit(onsubmit)} noValidate>
          <BaseInput name="name" title="Имя:" error={errors.name}>
            <input
              id="name"
              {...register('name', {
                maxLength: { value: 100, message: 'Максимальное количество символов 100' },
              })}
            />
          </BaseInput>
          <BaseInput name="phone" title="Телефон:" error={errors.phone}>
            <input
              id="phone"
              {...register('phone', {
                maxLength: { value: 20, message: 'Максимальное количество символов 20' },
              })}
            />
          </BaseInput>
          <BaseInput name="email" title="Email:" error={errors.email} required={true}>
            <input
              id="email"
              type="email"
              {...register('email', {
                required: 'Укажите email',
                maxLength: { value: 255, message: 'Максимальное количество символов 255' },
              })}
            />
          </BaseInput>
          <Button type="primary" htmlType="submit">
            Создать
          </Button>
        </form>
      </div>
    </HomeTemplate>
  );
};

export default CreateSubscription;
