import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

export const token = {
  get: () => ({
    token: localStorage.getItem('token'),
    refreshToken: localStorage.getItem('refreshToken'),
  }),
  set: (token: string, refreshToken: string) => {
    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);
  },
  delete: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
  },
};

export const createMarkup = (editorState: EditorState) => {
  // Example
  // <div dangerouslySetInnerHTML={createMarkup()} />
  return { __html: draftToHtml(convertToRaw(editorState.getCurrentContent())) };
};

export const getBase64 = async (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};
