import React, { ReactElement, Suspense, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import LoginPage from '../Pages/Auth/LoginPage';
import { Routes, Route, useNavigate } from 'react-router-dom';
import 'antd/dist/antd.css';
import PrivateRoute from './PrivateRoute';
import NotFound from '../Pages/NotFound';
import { appSlice } from '../../redux/reducers/slices/app';
import FaqPage from '../Pages/FAQ';
import FaqEdit from '../Pages/FAQ/edit';
import FaqCreate from '../Pages/FAQ/create';
import SliderPage from '../Pages/Slider';
import EditSlider from '../Pages/Slider/edit';
import CreateSlider from '../Pages/Slider/create';
import PrivacyPolicy from '../Pages/PrivacyPolicy';
import PrivacyPolicyEdit from '../Pages/PrivacyPolicy/edit';
import PrivacyPolicyCreate from '../Pages/PrivacyPolicy/create';
import CreateMugType from '../Pages/MugType/create';
import EditMugTypes from '../Pages/MugType/edit';
import MugTypes from '../Pages/MugType';
import PostcardType from '../Pages/PostcardType';
import EditPostcardType from '../Pages/PostcardType/edit';
import CreatePostcardType from '../Pages/PostcardType/create';
import PackagingType from '../Pages/PackagingType';
import EditPackagingType from '../Pages/PackagingType/edit';
import CreatePackagingType from '../Pages/PackagingType/create';
import CategoriesPage from '../Pages/Categories';
import CreateCategory from '../Pages/Categories/create';
import EditCategory from '../Pages/Categories/edit';
import ProductsPage from '../Pages/Products';
import EditProduct from '../Pages/Products/edit';
import CreateProduct from '../Pages/Products/create';
import SkinColorPage from '../Pages/SkinColor';
import EditSkinColor from '../Pages/SkinColor/edit';
import CreateSkinColor from '../Pages/SkinColor/create';
import HeadsPage from '../Pages/Heads';
import CreateHead from '../Pages/Heads/create';
import EditHead from '../Pages/Heads/edit';
import EditLeftArm from '../Pages/LeftArm/edit';
import CreateLeftArm from '../Pages/LeftArm/create';
import LowerBodyPage from '../Pages/LowerBody';
import EditLowerBody from '../Pages/LowerBody/edit';
import CreateLowerBody from '../Pages/LowerBody/create';
import MiddleBodyPage from '../Pages/MiddleBody';
import CreateMiddleBody from '../Pages/MiddleBody/create';
import EditMiddleBody from '../Pages/MiddleBody/edit';
import HairTypesPage from '../Pages/HairType';
import EditHairTypePage from '../Pages/HairType/edit';
import CreateHairTypePage from '../Pages/HairType/create';
import CreateHair from '../Pages/Hairs/create';
import EditHair from '../Pages/Hairs/edit';
import HairsPage from '../Pages/Hairs';
import LeftArmsPage from '../Pages/LeftArm';
import RightArmsPage from '../Pages/RightArm';
import EditRightArm from '../Pages/RightArm/edit';
import CreateRightArm from '../Pages/RightArm/create';
import ThingsPage from '../Pages/Things';
import CreateThing from '../Pages/Things/create';
import EditThing from '../Pages/Things/edit';
import PromoCodesPage from '../Pages/PromoCodes';
import EditPromoCode from '../Pages/PromoCodes/edit';
import CreatePromoCode from '../Pages/PromoCodes/create';
import PaymentIconsPage from '../Pages/PaymentIcons';
import CreatePaymentIcon from '../Pages/PaymentIcons/create';
import AboutPage from '../Pages/About';
import CreateAbout from '../Pages/About/create';
import EditAbout from '../Pages/About/edit';
import BackSideTextPage from '../Pages/BackSideText';
import CreateBackSideText from '../Pages/BackSideText/create';
import EditBackSideText from '../Pages/BackSideText/edit';
import PromotedCategoriesPage from '../Pages/PromotedCategories';
import EditPromotedCategory from '../Pages/PromotedCategories/edit';
import CreatePromotedCategory from '../Pages/PromotedCategories/create';
import SubscriptionsPage from '../Pages/Subscriptions';
import CreateSubscription from '../Pages/Subscriptions/create';
import SettingsPage from '../Pages/Settings';
import EditSettings from '../Pages/Settings/edit';
import CdekPage from '../Pages/Delivery/Cdek';
import CdekEdit from '../Pages/Delivery/Cdek/edit';
import EditBoxberry from '../Pages/Delivery/Boxberry/edit';
import BoxberryPage from '../Pages/Delivery/Boxberry';
import MetaTagsPage from '../Pages/Meta';
import EditMeta from '../Pages/Meta/edit/EditMeta';
import Orders from '../Pages/Orders';
import NumberOfGirlsPage from '../Pages/NumberOfGirls';
import NumberOfGirlsEdit from '../Pages/NumberOfGirls/edit';
import NumberOfGirlsCreate from '../Pages/NumberOfGirls/create';

function App(): ReactElement {
  const { appLoader } = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(appSlice.actions.setLoggedIn(true));
    dispatch(appSlice.actions.setLoaderStatus(false));
  }, []);

  const metaPaths = [
    'home',
    'payment',
    'contacts',
    'about-us',
    'tracking-order',
    'new',
    'bestsellers',
    'privacy-policy',
    'order-details',
    'order',
    'faq',
  ];

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route path="about">
            <Route element={<AboutPage />} index />
            <Route element={<EditAbout />} path={':id/edit'} />
            <Route element={<CreateAbout />} path={'create'} />
          </Route>
          <Route path="faq">
            <Route element={<FaqPage />} index />
            <Route element={<FaqEdit />} path={':id/edit'} />
            <Route element={<FaqCreate />} path={'create'} />
          </Route>
          <Route path="slider">
            <Route element={<SliderPage />} index />
            <Route element={<EditSlider />} path={':id/edit'} />
            <Route element={<CreateSlider />} path={'create'} />
          </Route>
          <Route path="privacy-policy">
            <Route element={<PrivacyPolicy />} index />
            <Route element={<PrivacyPolicyEdit />} path={':id/edit'} />
            <Route element={<PrivacyPolicyCreate />} path={'create'} />
          </Route>
          <Route path="mug-types">
            <Route element={<MugTypes />} index />
            <Route element={<EditMugTypes />} path={':id/edit'} />
            <Route element={<CreateMugType />} path={'create'} />
          </Route>
          <Route path="orders">
            <Route element={<Orders />} index />
          </Route>
          <Route path="postcard-types">
            <Route element={<PostcardType />} index />
            <Route element={<EditPostcardType />} path={':id/edit'} />
            <Route element={<CreatePostcardType />} path={'create'} />
          </Route>
          <Route path="packaging-types">
            <Route element={<PackagingType />} index />
            <Route element={<EditPackagingType />} path={':id/edit'} />
            <Route element={<CreatePackagingType />} path={'create'} />
          </Route>
          <Route path="categories">
            <Route element={<CategoriesPage />} index />
            <Route element={<EditCategory />} path={':id/edit'} />
            <Route element={<CreateCategory />} path={'create'} />
          </Route>
          <Route path="products">
            <Route element={<ProductsPage />} index />
            <Route element={<EditProduct />} path={':id/edit'} />
            <Route element={<CreateProduct />} path={'create'} />
          </Route>
          <Route path="number-of-girls">
            <Route element={<NumberOfGirlsPage />} index />
            <Route element={<NumberOfGirlsEdit />} path={':id/edit'} />
            <Route element={<NumberOfGirlsCreate />} path={'create'} />
          </Route>
          <Route path="skin-color">
            <Route element={<SkinColorPage />} index />
            <Route element={<EditSkinColor />} path={':id/edit'} />
            <Route element={<CreateSkinColor />} path={'create'} />
          </Route>
          <Route path="heads">
            <Route element={<HeadsPage />} index />
            <Route element={<EditHead />} path={':id/edit'} />
            <Route element={<CreateHead />} path={'create'} />
          </Route>
          <Route path="left-arms">
            <Route element={<LeftArmsPage />} index />
            <Route element={<EditLeftArm />} path={':id/edit'} />
            <Route element={<CreateLeftArm />} path={'create'} />
          </Route>
          <Route path="right-arms">
            <Route element={<RightArmsPage />} index />
            <Route element={<EditRightArm />} path={':id/edit'} />
            <Route element={<CreateRightArm />} path={'create'} />
          </Route>
          <Route path="lower-body">
            <Route element={<LowerBodyPage />} index />
            <Route element={<EditLowerBody />} path={':id/edit'} />
            <Route element={<CreateLowerBody />} path={'create'} />
          </Route>
          <Route path="middle-body">
            <Route element={<MiddleBodyPage />} index />
            <Route element={<EditMiddleBody />} path={':id/edit'} />
            <Route element={<CreateMiddleBody />} path={'create'} />
          </Route>
          <Route path="hair-types">
            <Route element={<HairTypesPage />} index />
            <Route element={<EditHairTypePage />} path={':id/edit'} />
            <Route element={<CreateHairTypePage />} path={'create'} />
          </Route>
          <Route path="hairs">
            <Route element={<HairsPage />} index />
            <Route element={<EditHair />} path={':id/edit'} />
            <Route element={<CreateHair />} path={'create'} />
          </Route>
          <Route path="things">
            <Route element={<ThingsPage />} index />
            <Route element={<EditThing />} path={':id/edit'} />
            <Route element={<CreateThing />} path={'create'} />
          </Route>
          <Route path="promo-codes">
            <Route element={<PromoCodesPage />} index />
            <Route element={<EditPromoCode />} path={':id/edit'} />
            <Route element={<CreatePromoCode />} path={'create'} />
          </Route>
          <Route path="payment-icons">
            <Route element={<PaymentIconsPage />} index />
            <Route element={<CreatePaymentIcon />} path={'create'} />
          </Route>
          <Route path="back-side-text">
            <Route element={<BackSideTextPage />} index />
            <Route element={<EditBackSideText />} path={':id/edit'} />
            <Route element={<CreateBackSideText />} path={'create'} />
          </Route>
          <Route path="promoted-categories">
            <Route element={<PromotedCategoriesPage />} index />
            <Route element={<EditPromotedCategory />} path={':id/edit'} />
            <Route element={<CreatePromotedCategory />} path={'create'} />
          </Route>
          <Route path="subscriptions">
            <Route element={<SubscriptionsPage />} index />
            <Route element={<CreateSubscription />} path={'create'} />
          </Route>
          <Route path="settings">
            <Route element={<SettingsPage />} index />
            <Route element={<EditSettings />} path={'edit'} />
          </Route>
          <Route path="cdek">
            <Route element={<CdekPage />} index />
            <Route element={<CdekEdit />} path={'edit'} />
          </Route>
          <Route path="boxberry">
            <Route element={<BoxberryPage />} index />
            <Route element={<EditBoxberry />} path={'edit'} />
          </Route>
          <Route path="tags">
            {metaPaths.map((item, index) => {
              return (
                <Route path={item} key={index}>
                  <Route element={<MetaTagsPage pathName={item} />} index />
                  <Route element={<EditMeta pathName={item} />} path={'edit'} />
                </Route>
              );
            })}
          </Route>
        </Route>
        <Route path="login" element={<LoginPage />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
      {appLoader && <div className="page-loader">Loading...</div>}
    </Suspense>
  );
}

export default App;
