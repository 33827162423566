import React, { useEffect, useState } from 'react';
import HomeTemplate from '../../../Templates/homeTemplate';
import { useAppDispatch } from '../../../../hooks/redux';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { IMugType, TMugTypeData } from '../../../../types/app/mugTypes';
import { appSlice } from '../../../../redux/reducers/slices/app';
import { api } from '../../../../api';
import { TResponse, TResponseImages, TResponseItem } from '../../../../api/types';
import { TImage } from '../../../../api/types/images';
import { errorHandler, uploadImage } from '../../../../helpers/functions';
import { Button, message } from 'antd';
import { TRequestMugTypeData } from '../../../../api/types/mugTypes';
import BaseInput from '../../../Atoms/BaseInput';
import ImgUploader from '../../../Molecules/ImgUploader';

const EditMugTypes = () => {
  const [mugType, setMugType] = useState<IMugType>();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    try {
      const getMugTypeAsync = async () => {
        dispatch(appSlice.actions.setContentLoader(true));
        const {
          data: { item },
        } = await api.get<IMugType>('/mug-type', id);
        setMugType(item);
        setValue('name', item.name);
        setValue('price', item.price);
        setValue('weight', item.weight);
        setValue('images', [item.image]);
        dispatch(appSlice.actions.setContentLoader(false));
      };
      getMugTypeAsync();
    } catch (e: any) {}
  }, []);

  const editMugType = async (isUpdateImage: boolean, data: TMugTypeData) => {
    try {
      dispatch(appSlice.actions.setContentLoader(true));
      let imageResponse: TResponse<TResponseImages<TImage>>;
      const MugTypeData: TRequestMugTypeData = {
        name: data.name,
        price: +data.price,
        weight: +data.weight,
        imageId: mugType?.image.id ?? 0,
      };
      if (isUpdateImage) {
        imageResponse = await uploadImage(data.images);
        MugTypeData.imageId = imageResponse.data.items[0].id;
      }

      const result: TResponse<TResponseItem<IMugType>> = await api.edit<TRequestMugTypeData>(
        '/mug-type',
        id,
        MugTypeData,
      );
      setMugType(result.data.item);
      message.success('Тип кружки успешно сохранен!');
      dispatch(appSlice.actions.setContentLoader(false));
    } catch (e: any) {
      dispatch(appSlice.actions.setContentLoader(false));
      errorHandler(e, setError);
    }
  };

  const onsubmit = async (mugTypeData: any) => {
    try {
      const isEditedImage: boolean = mugTypeData.images[0].id !== mugType?.image.id;
      editMugType(isEditedImage, mugTypeData);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  return (
    <HomeTemplate classes="slider-edit" withoutScroll={false}>
      <div className="simple-headline">Редактировать тип кружки</div>
      <div className="base-wrapper mw-1200">
        <form onSubmit={handleSubmit(onsubmit)} noValidate>
          <BaseInput name="name" title="Имя:" error={errors.name} required={true}>
            <input
              id="name"
              {...register('name', {
                required: 'Введите имя',
                maxLength: { value: 100, message: 'Максимальное количество символов 100' },
              })}
            />
          </BaseInput>
          <BaseInput name="price" title="Цена:" error={errors.price} required={true}>
            <input
              id="price"
              type="number"
              {...register('price', {
                required: 'Укажите цену',
                min: { value: 1, message: 'Укажите положительную цену' },
              })}
            />
          </BaseInput>
          <BaseInput name="weight" title="Вес, граммы:" error={errors.weight} required={true}>
            <input
              id="weight"
              type="number"
              {...register('weight', {
                required: 'Укажите вес',
                min: { value: 1, message: 'Укажите положительное число' },
              })}
            />
          </BaseInput>
          <ImgUploader
            maxFileList={1}
            control={control}
            error={errors.images}
            label="Изображение:"
            defaultList={mugType?.image}
          />
          <Button type="primary" htmlType="submit">
            Сохранить
          </Button>
        </form>
      </div>
    </HomeTemplate>
  );
};

export default EditMugTypes;
