import React, { useState } from 'react';
import HomeTemplate from '../../../Templates/homeTemplate';
import { TResponse, TResponseImages } from '../../../../api/types';
import { errorHandler, uploadImage } from '../../../../helpers/functions';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../hooks/redux';
import { appSlice } from '../../../../redux/reducers/slices/app';
import { TImage } from '../../../../api/types/images';
import { api } from '../../../../api';
import { Button, message } from 'antd';
import { TPaymentIconData } from '../../../../types/app/paymentIcons';
import { TRequestPaymentIconData } from '../../../../api/types/paymentIcon';
import { useNavigate } from 'react-router-dom';
import ImgUploader from '../../../Molecules/ImgUploader';

const CreatePaymentIcon = () => {
  const navigate = useNavigate();
  const [imageId, setImageId] = useState<number[]>([0]);

  const {
    handleSubmit,
    setError,
    control,
    formState: { errors },
  } = useForm();

  const dispatch = useAppDispatch();

  const create = async (paymentIconData: TPaymentIconData) => {
    try {
      dispatch(appSlice.actions.setContentLoader(true));
      const data: TRequestPaymentIconData = {
        imageId: imageId[0],
      };
      if (!imageId[0]) {
        try {
          const imageResponse: TResponse<TResponseImages<TImage>> = await uploadImage(paymentIconData.imageId);
          data.imageId = imageResponse.data.items[0].id;
          setImageId([imageResponse.data.items[0].id]);
        } catch (e: any) {
          dispatch(appSlice.actions.setContentLoader(false));
          return errorHandler(e, setError, 'imageId');
        }
      }

      await api.create<TRequestPaymentIconData>('/payment-icon', data);

      message.success('Иконка успешно добавлена!');
      dispatch(appSlice.actions.setContentLoader(false));
      navigate('/payment-icons');
    } catch (e: any) {
      dispatch(appSlice.actions.setContentLoader(false));
      errorHandler(e, setError);
    }
  };

  const onsubmit = async (data: TPaymentIconData) => {
    try {
      create(data);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  return (
    <HomeTemplate withoutScroll={false}>
      <div className="simple-headline">Добавить платежную иконку</div>
      <div className="base-wrapper mw-1200">
        <form onSubmit={handleSubmit(onsubmit)} noValidate>
          <ImgUploader
            setImageId={setImageId}
            maxFileList={1}
            control={control}
            error={errors.imageId}
            name="imageId"
            label="Иконка:"
          />
          <Button type="primary" htmlType="submit">
            Создать
          </Button>
        </form>
      </div>
    </HomeTemplate>
  );
};

export default CreatePaymentIcon;
