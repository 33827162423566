import React, { useState } from 'react';
import { TImage } from '../../../../../api/types/images';
import { IOrderItem } from '../../../../../types/app/order';
import classNames from 'classnames';

interface IProps {
  orderItem: IOrderItem & { side: number };
}

const MugImage = ({ orderItem }: IProps) => {
  const [sideIndex, setSideIndex] = useState(orderItem.side);
  return (
    <div className="mug-image">
      <div className="mug-image-tabs">
        <span className={classNames('mug-image-tab', { active: sideIndex === 0 })} onClick={() => setSideIndex(0)}>
          ЛИЦЕВАЯ
        </span>
        <span className={classNames('mug-image-tab', { active: sideIndex === 1 })} onClick={() => setSideIndex(1)}>
          ОБОРОТНАЯ
        </span>
      </div>
      <div className="mug-image-content">
        <img
          src={sideIndex === 0 ? orderItem.frontSideImage.medium : orderItem.backSideImage.medium}
          alt={sideIndex === 0 ? orderItem.frontSideImage.filename : orderItem.backSideImage.filename}
        />
      </div>
    </div>
  );
};

export default MugImage;
