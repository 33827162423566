import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TResponse, TResponseImages } from '../../../../api/types';
import { errorHandler, uploadImage } from '../../../../helpers/functions';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../hooks/redux';
import { api } from '../../../../api';
import { appSlice } from '../../../../redux/reducers/slices/app';
import { TImage } from '../../../../api/types/images';
import { Button, message, Select } from 'antd';
import { itemsApi } from '../../../../api/items';
import HomeTemplate from '../../../Templates/homeTemplate';
import BaseSelect from '../../../Atoms/BaseAsyncSelect';
import ImgUploader from '../../../Molecules/ImgUploader';
import { TNumberOfGirlsData } from '../../../../types/app/numberOfGirls';
import { TRequestNumberOfGirlsData } from '../../../../api/types/numberOfGirls';
import BaseInput from '../../../Atoms/BaseInput';

const { Option } = Select;

const NumberOfGirlsCreate = () => {
  const navigate = useNavigate();
  const [imageId, setImageId] = useState<number[]>([0]);
  const [girls, setGirlsCount] = useState<number[]>([0]);

  const {
    handleSubmit,
    setError,
    control,
    register,
    formState: { errors },
  } = useForm();

  const dispatch = useAppDispatch();

  useEffect(() => {
    getNumberOfGirls();
  }, []);

  const create = async ({ name, countItem, backgroundId }: TNumberOfGirlsData) => {
    try {
      dispatch(appSlice.actions.setContentLoader(true));
      const data: TRequestNumberOfGirlsData = {
        backgroundId: imageId[0],
        countItem: countItem,
        name,
      };
      if (!imageId[0]) {
        try {
          const imageResponse: TResponse<TResponseImages<TImage>> = await uploadImage(backgroundId);
          data.backgroundId = imageResponse.data.items[0].id;
          setImageId([imageResponse.data.items[0].id]);
        } catch (e: any) {
          dispatch(appSlice.actions.setContentLoader(false));
          return errorHandler(e, setError, 'backgroundId');
        }
      }

      await api.create<TRequestNumberOfGirlsData>('/number-of-object', data);

      message.success('Тип тематики кружек успешно добавлен!');
      dispatch(appSlice.actions.setContentLoader(false));
      navigate('/number-of-girls');
    } catch (e: any) {
      dispatch(appSlice.actions.setContentLoader(false));
      errorHandler(e, setError);
    }
  };

  const onsubmit = async (data: any) => {
    try {
      create(data);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  const getNumberOfGirls = async () => {
    const response = await itemsApi.getAll<number[]>();
    setGirlsCount(response.data.items);
  };

  return (
    <HomeTemplate classes="head-create" withoutScroll={false}>
      <div className="simple-headline">Добавить тип тематики кружки</div>
      <div className="base-wrapper mw-1200">
        <form onSubmit={handleSubmit(onsubmit)} noValidate>
          <BaseInput name="name" title="Имя:" error={errors.name} required={true}>
            <input
              id="name"
              {...register('name', {
                required: 'Укажите имя',
                maxLength: { value: 255, message: 'Максимальное количество символов 255' },
              })}
            />
          </BaseInput>
          <BaseSelect
            title="Тематика кружки:"
            name="countItem"
            control={control}
            error={errors.countItem}
            require={true}
          >
            {!!girls[0] &&
              girls.map((item: number) => {
                return (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                );
              })}
          </BaseSelect>
          <ImgUploader
            setImageId={setImageId}
            maxFileList={1}
            control={control}
            error={errors.backgroundId}
            name="backgroundId"
            label="Макет 2000x2000 пикселей формата png:"
          />

          <Button type="primary" htmlType="submit">
            Создать
          </Button>
        </form>
      </div>
    </HomeTemplate>
  );
};

export default NumberOfGirlsCreate;
