import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { getBase64 } from '../../../helpers';
import './style.scss';
import { Controller, FieldError, Control, FieldValues } from 'react-hook-form';
import { UploadChangeParam } from 'antd/es/upload';
import _ from 'lodash';

type TImgUploader = {
  maxFileList: number;
  control: Control<FieldValues>;
  label?: string;
  multiple?: boolean;
  error: any;
  defaultList?: any;
  setImageId?: any;
  required?: boolean;
  name?: string;
};

const ImgUploader: React.FC<TImgUploader> = ({
  maxFileList,
  label,
  control,
  error,
  defaultList,
  multiple = false,
  setImageId,
  required = true,
  name,
}: TImgUploader) => {
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewTitle, setPreviewTitle] = useState<string>('');
  const [previewImage, setPreviewImage] = useState<string>('');
  const [fileList, setFileList] = useState<any[]>([]);

  const handleChange = useCallback(({ fileList }: any, onChange: any) => {
    setFileList(fileList);
    onChange(fileList);
    setImageId && setImageId([0]);
  }, []);

  useEffect(() => {
    const localFileList = fileList;
    if (error && !_.isEmpty(error.message)) {
      error.backendResponse?.forEach((item: any) => {
        localFileList[item.propertyPath.match(/\d+/)[0]].status = 'error';
      });
    }
    setFileList(localFileList);
  }, [error]);

  useEffect(() => {
    let imageList;
    if (!_.isEmpty(defaultList)) {
      if (_.isArray(defaultList)) {
        imageList = defaultList.map((item) => ({
          name: item?.filename,
          status: 'success',
          uid: item?.id,
          thumbUrl: item?.small,
          url: item?.original,
        }));
      } else {
        imageList = [
          {
            name: defaultList.filename,
            status: 'success',
            uid: defaultList.id,
            thumbUrl: defaultList.small,
            url: defaultList.original,
          },
        ];
      }
      setFileList(imageList);
    }
  }, [defaultList]);

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const UploadButton = useCallback(() => {
    return (
      <>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Загрузить</div>
      </>
    );
  }, []);

  const dummyRequest = useCallback(({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  }, []);

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const getRules = () => {
    const rules: any = {};
    if (required) {
      rules.required = 'Загрузите изображение';
    }
    return rules;
  };

  const handleRemove = () => {
    setImageId && setImageId(0);
  };

  const buildErrorMessages = (error: any) => {
    if (error.backendResponse?.length) {
      return error.backendResponse.map((item: any, index: number) => {
        return (
          <div key={index} className="base-error img-uploader__details">
            <span>Image №{+item.propertyPath.match(/\d+/)[0] + 1}: </span>
            <span>{item.message}</span>
          </div>
        );
      });
    } else {
      return <div className="base-error img-uploader__details">{error.message}</div>;
    }
  };

  return (
    <div className={`img-uploader ${error && !_.isEmpty(error.message) ? 'img-uploader-error' : ''}`}>
      {label ? (
        <div className="img-uploader__label">
          {label} {required && <span>*</span>}
        </div>
      ) : null}
      <Controller
        control={control}
        name={`${name ? name : 'images'}`}
        rules={getRules()}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <Upload
            ref={ref}
            multiple={multiple}
            maxCount={maxFileList}
            accept=".png, .jpg, .jpeg"
            onRemove={handleRemove}
            customRequest={dummyRequest}
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={(info: UploadChangeParam) => handleChange(info, onChange)}
          >
            {fileList.length >= maxFileList ? null : UploadButton()}
          </Upload>
        )}
      />
      {error && !_.isEmpty(error.message) && buildErrorMessages(error)}
      <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  );
};

export default ImgUploader;
