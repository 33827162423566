import React, { useEffect, useState } from 'react';
import HomeTemplate from '../../../Templates/homeTemplate';
import { ISettingsType, TCurrency, TSettingsData } from '../../../../types/app/settings';
import { useAppDispatch } from '../../../../hooks/redux';
import { useForm } from 'react-hook-form';
import { appSlice } from '../../../../redux/reducers/slices/app';
import { settingsApi } from '../../../../api/settings';
import BaseInput from '../../../Atoms/BaseInput';
import { Button, message, Select } from 'antd';
import { errorHandler } from '../../../../helpers/functions';
import { TRequestSettingsData } from '../../../../api/types/settings';
import BaseSelect from '../../../Atoms/BaseAsyncSelect';
import { api } from '../../../../api';

const { Option } = Select;

const EditSettings = () => {
  const dispatch = useAppDispatch();
  const [currencies, setCurrencies] = useState<TCurrency[]>([{ id: 0, code: '' }]);

  const {
    handleSubmit,
    setValue,
    setError,
    control,
    register,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    try {
      const getDataAsync = async () => {
        dispatch(appSlice.actions.setContentLoader(true));
        const {
          data: { item },
        } = await settingsApi.get<ISettingsType>('settings');
        const tinkoffRes = await settingsApi.get<any>('tinkoff').then((res) => res.data.item);

        setValue('deliveryDays', item.deliveryDays);
        setValue('percentDiscountForNotIssuedOrder', item.percentDiscountForNotIssuedOrder);
        setValue('packageTrackingMessage', item.packageTrackingMessage);
        setValue('address', item.address);
        setValue('phoneNumbers', item.phoneNumbers.join('\n'));
        setValue('email', item.email);
        setValue('facebookLink', item.facebookLink);
        setValue('instagramLink', item.instagramLink);
        setValue('vkontakteLink', item.vkontakteLink);
        setValue('currencyId', item.currency.id);
        setValue('managerEmail', item.managerEmail);
        setValue('contactUsLink', item.contactUsLink);
        setValue('terminal', tinkoffRes.terminal);
        setValue('password', tinkoffRes.password);
        dispatch(appSlice.actions.setContentLoader(false));
      };
      getDataAsync();
      getCurrencies();
    } catch (e: any) {}
  }, []);

  const getCurrencies = async () => {
    try {
      const {
        data: { items },
      } = await settingsApi.getCurrencies<TCurrency[]>();
      setCurrencies(items);
    } catch (e) {
      console.log('e');
    }
  };

  const edit = async ({ terminal, password, ...data }: TSettingsData) => {
    const discount = data.percentDiscountForNotIssuedOrder;
    try {
      dispatch(appSlice.actions.setContentLoader(true));
      const settingsData: TRequestSettingsData = {
        ...data,
        phoneNumbers: data.phoneNumbers.split('\n'),
        deliveryDays: +data.deliveryDays,
        percentDiscountForNotIssuedOrder: discount ? +discount : null,
      };

      await settingsApi.edit<TRequestSettingsData>(settingsData);
      await api.edit<any>('/tinkoff', undefined, { terminal, password });
      message.success('Настройки успешно изменены!');
      dispatch(appSlice.actions.setContentLoader(false));
    } catch (e: any) {
      dispatch(appSlice.actions.setContentLoader(false));
      errorHandler(e, setError);
    }
  };

  const onsubmit = async (data: any) => {
    try {
      edit(data);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  return (
    <HomeTemplate withoutScroll={false}>
      <div className="simple-headline">Редактировать настройки</div>
      <div className="base-wrapper mw-1200">
        <form onSubmit={handleSubmit(onsubmit)} noValidate>
          <BaseInput name="email" title="Email:" error={errors.email} required={true}>
            <input
              id="email"
              {...register('email', {
                required: 'Заполните поле',
                maxLength: { value: 100, message: 'Максимальное количество символов 100' },
              })}
            />
          </BaseInput>
          <BaseInput name="managerEmail" title="Email менеджера:" error={errors.managerEmail} required={true}>
            <input
              id="managerEmail"
              {...register('managerEmail', {
                required: 'Заполните поле',
                maxLength: { value: 100, message: 'Максимальное количество символов 100' },
              })}
            />
          </BaseInput>
          <BaseInput
            name="contactUsLink"
            title="Ссылка для обратной связи:"
            error={errors.contactUsLink}
            required={true}
          >
            <input
              id="contactUsLink"
              {...register('contactUsLink', {
                required: 'Заполните поле',
                maxLength: { value: 255, message: 'Максимальное количество символов 255' },
              })}
            />
          </BaseInput>
          <BaseSelect title="Валюта:" name="currencyId" control={control} error={errors.currencyId} require={true}>
            {currencies[0].id &&
              currencies.map((item: TCurrency) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.code}
                  </Option>
                );
              })}
          </BaseSelect>
          <BaseInput name="vkontakteLink" title="Vkontakte ссылка:" error={errors.vkontakteLink}>
            <input
              id="vkontakteLink"
              {...register('vkontakteLink', {
                maxLength: { value: 255, message: 'Максимальное количество символов 255' },
              })}
            />
          </BaseInput>
          <BaseInput name="instagramLink" title="Instagram ссылка:" error={errors.instagramLink}>
            <input
              id="instagramLink"
              {...register('instagramLink', {
                maxLength: { value: 255, message: 'Максимальное количество символов 255' },
              })}
            />
          </BaseInput>
          <BaseInput name="facebookLink" title="Facebook ссылка:" error={errors.facebookLink}>
            <input
              id="facebookLink"
              {...register('facebookLink', {
                maxLength: { value: 255, message: 'Максимальное количество символов 255' },
              })}
            />
          </BaseInput>
          <BaseInput name="deliveryDays" title="Дней доставки:" error={errors.deliveryDays} required={true}>
            <input
              id="deliveryDays"
              type="number"
              {...register('deliveryDays', {
                required: 'Заполните поле',
                min: { value: 1, message: 'Укажите валидную дату' },
              })}
            />
          </BaseInput>
          <BaseInput name="address" title="Адрес:" error={errors.address} required={true}>
            <textarea
              {...register('address', {
                required: 'Заполните поле',
                maxLength: { value: 255, message: 'Максимальное количество символов 255' },
              })}
              id="address"
            />
          </BaseInput>
          <BaseInput
            name="packageTrackingMessage"
            title="Сообщение на отслеживание товара:"
            error={errors.packageTrackingMessage}
            required={true}
          >
            <input
              id="packageTrackingMessage"
              {...register('packageTrackingMessage', {
                maxLength: { value: 255, message: 'Максимальное количество символов 255' },
              })}
            />
          </BaseInput>
          <BaseInput
            name="percentDiscountForNotIssuedOrder"
            title="Процентная скидка за не оформленный заказ:"
            error={errors.percentDiscountForNotIssuedOrder}
          >
            <input
              id="percentDiscountForNotIssuedOrder"
              type="number"
              {...register('percentDiscountForNotIssuedOrder', {
                min: { value: 1, message: 'Укажите валидную скидку' },
              })}
            />
          </BaseInput>
          <BaseInput name="phoneNumbers" title="Телефоны:" error={errors.phoneNumbers} required={true}>
            <span className="base-input__sub-title">Номер телефона должен начинаться с новой строки</span>
            <textarea
              {...register('phoneNumbers', {
                required: 'Заполните поле',
                maxLength: { value: 100, message: 'Максимальное количество символов 100' },
              })}
              id="phoneNumbers"
            />
          </BaseInput>
          <BaseInput name="terminal" title="Tinkoff terminal:" error={errors.terminal} required={true}>
            <input
              {...register('terminal', {
                required: 'Заполните поле',
                maxLength: { value: 255, message: 'Максимальное количество символов 255' },
              })}
              id="terminal"
            />
          </BaseInput>
          <BaseInput name="password" title="Tinkoff password:" error={errors.password} required={true}>
            <input
              {...register('password', {
                required: 'Заполните поле',
                maxLength: { value: 255, message: 'Максимальное количество символов 255' },
              })}
              id="password"
            />
          </BaseInput>
          <Button type="primary" htmlType="submit">
            Сохранить
          </Button>
        </form>
      </div>
    </HomeTemplate>
  );
};

export default EditSettings;
