import React from 'react';
import { Layout, Menu } from 'antd';
import {
  QuestionOutlined,
  FileImageOutlined,
  FileProtectOutlined,
  AppstoreOutlined,
  IdcardOutlined,
  InboxOutlined,
  ContainerOutlined,
  DatabaseOutlined,
  BuildOutlined,
  SettingOutlined,
  TagOutlined,
  DingtalkOutlined,
  FireOutlined,
  RocketOutlined,
  NotificationOutlined,
  ToolOutlined,
  DeliveredProcedureOutlined,
  LeftOutlined,
  RightOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import logo from './../../assets/images/logo.png';
import exit from './../../assets/images/exit.svg';
import { Link, NavLink, Outlet, useLocation } from 'react-router-dom';
import './style.scss';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { authActions } from '../../redux/reducers/actionCreators/auth';
import { token } from '../../helpers';
import classNames from 'classnames';
import ContentLoader from '../Atoms/ContentLoader';
import { appSlice } from '../../redux/reducers/slices/app';

type THomeTemplate = {
  children: any;
  classes?: string;
  withoutScroll: boolean;
};

const tagIcon = (
  <>
    <LeftOutlined />
    <RightOutlined />
  </>
);

const HomeTemplate: React.FC<THomeTemplate> = ({ children, classes, withoutScroll }: THomeTemplate) => {
  const { SubMenu } = Menu;
  const { Header, Content, Sider } = Layout;
  const { contentLoader, subMenuActive } = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const logout = () => {
    dispatch(authActions.logout(token.get()));
  };

  const onOpenSubMenu = (e: any) => {
    dispatch(appSlice.actions.setSubMenu(e));
  };

  return (
    <Layout className={`home-layout ${classes ?? ''} ${classNames({ withoutScroll: withoutScroll })}`}>
      <Header className="header">
        <div className="logo">
          <Link to="/settings">
            <img src={logo} alt="mugnolia" />
          </Link>
        </div>
        <div className="logout">
          <button onClick={logout}>
            <img src={exit} alt="exit" /> Выйти
          </button>
        </div>
      </Header>
      <Layout>
        <Sider width={250} className="site-layout-background antd-menu">
          <Menu
            mode="inline"
            onOpenChange={onOpenSubMenu}
            openKeys={subMenuActive}
            style={{ height: '100%', borderRight: 0 }}
            selectedKeys={[`/${pathname.split('/').pop()}`, `/${pathname.split('/')[1]}`, `/${pathname.split('/')[2]}`]}
          >
            <Menu.Item key="/settings" icon={<ToolOutlined />}>
              <NavLink to="/settings">Общие настройки</NavLink>
            </Menu.Item>
            <Menu.Item key="/orders" icon={<UnorderedListOutlined />}>
              <NavLink to="/orders">Заказы</NavLink>
            </Menu.Item>
            <Menu.Item key="/about" icon={<FireOutlined />}>
              <NavLink to="/about">О нас</NavLink>
            </Menu.Item>
            <Menu.Item key="/faq" icon={<QuestionOutlined />}>
              <NavLink to="/faq">Вопросы и ответы</NavLink>
            </Menu.Item>
            <Menu.Item key="/slider" icon={<FileImageOutlined />}>
              <NavLink to="/slider">Слайды</NavLink>
            </Menu.Item>
            <Menu.Item key="/privacy-policy" icon={<FileProtectOutlined />}>
              <NavLink to="/privacy-policy">Политика конфиденциальности</NavLink>
            </Menu.Item>
            <Menu.Item key="/categories" icon={<ContainerOutlined />}>
              <NavLink to="/categories">Категории</NavLink>
            </Menu.Item>
            <SubMenu key="products-parent" icon={<DatabaseOutlined />} title="Товары">
              <Menu.Item key="/mug-types" icon={<AppstoreOutlined />}>
                <NavLink to="/mug-types">Типы кружек</NavLink>
              </Menu.Item>
              <Menu.Item key="/postcard-types" icon={<IdcardOutlined />}>
                <NavLink to="/postcard-types">Типы открыток</NavLink>
              </Menu.Item>
              <Menu.Item key="/packaging-types" icon={<InboxOutlined />}>
                <NavLink to="/packaging-types">Типы упаковок</NavLink>
              </Menu.Item>
              <Menu.Item key="/products" icon={<SettingOutlined />}>
                <NavLink to="/products">Товар</NavLink>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="image-constructor" icon={<BuildOutlined />} title="Конструктор изображения">
              <Menu.Item key="/number-of-girls" icon={<SettingOutlined />}>
                <NavLink to="/number-of-girls">Тематика кружки</NavLink>
              </Menu.Item>
              <Menu.Item key="/skin-color" icon={<SettingOutlined />}>
                <NavLink to="/skin-color">Цвета кожи</NavLink>
              </Menu.Item>
              <Menu.Item key="/heads" icon={<SettingOutlined />}>
                <NavLink to="/heads">Головы</NavLink>
              </Menu.Item>
              <SubMenu key="hairs-parent" icon={<SettingOutlined />} title="Волосы">
                <Menu.Item key="/hair-types" icon={<SettingOutlined />}>
                  <NavLink to="/hair-types">Типы волос</NavLink>
                </Menu.Item>
                <Menu.Item key="/hairs" icon={<SettingOutlined />}>
                  <NavLink to="/hairs">Прически</NavLink>
                </Menu.Item>
              </SubMenu>
              <Menu.Item key="/middle-body" icon={<SettingOutlined />}>
                <NavLink to="/middle-body">Туловище</NavLink>
              </Menu.Item>
              <SubMenu key="arms" icon={<SettingOutlined />} title="Руки">
                <Menu.Item key="/left-arms" icon={<SettingOutlined />}>
                  <NavLink to="/left-arms">Левые руки</NavLink>
                </Menu.Item>
                <Menu.Item key="/right-arms" icon={<SettingOutlined />}>
                  <NavLink to="/right-arms">Правые руки</NavLink>
                </Menu.Item>
                <Menu.Item key="/things" icon={<SettingOutlined />}>
                  <NavLink to="/things">Предметы</NavLink>
                </Menu.Item>
              </SubMenu>
              <Menu.Item key="/lower-body" icon={<SettingOutlined />}>
                <NavLink to="/lower-body">Ноги</NavLink>
              </Menu.Item>
              <Menu.Item key="/back-side-text" icon={<SettingOutlined />}>
                <NavLink to="/back-side-text">Надписи</NavLink>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="/promo-codes" icon={<TagOutlined />}>
              <NavLink to="/promo-codes">Промокоды</NavLink>
            </Menu.Item>
            <Menu.Item key="/payment-icons" icon={<DingtalkOutlined />}>
              <NavLink to="/payment-icons">Платежные иконки</NavLink>
            </Menu.Item>
            <Menu.Item key="/promoted-categories" icon={<RocketOutlined />}>
              <NavLink to="/promoted-categories">Продвигаемые категории</NavLink>
            </Menu.Item>
            <Menu.Item key="/subscriptions" icon={<NotificationOutlined />}>
              <NavLink to="/subscriptions">Подписки</NavLink>
            </Menu.Item>
            <SubMenu key="delivery" icon={<DeliveredProcedureOutlined />} title="Доставка">
              <Menu.Item key="/cdek" icon={<SettingOutlined />}>
                <NavLink to="/cdek">СДЭК</NavLink>
              </Menu.Item>
              <Menu.Item key="/boxberry" icon={<SettingOutlined />}>
                <NavLink to="/boxberry">Boxberry</NavLink>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="metaTags" icon={tagIcon} title="Метатеги">
              <Menu.Item key="/home" icon={<SettingOutlined />}>
                <NavLink to="/tags/home">Главная страница</NavLink>
              </Menu.Item>
              <Menu.Item key="/payment" icon={<SettingOutlined />}>
                <NavLink to="/tags/payment">Способы оплаты</NavLink>
              </Menu.Item>
              <Menu.Item key="/contacts" icon={<SettingOutlined />}>
                <NavLink to="/tags/contacts">Контакты</NavLink>
              </Menu.Item>
              <Menu.Item key="/about-us" icon={<SettingOutlined />}>
                <NavLink to="/tags/about-us">О нас</NavLink>
              </Menu.Item>
              <Menu.Item key="/tracking-order" icon={<SettingOutlined />}>
                <NavLink to="/tags/tracking-order">Отслеживание заказа</NavLink>
              </Menu.Item>
              <Menu.Item key="/bestsellers" icon={<SettingOutlined />}>
                <NavLink to="/tags/bestsellers">Бестселлеры</NavLink>
              </Menu.Item>
              <Menu.Item key="/new" icon={<SettingOutlined />}>
                <NavLink to="/tags/new">Новинки</NavLink>
              </Menu.Item>
              <Menu.Item key="/privacy-policy" icon={<SettingOutlined />}>
                <NavLink to="/tags/privacy-policy">Политика конфиденциальности</NavLink>
              </Menu.Item>
              <Menu.Item key="/order-details" icon={<SettingOutlined />}>
                <NavLink to="/tags/order-details">Корзина</NavLink>
              </Menu.Item>
              <Menu.Item key="/order" icon={<SettingOutlined />}>
                <NavLink to="/tags/order">Оформление заказа</NavLink>
              </Menu.Item>
              <Menu.Item key="/faq" icon={<SettingOutlined />}>
                <NavLink to="/tags/faq">FAQ</NavLink>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </Sider>
        <Layout>
          <Content
            className="site-layout site-layout-background"
            style={{
              padding: 24,
              margin: 0,
            }}
          >
            <>
              {children}
              {contentLoader && <ContentLoader />}
            </>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default HomeTemplate;
