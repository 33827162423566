import React, { useCallback, useEffect, useState } from 'react';
import HomeTemplate from '../../../Templates/homeTemplate';
import { TResponse, TResponseImages, TResponseItem } from '../../../../api/types';
import { errorHandler, uploadImage } from '../../../../helpers/functions';
import { useAppDispatch } from '../../../../hooks/redux';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { initialAboutItems } from '../index';
import { IAboutType, TAboutData } from '../../../../types/app/about';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import { appSlice } from '../../../../redux/reducers/slices/app';
import { api } from '../../../../api';
import { TImage } from '../../../../api/types/images';
import { Button, message } from 'antd';
import { TRequestAboutData } from '../../../../api/types/about';
import draftToHtml from 'draftjs-to-html';
import ImgUploader from '../../../Molecules/ImgUploader';
import BaseInput from '../../../Atoms/BaseInput';
import SimpleEditor from '../../../Atoms/SimpleEditor';

const EditAbout = () => {
  const [about, setAbout] = useState<IAboutType>(initialAboutItems);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isValidEditor, setValidEditor] = useState<boolean | undefined>(undefined);
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const {
    handleSubmit,
    setValue,
    setError,
    control,
    register,
    formState: { errors },
  } = useForm();

  const setEditorValue = useCallback((value: string) => {
    const blocksFromHTML = convertFromHTML(value);
    const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

    return EditorState.createWithContent(contentState);
  }, []);

  useEffect(() => {
    try {
      const getDataAsync = async () => {
        dispatch(appSlice.actions.setContentLoader(true));
        const {
          data: { item },
        } = await api.get<IAboutType>('/about', id);
        setAbout(item);
        setValue('title', item.title);
        setValue('content', item.content);
        setValue('image', [item.image]);
        setEditorState(setEditorValue(item.content));
        dispatch(appSlice.actions.setContentLoader(false));
      };
      getDataAsync();
    } catch (e: any) {}
  }, []);

  const edit = async (isUpdateImage: boolean, data: TAboutData) => {
    try {
      dispatch(appSlice.actions.setContentLoader(true));
      let imageResponse: TResponse<TResponseImages<TImage>>;
      const aboutData: TRequestAboutData = {
        title: data.title,
        content: data.content,
        imageId: about?.image.id ?? 0,
      };
      if (isUpdateImage) {
        try {
          imageResponse = await uploadImage(data.image);
          aboutData.imageId = imageResponse.data.items[0].id;
        } catch (e) {
          dispatch(appSlice.actions.setContentLoader(false));
          return errorHandler(e, setError, 'image');
        }
      }

      const result: TResponse<TResponseItem<IAboutType>> = await api.edit<TRequestAboutData>('/about', id, aboutData);
      setAbout(result.data.item);
      message.success('Блок о нас успешно сохранен!');
      dispatch(appSlice.actions.setContentLoader(false));
    } catch (e: any) {
      dispatch(appSlice.actions.setContentLoader(false));
      errorHandler(e, setError);
    }
  };

  const onsubmit = async (data: any) => {
    try {
      if (editorState.getCurrentContent().getPlainText().replace(/\s/g, '').length) {
        setValidEditor(true);
        const dataWithEditor: TAboutData = {
          ...data,
          content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        };
        const isEditedImage: boolean = data.image[0].id !== about?.image.id;

        edit(isEditedImage, dataWithEditor);
      } else {
        setValidEditor(false);
      }
    } catch (e: any) {
      console.log('error', e);
    }
  };

  const onerror = async () => {
    try {
      if (!editorState.getCurrentContent().getPlainText().replace(/\s/g, '').length) {
        setValidEditor(false);
      }
    } catch (e) {}
  };

  return (
    <HomeTemplate withoutScroll={false}>
      <div className="simple-headline">Редактировать блок о нас</div>
      <div className="base-wrapper mw-1200">
        <form onSubmit={handleSubmit(onsubmit, onerror)} noValidate>
          <BaseInput name="title" title="Заголовок:" error={errors.title} required={true}>
            <input
              id="title"
              {...register('title', {
                required: 'Введите заголовок',
                maxLength: { value: 50, message: 'Максимальное количество символов 50' },
              })}
            />
          </BaseInput>
          <SimpleEditor
            isValid={isValidEditor}
            required={true}
            editorState={editorState}
            setEditorState={setEditorState}
          />
          <ImgUploader
            maxFileList={1}
            control={control}
            error={errors.image}
            label="Изображение:"
            defaultList={about?.image}
            name="image"
            required={true}
          />
          <Button type="primary" htmlType="submit">
            Сохранить
          </Button>
        </form>
      </div>
    </HomeTemplate>
  );
};

export default EditAbout;
