import React, { useState } from 'react';
import HomeTemplate from '../../../Templates/homeTemplate';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../hooks/redux';
import { appSlice } from '../../../../redux/reducers/slices/app';
import { TResponse, TResponseImages } from '../../../../api/types';
import { TImage } from '../../../../api/types/images';
import { api } from '../../../../api';
import { Button, message } from 'antd';
import { errorHandler } from '../../../../helpers/functions';
import BaseInput from '../../../Atoms/BaseInput';
import ImgUploader from '../../../Molecules/ImgUploader';
import { TSliderData } from '../../../../types/app/slider';
import { TRequestSliderData } from '../../../../api/types/slider';

const CreateSlider = () => {
  const navigate = useNavigate();
  const [imageId, setImageId] = useState<number[]>([0]);
  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors },
  } = useForm();
  const dispatch = useAppDispatch();

  const createFaq = async (data: TSliderData) => {
    try {
      dispatch(appSlice.actions.setContentLoader(true));
      const formData = new FormData();
      const sliderData: TRequestSliderData = {
        title: data.title,
        description: data.description,
        href: data.href,
        imageId: imageId[0],
      };

      if (!imageId[0]) {
        data.images.forEach((item: any) => {
          formData.append('files[]', item.originFileObj);
        });
        const imageResponse: TResponse<TResponseImages<TImage>> = await api.uploadImages(formData);
        sliderData.imageId = imageResponse.data.items[0].id;
        setImageId([imageResponse.data.items[0].id]);
      }

      await api.create<TRequestSliderData>('/slider-item', sliderData);

      message.success('Слайд успешно создан!');
      dispatch(appSlice.actions.setContentLoader(false));
      navigate('/slider');
    } catch (e: any) {
      dispatch(appSlice.actions.setContentLoader(false));
      errorHandler(e, setError);
    }
  };

  const onsubmit = async (data: any) => {
    try {
      createFaq(data);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  return (
    <HomeTemplate classes="slider-create" withoutScroll={false}>
      <div className="simple-headline">Создать слайд</div>
      <div className="base-wrapper mw-1200">
        <form onSubmit={handleSubmit(onsubmit)} noValidate>
          <BaseInput name="title" title="Заголовок:" error={errors.title} required={true}>
            <input
              id="title"
              {...register('title', {
                required: 'Введите заголовок',
                maxLength: { value: 100, message: 'Максимальное количество символов 100' },
              })}
            />
          </BaseInput>
          <BaseInput name="description" title="Описание:" error={errors.description}>
            <input
              id="description"
              {...register('description', {
                maxLength: { value: 255, message: 'Максимальное количество символов 255' },
              })}
            />
          </BaseInput>
          <BaseInput name="href" title="Ссылка:" error={errors.href}>
            <input
              id="href"
              {...register('href', {
                maxLength: { value: 255, message: 'Максимальное количество символов 255' },
              })}
            />
          </BaseInput>
          <ImgUploader
            setImageId={setImageId}
            maxFileList={1}
            control={control}
            error={errors.images}
            label="Изображение:"
          />
          <Button type="primary" htmlType="submit">
            Создать
          </Button>
        </form>
      </div>
    </HomeTemplate>
  );
};

export default CreateSlider;
