import React, { useEffect, useState } from 'react';
import HomeHeader from '../../Molecules/HomeHeader';
import { useNavigate } from 'react-router-dom';
import HomeTemplate from '../../Templates/homeTemplate';
import { useAppDispatch } from '../../../hooks/redux';
import { appSlice } from '../../../redux/reducers/slices/app';
import { settingsApi } from '../../../api/settings';
import { IMeta } from '../../../types/app/meta';

const initialTags = {
  title: '',
  description: '',
  keywords: '',
};

interface IProps {
  pathName: string;
}

const MetaTagsPage = ({ pathName }: IProps) => {
  const navigate = useNavigate();
  const [metaTags, setMetaTags] = useState<IMeta>(initialTags);
  const dispatch = useAppDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathName]);

  useEffect(() => {
    getData();
  }, [pathName]);

  const getData = async () => {
    try {
      dispatch(appSlice.actions.setContentLoader(true));
      const response = await settingsApi.get<IMeta>(`meta-tags/${pathName}`);
      setMetaTags(response.data.item);
      dispatch(appSlice.actions.setContentLoader(false));
    } catch (e: any) {
      console.log(e);
      dispatch(appSlice.actions.setContentLoader(false));
    }
  };

  const editHandler = () => {
    navigate(`edit`);
  };
  return (
    <HomeTemplate classes="meta-tags" withoutScroll={false}>
      <HomeHeader buttonName="Изменить" onClick={editHandler} title="Метатеги" />
      <div className="settings-item">
        <div className="settings-item__title">Title:</div>
        <div className="settings-item__value">{metaTags.title ? metaTags.title : '-'}</div>
      </div>
      <div className="settings-item">
        <div className="settings-item__title">Description:</div>
        <div className="settings-item__value">{metaTags.description ? metaTags.description : '-'}</div>
      </div>
      <div className="settings-item">
        <div className="settings-item__title">Keywords:</div>
        <div className="settings-item__value">{metaTags.keywords ? metaTags.keywords : '-'}</div>
      </div>
    </HomeTemplate>
  );
};

export default MetaTagsPage;
