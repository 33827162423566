import React, { useEffect, useState } from 'react';
import HomeHeader from '../../Molecules/HomeHeader';
import HomeTemplate from '../../Templates/homeTemplate';
import { useNavigate } from 'react-router-dom';
import { ISettingsType } from '../../../types/app/settings';
import { settingsApi } from '../../../api/settings';
import { appSlice } from '../../../redux/reducers/slices/app';
import { useAppDispatch } from '../../../hooks/redux';
import './style.scss';

export const initialSettings: ISettingsType = {
  currency: {
    id: 0,
    code: '',
  },
  email: '',
  managerEmail: '',
  contactUsLink: '',
  deliveryDays: 0,
  address: '',
  facebookLink: '',
  instagramLink: '',
  vkontakteLink: '',
  packageTrackingMessage: '',
  percentDiscountForNotIssuedOrder: 0,
  phoneNumbers: [''],
};

const SettingsPage = () => {
  const navigate = useNavigate();
  const [settings, setSettings] = useState<ISettingsType>(initialSettings);
  const [tinkoff, setTinkoff] = useState<any>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      dispatch(appSlice.actions.setContentLoader(true));
      const response = await settingsApi.get<ISettingsType>('settings');
      const tinkoffRes = await settingsApi.get<any>('tinkoff').then((res) => res.data.item);
      setTinkoff(tinkoffRes);
      setSettings(response.data.item);
      dispatch(appSlice.actions.setContentLoader(false));
    } catch (e: any) {
      console.log(e);
      dispatch(appSlice.actions.setContentLoader(false));
    }
  };

  const editHandler = () => {
    navigate(`edit`);
  };

  return (
    <HomeTemplate classes="settings" withoutScroll={false}>
      <HomeHeader buttonName="Изменить" onClick={editHandler} title="Общие настройки" />
      <div className="settings-item">
        <div className="settings-item__title">Email: </div>
        <div className="settings-item__value">
          {settings.email ? <a href={`mailto:${settings.email}`}>{settings.email}</a> : '-'}
        </div>
      </div>
      <div className="settings-item">
        <div className="settings-item__title">Email менеджера:</div>
        <div className="settings-item__value">
          {settings.managerEmail ? <a href={`mailto:${settings.managerEmail}`}>{settings.managerEmail}</a> : '-'}
        </div>
      </div>
      <div className="settings-item">
        <div className="settings-item__title">Ссылка для обратной связи: </div>
        <div className="settings-item__value">
          <div className="settings-item__value">
            {settings.contactUsLink ? (
              <a href={settings.contactUsLink} target="_blank" rel="noreferrer">
                {settings.contactUsLink}
              </a>
            ) : (
              '-'
            )}
          </div>
        </div>
      </div>
      <div className="settings-item">
        <div className="settings-item__title">Валюта: </div>
        <div className="settings-item__value">
          <div className="settings-item__value">{settings.currency.code ? settings.currency.code : '-'}</div>
        </div>
      </div>
      <div className="settings-item">
        <div className="settings-item__title">Vkontakte ссылка: </div>
        <div className="settings-item__value">
          {settings.vkontakteLink ? (
            <a href={settings.vkontakteLink} target="_blank" rel="noreferrer">
              {settings.vkontakteLink}
            </a>
          ) : (
            '-'
          )}
        </div>
      </div>
      <div className="settings-item">
        <div className="settings-item__title">Instagram ссылка: </div>
        <div className="settings-item__value">
          {settings.instagramLink ? (
            <a href={settings.instagramLink} target="_blank" rel="noreferrer">
              {settings.instagramLink}
            </a>
          ) : (
            '-'
          )}
        </div>
      </div>
      <div className="settings-item">
        <div className="settings-item__title">Facebook ссылка: </div>
        <div className="settings-item__value">
          {settings.facebookLink ? (
            <a href={settings.facebookLink} target="_blank" rel="noreferrer">
              {settings.facebookLink}
            </a>
          ) : (
            '-'
          )}
        </div>
      </div>
      <div className="settings-item">
        <div className="settings-item__title">Дней доставки: </div>
        <div className="settings-item__value">{settings.deliveryDays ? settings.deliveryDays : '-'}</div>
      </div>
      <div className="settings-item">
        <div className="settings-item__title">Адрес: </div>
        <div className="settings-item__value">
          <div className="settings-item__value" style={{ whiteSpace: 'pre-wrap' }}>
            {settings.address ? settings.address : '-'}
          </div>
        </div>
      </div>
      <div className="settings-item">
        <div className="settings-item__title">Сообщение на отслеживание товара:</div>
        <div className="settings-item__value">
          {settings.packageTrackingMessage ? settings.packageTrackingMessage : '-'}
        </div>
      </div>
      <div className="settings-item">
        <div className="settings-item__title">Процентная скидка за не оформленный заказ:</div>
        <div className="settings-item__value">
          {settings.percentDiscountForNotIssuedOrder ? settings.percentDiscountForNotIssuedOrder : '-'}
        </div>
      </div>
      <div className="settings-item">
        <div className="settings-item__title">Телефоны:</div>
        <div className="settings-item__value">
          {settings.phoneNumbers.length
            ? settings.phoneNumbers.map((item, index) => {
                return (
                  <>
                    <a key={index} href={`tel:${item}`}>
                      {item}
                    </a>
                    {settings.phoneNumbers.length !== index + 1 ? <br /> : null}
                  </>
                );
              })
            : '-'}
        </div>
      </div>
      <div className="settings-item">
        <div className="settings-item__title">Процентная скидка за не оформленный заказ:</div>
        <div className="settings-item__value">
          {settings.percentDiscountForNotIssuedOrder ? settings.percentDiscountForNotIssuedOrder : '-'}
        </div>
      </div>
      <div className="settings-item">
        <div className="settings-item__title">Tinkoff terminal:</div>
        <div className="settings-item__value">{tinkoff?.terminal ? tinkoff.terminal : '-'}</div>
      </div>
      <div className="settings-item">
        <div className="settings-item__title">Tinkoff password:</div>
        <div className="settings-item__value">{tinkoff?.password ? tinkoff.password : '-'}</div>
      </div>
    </HomeTemplate>
  );
};

export default SettingsPage;
