import { TResponse } from './types';
import { privateAxios } from './axiosInstance';

export const settingsApi = {
  get: async <Entity>(path: string): Promise<TResponse<{ item: Entity }>> => {
    return await privateAxios.get(`/${path}`);
  },
  edit: async <Entity>(data: Entity) => {
    return await privateAxios.put('/settings', data);
  },
  getCurrencies: async <Entity>(): Promise<TResponse<{ items: Entity }>> => {
    return await privateAxios.get('/currency');
  },
};
