import { publicAxios } from './axiosInstance';
import { FormValues } from '../types/auth';

export const AuthApi = {
  login: async ({ email, password }: FormValues) => {
    return await publicAxios.post('/admin/login', { email, password });
  },
  logout: async (refreshToken: string | null) => {
    return await publicAxios.post('/logout', { refreshToken: refreshToken });
  },
  refresh: async (refreshToken: string | null) => {
    return await publicAxios.post('/token-refresh', { refreshToken: refreshToken });
  },
};
