import React from 'react';
import { Select } from 'antd';
import './style.scss';
import { SelectValue } from 'antd/es/select';
import { Controller, FieldError } from 'react-hook-form';
import _ from 'lodash';

type TBaseSelect = {
  title: string;
  require?: boolean;
  name: string;
  control: any;
  error?: FieldError | undefined;
  children: React.ReactNode;
  handlerChange?: (item: SelectValue) => void;
  disabled?: boolean;
  multiple?: boolean;
};

const BaseSelect: React.FC<TBaseSelect> = (props: TBaseSelect) => {
  const selectHandler = (item: any, onChange: any) => {
    if (_.isArray(item)) {
      if (item.includes(0)) {
        if (item.slice(-1)[0] === 0) {
          onChange([0]);
        } else {
          onChange(item.filter((id: number) => id !== 0));
        }
      } else {
        onChange(item);
      }
    } else {
      onChange(item);
    }
    props.handlerChange && props.handlerChange(item);
  };

  const getRules = () => {
    const rules: any = {};
    if (props.require) {
      rules.required = 'Выберите значение';
    }
    return rules;
  };

  return (
    <div className={`base-select ${props.error && props.error.message ? 'base-select__error' : ''}`}>
      <div className="base-select__label">
        {props.title} {props.require && <span>*</span>}
      </div>
      <Controller
        control={props.control}
        name={props.name}
        rules={getRules()}
        render={({ field: { onChange, onBlur, value, ref } }) => {
          return (
            <Select
              mode={props.multiple ? 'multiple' : undefined}
              disabled={props.disabled}
              ref={ref}
              showSearch
              placeholder="Введите для поиска"
              optionFilterProp="children"
              value={value}
              onChange={(item) => selectHandler(item, onChange)}
              filterOption={(input, option) => `${option?.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
            >
              {props.children}
            </Select>
          );
        }}
      />
      {props.error && props.error.message && (
        <span className="base-error img-uploader__details">{props.error.message}</span>
      )}
    </div>
  );
};

export default BaseSelect;
