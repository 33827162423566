import { privateAxios } from './axiosInstance';
import { TResponse, TResponseImages, TResponseItem, TResponseItems, TResponseWithoutPagination } from './types';
import { TImage } from './types/images';
import { config } from '../helpers/config';

export const api = {
  uploadImages: async (data: any): Promise<TResponse<TResponseImages<TImage>>> => {
    return await privateAxios.post('/images/upload', data, { headers: { 'Content-Type': 'multipart/form-data' } });
  },
  getAll: async <Entity>(
    path: string,
    page = 1,
    perPage: number = config.PAGE_SIZE_FOR_PAGINATION,
  ): Promise<TResponse<TResponseItems<Entity>>> => {
    return await privateAxios.get(path, { params: { page: page, per_page: perPage } });
  },
  getAllWithoutPagination: async <Entity>(path: string): Promise<TResponse<TResponseWithoutPagination<Entity>>> => {
    return await privateAxios.get(path);
  },
  get: async <Entity>(path: string, id?: string): Promise<TResponse<TResponseItem<Entity>>> => {
    return await privateAxios.get(`${path}${id ? `/${id}` : ''}`);
  },
  delete: async (path: string, id: number) => {
    return await privateAxios.delete(`${path}/${id}`);
  },
  create: async <Entity>(path: string, data: Entity) => {
    return await privateAxios.post(path, data);
  },
  edit: async <Entity>(path: string, id: any, data: Entity) => {
    return await privateAxios.put(`${path}${id ? `/${id}` : ''}`, data);
  },
};
