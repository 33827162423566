import React, { useEffect, useState } from 'react';
import { TResponse, TResponseImages, TResponseItem } from '../../../../api/types';
import { errorHandler, uploadImage } from '../../../../helpers/functions';
import { useAppDispatch } from '../../../../hooks/redux';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { appSlice } from '../../../../redux/reducers/slices/app';
import { api } from '../../../../api';
import { itemsApi } from '../../../../api/items';
import { TImage } from '../../../../api/types/images';
import { Button, message, Select } from 'antd';
import HomeTemplate from '../../../Templates/homeTemplate';
import BaseSelect from '../../../Atoms/BaseAsyncSelect';
import { initialNumberOfGirlsItems } from '../index';
import { TNumberOfGirls, TNumberOfGirlsData } from '../../../../types/app/numberOfGirls';
import { TRequestNumberOfGirlsData } from '../../../../api/types/numberOfGirls';
import ImgUploader from '../../../Molecules/ImgUploader';
import BaseInput from '../../../Atoms/BaseInput';

const { Option } = Select;

const NumberOfGirlsEdit = () => {
  const [numberOfGirls, setNumberOfGirls] = useState<TNumberOfGirls>(initialNumberOfGirlsItems);

  const [girls, setGirlsCount] = useState<number[]>([0]);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    control,
    formState: { errors },
  } = useForm();

  console.log(errors);

  useEffect(() => {
    try {
      const getDataAsync = async () => {
        dispatch(appSlice.actions.setContentLoader(true));
        const {
          data: { item },
        } = await api.get<TNumberOfGirls>('/number-of-object', id);
        setNumberOfGirls(item);

        setValue('backgroundId', [item.background]);
        setValue('name', item.name);
        setValue('countItem', item.countItem);

        getNumberOfGirls();
        dispatch(appSlice.actions.setContentLoader(false));
      };
      getDataAsync();
    } catch (e: any) {}
  }, []);

  const getNumberOfGirls = async () => {
    const response = await itemsApi.getAll<number[]>();
    setGirlsCount(response.data.items);
  };

  const edit = async (isUpdateImage: boolean, data: TNumberOfGirlsData) => {
    try {
      dispatch(appSlice.actions.setContentLoader(true));
      let imageResponse: TResponse<TResponseImages<TImage>>;
      const numberOfGirlsData: TRequestNumberOfGirlsData = {
        name: data.name,
        backgroundId: numberOfGirls?.background.id ?? 0,
        countItem: data.countItem,
      };
      if (isUpdateImage) {
        try {
          imageResponse = await uploadImage(data.backgroundId);
          numberOfGirlsData.backgroundId = imageResponse.data.items[0].id;
        } catch (e) {
          dispatch(appSlice.actions.setContentLoader(false));
          return errorHandler(e, setError, 'backgroundId');
        }
      }

      const result: TResponse<TResponseItem<TNumberOfGirls>> = await api.edit<TRequestNumberOfGirlsData>(
        '/number-of-object',
        id,
        numberOfGirlsData,
      );
      setNumberOfGirls(result.data.item);
      message.success('Тип тематики кружек успешно изменен!');
      dispatch(appSlice.actions.setContentLoader(false));
    } catch (e: any) {
      dispatch(appSlice.actions.setContentLoader(false));
      errorHandler(e, setError);
    }
  };

  const onsubmit = async (data: any) => {
    try {
      const isEditedImage: boolean = data.backgroundId[0].id !== numberOfGirls?.background.id;
      edit(isEditedImage, data);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  return (
    <HomeTemplate classes="product-edit" withoutScroll={false}>
      <div className="simple-headline">Редактировать тип тематики кружки</div>
      <div className="base-wrapper mw-1200">
        <form onSubmit={handleSubmit(onsubmit)} noValidate>
          <BaseInput name="name" title="Имя:" error={errors.name} required={true}>
            <input
              id="name"
              {...register('name', {
                required: 'Укажите имя',
                maxLength: { value: 255, message: 'Максимальное количество символов 255' },
              })}
            />
          </BaseInput>
          <BaseSelect
            title="Количество объектов:"
            name="countItem"
            control={control}
            error={errors.countItem}
            require={true}
          >
            {!!girls[0] &&
              girls.map((item: number) => {
                return (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                );
              })}
          </BaseSelect>
          <ImgUploader
            maxFileList={1}
            required={true}
            control={control}
            error={errors.backgroundId}
            label="Макет 2000x2000 пикселей формата png:"
            name="backgroundId"
            defaultList={numberOfGirls?.background}
          />
          <Button type="primary" htmlType="submit">
            Сохранить
          </Button>
        </form>
      </div>
    </HomeTemplate>
  );
};

export default NumberOfGirlsEdit;
