import React, { useEffect, useState } from 'react';
import HomeTemplate from '../../../Templates/homeTemplate';
import BaseInput from '../../../Atoms/BaseInput';
import { Button, message } from 'antd';
import { useAppDispatch } from '../../../../hooks/redux';
import { useForm } from 'react-hook-form';
import { appSlice } from '../../../../redux/reducers/slices/app';
import { settingsApi } from '../../../../api/settings';
import { errorHandler } from '../../../../helpers/functions';
import { IMeta, IMetaData } from '../../../../types/app/meta';
import { TRequestMetaData } from '../../../../api/types/meta';
import { api } from '../../../../api';

interface IProps {
  pathName: string;
}

const EditMeta = ({ pathName }: IProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    handleSubmit,
    setValue,
    setError,
    register,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    try {
      const getDataAsync = async () => {
        dispatch(appSlice.actions.setContentLoader(true));
        const {
          data: { item },
        } = await settingsApi.get<IMeta>(`meta-tags/${pathName}`);
        setValue('description', item.description || '');
        setValue('keywords', item.keywords || '');
        setValue('title', item.title || '');
        dispatch(appSlice.actions.setContentLoader(false));
      };
      getDataAsync();
    } catch (e: any) {}
  }, []);

  const edit = async (data: IMetaData) => {
    console.log('Save meta tags', data);
    try {
      dispatch(appSlice.actions.setContentLoader(true));

      await api.edit<TRequestMetaData>(`meta-tags/${pathName}`, undefined, data);
      message.success('Метатеги успешно изменены!');
      dispatch(appSlice.actions.setContentLoader(false));
    } catch (e: any) {
      dispatch(appSlice.actions.setContentLoader(false));
      errorHandler(e, setError);
    }
  };

  const onsubmit = async (data: any) => {
    try {
      edit(data);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  return (
    <HomeTemplate withoutScroll={false}>
      <div className="simple-headline">Редактировать метатеги</div>
      <div className="base-wrapper mw-1200">
        <form onSubmit={handleSubmit(onsubmit)} noValidate>
          <BaseInput name="title" title="Title:" error={errors.title}>
            <input id="title" {...register('title')} />
          </BaseInput>
          <BaseInput name="description" title="Description:" error={errors.description}>
            <textarea {...register('description', {})} id="description" />
          </BaseInput>
          <BaseInput name="keywords" title="Keywords:" error={errors.keywords}>
            <textarea {...register('keywords', {})} id="keywords" />
          </BaseInput>
          <Button type="primary" htmlType="submit" style={{ marginTop: 0 }}>
            Сохранить
          </Button>
        </form>
      </div>
    </HomeTemplate>
  );
};

export default EditMeta;
