import React, { useCallback, useEffect, useState } from 'react';
import HomeTemplate from '../../../Templates/homeTemplate';
import './style.scss';
import BaseInput from '../../../Atoms/BaseInput';
import SimpleEditor from '../../../Atoms/SimpleEditor';
import ImgUploader from '../../../Molecules/ImgUploader';
import { Button, message } from 'antd';
import { useAppDispatch } from '../../../../hooks/redux';
import { useParams } from 'react-router-dom';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import { Faq, TFaqData } from '../../../../types/app/faq';
import { appSlice } from '../../../../redux/reducers/slices/app';
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import draftToHtml from 'draftjs-to-html';
import { useForm } from 'react-hook-form';
import { errorHandler, uploadImage } from '../../../../helpers/functions';
import { TResponse, TResponseImages, TResponseItem } from '../../../../api/types';
import { TImage } from '../../../../api/types/images';
import { TRequestFaqData } from '../../../../api/types/faq';
import { api } from '../../../../api';

const FaqEdit = () => {
  const [faq, setFaq] = useState<Faq>();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isValidEditor, setValidEditor] = useState<boolean | undefined>(undefined);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    control,
    formState: { errors, isSubmitted },
  } = useForm();

  const setEditorValue = useCallback((value: string) => {
    const blocksFromHTML = convertFromHTML(value);
    const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

    return EditorState.createWithContent(contentState);
  }, []);

  useEffect(() => {
    try {
      const getFaqAsync = async () => {
        dispatch(appSlice.actions.setContentLoader(true));
        const {
          data: { item },
        } = await api.get<Faq>('/faq', id);
        setFaq(item);
        setValue('name', item.name);
        setValue('slug', item.slug);
        setValue('images', [item.image]);
        setValue('description', item.meta.description || '');
        setValue('keywords', item.meta.keywords || '');
        setValue('title', item.meta.title || '');
        setEditorState(setEditorValue(item.text));
        dispatch(appSlice.actions.setContentLoader(false));
      };
      getFaqAsync();
    } catch (e: any) {}
  }, []);

  const editFaq = async (
    isUpdateImage: boolean,
    { name, text, slug, images, title, keywords, description }: TFaqData,
  ) => {
    try {
      dispatch(appSlice.actions.setContentLoader(true));
      let imageResponse: TResponse<TResponseImages<TImage>>;
      const faqData: TRequestFaqData = {
        name: name,
        slug: slug,
        text: text,
        imageId: faq?.image.id ?? 0,
        meta: {
          title,
          keywords,
          description,
        },
      };
      if (isUpdateImage) {
        imageResponse = await uploadImage(images);
        faqData.imageId = imageResponse.data.items[0].id;
      }

      const result: TResponse<TResponseItem<Faq>> = await api.edit<TRequestFaqData>('/faq', faq?.id, faqData);
      setFaq(result.data.item);
      message.success('FAQ успешно сохранен!');
      dispatch(appSlice.actions.setContentLoader(false));
    } catch (e: any) {
      dispatch(appSlice.actions.setContentLoader(false));
      errorHandler(e, setError);
    }
  };

  const onsubmit = async (data: any) => {
    try {
      if (editorState.getCurrentContent().getPlainText().replace(/\s/g, '').length) {
        setValidEditor(true);
        const dataWithEditor: TFaqData = {
          ...data,
          text: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        };
        const isEditedImage: boolean = dataWithEditor.images[0].id !== faq?.image.id;

        editFaq(isEditedImage, dataWithEditor);
      } else {
        setValidEditor(false);
      }
    } catch (e: any) {
      console.log('error', e);
    }
  };

  const onerror = async () => {
    try {
      if (!editorState.getCurrentContent().getPlainText().replace(/\s/g, '').length) {
        setValidEditor(false);
      }
    } catch (e) {}
  };

  const nameHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setValue('slug', new CyrillicToTranslit().transform(e?.currentTarget.value, '-'), { shouldValidate: isSubmitted });
  };

  return (
    <HomeTemplate classes="faq-edit" withoutScroll={false}>
      <div className="simple-headline">Редактировать FAQ</div>
      <div className="base-wrapper mw-1200">
        <form onSubmit={handleSubmit(onsubmit, onerror)} noValidate>
          <BaseInput name="name" title="Имя:" error={errors.name} required={true}>
            <input
              id="name"
              onInput={nameHandler}
              {...register('name', {
                required: 'Введите имя',
                maxLength: { value: 100, message: 'Максимальное количество символов 100' },
              })}
            />
          </BaseInput>
          <BaseInput name="slug" title="Slug:" error={errors.slug} required={true}>
            <input
              id="slug"
              {...register('slug', {
                required: 'Введите slug',
                maxLength: { value: 255, message: 'Максимальное количество символов 255' },
              })}
            />
          </BaseInput>
          <SimpleEditor
            isValid={isValidEditor}
            required={true}
            editorState={editorState}
            setEditorState={setEditorState}
          />
          <BaseInput name="title" title="Meta title:" error={errors.title}>
            <input id="title" {...register('title')} />
          </BaseInput>
          <BaseInput name="description" title="Meta description:" error={errors.description}>
            <textarea {...register('description', {})} id="description" />
          </BaseInput>
          <BaseInput name="keywords" title="Meta Keywords:" error={errors.keywords}>
            <textarea {...register('keywords', {})} id="keywords" />
          </BaseInput>
          <ImgUploader
            maxFileList={1}
            control={control}
            error={errors.images}
            label="Изображение:"
            defaultList={faq?.image}
          />
          <Button type="primary" htmlType="submit">
            Сохранить
          </Button>
        </form>
      </div>
    </HomeTemplate>
  );
};

export default FaqEdit;
