import { AuthApi } from '../../../api/auth';
import { AppDispatch } from '../../store';
import { FormValues, TToken } from '../../../types/auth';
import { authSlice } from '../slices/auth';
import { token } from '../../../helpers';
import { appSlice } from '../slices/app';
import { message } from 'antd';

export const authActions = {
  login: (data: FormValues) => async (dispatch: AppDispatch) => {
    try {
      const response = await AuthApi.login(data);
      dispatch(authSlice.actions.loginSuccess(response.data));
      token.set(response.data.token, response.data.refreshToken);
      dispatch(appSlice.actions.setLoggedIn(true));
    } catch (error: any) {
      dispatch(appSlice.actions.setLoggedIn(false));
      throw error;
    }
  },
  logout:
    ({ refreshToken }: TToken) =>
    (dispatch: AppDispatch) => {
      AuthApi.logout(refreshToken);
      dispatch(appSlice.actions.setLoggedIn(false));
      token.delete();
    },

  refreshToken: (refreshToken: TToken) => async (dispatch: AppDispatch) => {
    try {
      const response = await AuthApi.refresh(refreshToken.refreshToken);
      dispatch(authSlice.actions.loginSuccess(response.data));
      token.set(response.data.token, response.data.refreshToken);
      dispatch(appSlice.actions.setLoggedIn(true));
      return response;
    } catch (error: any) {
      if (error.response.status === 401) {
        dispatch(appSlice.actions.setLoggedIn(false));
        token.delete();
      }
    }
  },
};
