import React, { useCallback, useEffect, useState } from 'react';
import HomeHeader from '../../Molecules/HomeHeader';
import HomeTemplate from '../../Templates/homeTemplate';
import { IProductType } from '../../../types/app/products';
import { Link, useNavigate } from 'react-router-dom';
import { TResponseItems } from '../../../api/types';
import { ICategoryType } from '../../../types/app/categories';
import { initialItems, parseDateToDMY, parseTimeToHM } from '../../../helpers/functions';
import { api } from '../../../api';
import { Empty, message, Space } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import BaseTable from '../../Organisms/BaseTable';
import { initialCategoryItems } from '../Categories';
import './style.scss';
import { initialNumberOfGirlsItems } from '../NumberOfGirls';
import { TNumberOfGirls } from '../../../types/app/numberOfGirls';

const initialProductItems: IProductType = {
  id: 0,
  name: '',
  newPrice: 0,
  oldPrice: 0,
  slug: '',
  endOfSaleAt: '',
  countItem: 0,
  description: '',
  numberOfObject: initialNumberOfGirlsItems(),
  categories: [initialCategoryItems],
  images: [
    {
      id: 0,
      filename: '',
      originalFilename: '',
      original: '',
      small: '',
    },
  ],
  additionalConditionsText: '',
  warningText: '',
  updatedAt: `${new Date()}`,
  new: false,
  bestseller: false,
  meta: {
    title: '',
    keywords: '',
    description: '',
  },
};

const ProductsPage = () => {
  const navigate = useNavigate();

  const [itemsLoader, setItemsLoader] = useState<boolean>(true);
  const [products, setProducts] = useState<TResponseItems<IProductType>>(initialItems(initialProductItems));

  const buildProducts = useCallback(() => {
    return products.items.map((item: IProductType) => {
      return {
        key: item.id,
        id: item.id,
        name: item.name,
        newPrice: item.newPrice,
        oldPrice: item.oldPrice,
        categories: item.categories,
        new: item.new,
        countItem: item.countItem,
        numberOfObject: item.numberOfObject,
        endOfSaleAt: item.endOfSaleAt ? `${parseDateToDMY(item.endOfSaleAt)} ${parseTimeToHM(item.endOfSaleAt)}` : '',
        slug: item.slug,
        bestseller: item.bestseller,
        images: item.images,
        updated: `${parseDateToDMY(item.updatedAt)} ${parseTimeToHM(item.updatedAt)}`,
      };
    });
  }, [products.items]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async (page?: number) => {
    try {
      setItemsLoader(true);
      const productsResponse = await api.getAll<IProductType>('/products', page);
      setProducts(productsResponse.data);
      setItemsLoader(false);
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const buildCategories = (items: ICategoryType[]) => {
    return items.map((item, index) => {
      return (
        <Link key={item.id} to={`/categories/${item.slug}/edit`}>
          {item.name}
          {items.length > index + 1 ? ', ' : ''}
        </Link>
      );
    });
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      key: 'slug',
    },
    {
      title: 'Категории',
      dataIndex: 'categories',
      key: 'categories',
      render: buildCategories,
    },
    {
      title: 'Тематика кружки',
      dataIndex: 'numberOfObject',
      key: 'numberOfObject',
      render: (value: TNumberOfGirls) => <Link to={`/number-of-girls/${value.id}/edit`}>{value.name}</Link>,
    },
    {
      title: 'Новинка',
      dataIndex: 'new',
      key: 'new',
      render: (value: boolean) => (value ? 'Да' : 'Нет'),
    },
    {
      title: 'Бестселлер',
      dataIndex: 'bestseller',
      key: 'bestseller',
      render: (value: boolean) => (value ? 'Да' : 'Нет'),
    },
    {
      title: 'Новая цена',
      dataIndex: 'newPrice',
      key: 'newPrice',
    },
    {
      title: 'Старая цена',
      dataIndex: 'oldPrice',
      key: 'oldPrice',
    },
    {
      title: 'Дата изменения',
      dataIndex: 'updated',
      key: 'updated',
    },
    {
      title: 'Действия',
      key: 'action',
      render: (text: IProductType) => (
        <Space size="middle" className="actions">
          <EditOutlined onClick={() => editHandler(text)} />
          <DeleteOutlined onClick={() => deleteHandler(text)} />
        </Space>
      ),
    },
  ];

  const editHandler = (slideItem: IProductType) => {
    navigate(`${slideItem.slug}/edit`);
  };

  const deleteHandler = async (item: IProductType) => {
    try {
      setItemsLoader(true);
      const pageNumber =
        (products.totalCount - 1) % 10 === 0 && products.currentPageNumber > 1
          ? products.currentPageNumber - 1
          : products.currentPageNumber;
      await api.delete('/product', item.id);
      await getData(pageNumber);
      message.success('Товар успешно удален!');
    } catch (e: any) {
      console.log(e);
      setItemsLoader(false);
    }
  };

  const createHandler = () => {
    navigate('create');
  };

  const onPageChange = (page: number) => {
    getData(page);
  };

  return (
    <HomeTemplate classes="products-root" withoutScroll={true}>
      <HomeHeader onClick={createHandler} title="Товар" />
      {products.totalCount ? (
        <BaseTable
          columns={columns}
          data={buildProducts()}
          loader={itemsLoader}
          currentPageNumber={products.currentPageNumber}
          numItemsPerPage={products.numItemsPerPage}
          totalCount={products.totalCount}
          onPageChange={onPageChange}
        />
      ) : (
        <Empty description="Нет данных" />
      )}
    </HomeTemplate>
  );
};

export default ProductsPage;
